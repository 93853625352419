<script setup>
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import { onMounted, ref } from "vue";
const props = defineProps(['avatar', 'hideDefaulIcon']);
const avatarImage = ref(null);
onMounted(() => {
  avatarImage.value = props.avatar
});

</script>
<template>
  <span class="component-avatar round-placeholder">
    <img width="20" height="20" style="object-fit: cover !important; object-position: top !important;"
      v-if="props.avatar != null && !props.avatar.startsWith('#')" :src="props.avatar" class="round-image" />
    <AssigneeIcon v-if="props.avatar == null" />
    <span v-if="props.avatar != null && props.avatar.startsWith('#')" :style="{
        backgroundColor: props.avatar,
        display: 'inline-block',
        width: '20px',
        height: '20px',
        borderRadius: '20px',
        marginTop: '0px',//previously 8 new 0
      }"></span>
  </span>
</template>

<style>
.component-avatar.round-placeholder {
  display: inline !important;
}
</style>

