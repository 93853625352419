<template>
    <node-view-wrapper class="code-block">
        <BDropdown v-model="issue['openLabels']" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
                <div class="labelButton" style="cursor: pointer">
                    <AddCircleIconVue stroke="#7B8497" />&nbsp;
                    <span>Add Label</span>
                </div>
            </template>
            <LabelsDropdown @update:showPopup="issue['openLabels'] = $event" @selected:label="editedTaskLabels($event)"
                style="width: 100%" :selectedLabels="issue['labels'] != null ? issue['labels'].split(',') : []
            " />
        </BDropdown>
        <select contenteditable="false" v-model="selectedLanguage">
            <option :value="null">
                Auto
            </option>
            <option disabled>
                —
            </option>
            <option v-for="(language, index) in languages" :value="language" :key="index">
                {{ returnLanguageName(language) }}
            </option>
        </select>
        <i class="fa-regular fa-copy" @click="copyCode"></i>
        <pre><code><node-view-content /></code></pre>
    </node-view-wrapper>
</template>

<script>
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

export default {
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },

    props: nodeViewProps,

    data() {
        return {
            //languages: this.extension.options.lowlight.listLanguages(),
            languages: ['golo', 'javascript', 'json', 'typescript', 'plainttext', 'python']
        }
    },

    computed: {
        selectedLanguage: {
            get() {
                return this.node.attrs.language
            },
            set(language) {
                this.updateAttributes({ language })
            },
        },
    },
    methods: {
        copyCode: () => {
            console.log("Copied");
        },
        returnLanguageName: (name) => {
            const languageNameArray = {
                'golo': "Go",
                'javascript': "JavaScript",
                'json': "JSON",
                'typescript': "TypeScript",
                'plainttext': "PlainText",
                'python': "Python"
            };
            return languageNameArray[name] != undefined ? languageNameArray[name] : name.charAt(0).toUpperCase() + name.slice(1);
        }
    }
}
</script>

<style lang="scss">
.tiptap {
    .code-block {
        position: relative;

        select {
            position: absolute;
            background-color: #FFFFFF !important;
            right: 2rem;
            top: 0.5rem;
            border: none;
            box-shadow: none;
            border-radius: 8px;
            padding: 0 0.5rem;
        }

        i {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            color: #FFFFFF;
            font-size: 1.1rem;
            cursor: pointer;
        }
    }
}
</style>