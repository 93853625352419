// useDefineState.js
import { ref } from "vue";

const showEmptyGrouping = ref(true);

function useDefineState() {
  const setState = (newState) => {
    showEmptyGrouping.value = newState;
  };

  return {
    isShow: showEmptyGrouping,
    setState,
  };
}

export default useDefineState;
