<script setup>
import AssigneeIcon from "@/components/Icons/AssigneeIcon.vue";
import AddFilterIcon from "@/components/Icons/AddFilterIcon.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref, inject, watch } from "vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PriorityIcon from "@/components/Icons/PriorityIcon.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import CreatorIcon from "@/components/Icons/CreatorIcon.vue";
import LabelIcon from "@/components/Icons/LabelIcon.vue";
import EstimateIcon from "@/components/Icons/EstimateIcon.vue";
import KanbanLight from "@/components/Icons/KanbanLight.vue";
import ContentIcon from "@/components/Icons/ContentIcon.vue";
import ProjectIcon from "@/components/Icons/ProjectIcon.vue";
import ProjectStatusIcon from "@/components/Icons/ProjectStatusIcon.vue";
import RoadmapIcon from "@/components/Icons/RoadmapIcon.vue";
import CalendarIcon from "@/components/Icons/CalendarIcon.vue";
import ParentIssueIcon from "@/components/Icons/ParentIssueIcon.vue";
import SubtaskIcon from "@/components/Icons/SubtaskIcon.vue";
import BlockedIssueIcon from "@/components/Icons/BlockedIssuesIcon.vue";
import BlockingIssueIcon from "@/components/Icons/BlockingIssuesIcon.vue";
import IssueWithLinksIcon from "@/components/Icons/IssueWithLinksIcon.vue";
import DuplicateIcon from "@/components/Icons/DuplicateIcon.vue";
import DueDateIcon from "@/components/Icons/DueDateIcon.vue";
import CreatedDateIcon from "@/components/Icons/CreatedDateIcon.vue";
import UpdatedDateIcon from "@/components/Icons/UpdatedDateIcon.vue";
import FilterAdd from "@/components/TeamIcons/FilterAdd.vue";
import SelectionDropdown from "@/components/FilterComponents/SelectionDropdown.vue";
import { fetchAllWorkflowStatuses, buildFilterQuery, returnFilterItems } from "@/components/Utilities/Utilities";
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
import { icon } from "@fortawesome/fontawesome-svg-core";
const props = defineProps(['showTitle']);
const emits = defineEmits(['reload:issues']);
const filterItems = ref([]);
const showFilters = ref(false);
const showStatusFilter = ref(false);
const filterQuery = inject('filterQuery');
const priorityItems = inject('priority');
const showFilterBox = inject('showFilterBox');
const statuses = ref([]);
onMounted(async () => {
  statuses.value = await fetchAllWorkflowStatuses();
});

const filterType = ref(null);
async function showFilterDropdown(type) {
  filterType.value = type;
  filterItems.value = await returnFilterItems(type);
  showFilters.value = false;
  showStatusFilter.value = true;
}

function buildFilterQueryLocal(selectedObject) {
  filterQuery.value = buildFilterQuery(selectedObject, filterQuery.value);
  if (filterQuery.value == null) {
    showFilterBox.value = false;
  }
  else {
    if (Object.keys(filterQuery.value).length <= 0) {
      showFilterBox.value = false;
    }
    else {
      showFilterBox.value = true;
    }
  }
  emits('reload:issues', filterQuery.value);
}

function closeDropDown(close) {
  showStatusFilter.value = close;
}
</script>
<template>
  <SelectionDropdown v-if="showStatusFilter" :show="showStatusFilter" :items="filterItems" :type="filterType"
    @selected:filter="buildFilterQueryLocal($event)" @close:dropdown="closeDropDown($event)" />
  <BDropdown v-model="showFilters" variant="link" toggle-class="text-decoration-none" class="button-30" no-caret>
    <template #button-content>
      <AddFilterIcon class="d-none d-md-inline" />

      <span v-if="props.showTitle" style="color: #000;" class="d-none d-md-inline">Filters</span>
    </template>
    <ul class="filterUl">
      <li @click="showFilterDropdown('status')">
        <ClockIcon width="12" height="12" />
        Status
      </li>
      <li>
        <span v-if="statuses.length > 1" v-html="statuses[1].icon" width="12" height="12"></span>
        Status Type
      </li>
      <li @click="showFilterDropdown('assignee')">
        <AssigneeIcon width="12" height="12" />
        Assignee
      </li>
      <li @click="showFilterDropdown('created_by')">
        <CreatorIcon width="12" height="12" />
        Creator
      </li>
      <li @click="showFilterDropdown('priority')">
        <PriorityIcon width="12" height="12" />
        Priority
      </li>
      <li>
        <LabelIcon width="12" height="12" />
        Labels
      </li>
      <li>
        <EstimateIcon width="12" height="12" />
        Estimates
      </li>
      <li class="border-bottom">
        <ContentIcon width="12" height="12" />
        Content
      </li>
      <li @click="showFilterDropdown('project_id')">
        <ProjectIcon />
        Project
      </li>
      <li>
        <ProjectStatusIcon />
        Project Status
      </li>
      <li>
        <KanbanLight stroke="#3D4046" width="12" height="12" />
        Sprint
      </li>
      <li class="border-bottom">
        <RoadmapIcon />
        Roadmap
      </li>
      <li>
        <ParentIssueIcon />
        Parent Issue
      </li>
      <li>
        <SubtaskIcon />
        Subtasks
      </li>
      <li>
        <BlockedIssueIcon />
        Blocked Issues
      </li>
      <li>
        <BlockingIssueIcon />
        Blocking Issues
      </li>
      <li>
        <IssueWithLinksIcon />
        Issues with Links
      </li>
      <li class="border-bottom">
        <DuplicateIcon />
        Duplicates
      </li>
      <li>
        <DueDateIcon />
        Due Date
      </li>
      <li>
        <CreatedDateIcon />
        Created Date
      </li>
      <li>
        <UpdatedDateIcon />
        Updated Date
      </li>
      <li>
        <CalendarIcon width="12" height="12" />
        Started Date
      </li>
    </ul>
  </BDropdown>
</template>
<style></style>