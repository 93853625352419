<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField.vue";
import SelectDropdown from "@/components/Fields/SelectDropdown";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import CupIcon from "@/components/Icons/CupIcon.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import BacklogActive from "@/components/NavIcons/BacklogActiveIcon.vue";
import AvatarModal from "@/components/Fields/AvatarModal";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import api from "../../../api";
import { db, defaultDatabase, initiateDatabase } from '@/localdatabase/dbConfig';

import { onMounted, ref, watch } from "vue";  // Import the 'watch' function
import { Switch } from "@headlessui/vue";
import { useRouter } from "vue-router";
const toast = useToast();

var router = useRouter();
const name = ref("");
const slug = ref("");
const teamsList = ref([]);
const enabled = ref(false);
const checkSelectBox = ref(false);
const selectedTeam = ref(null);
const includeExistingMembers = ref(false);
const includeLabels = ref(false);
const includeWorkflows = ref(false);
const includeIssueTypes = ref(false);
const includeTemplates = ref(false);
const includeEstimates = ref(false);
const errors = ref([]);

onMounted(() => {
  const currentWorkspaceId = localStorage.getItem("workSpaceID");
  api.get("teams?dropdown=true").then((response) => {
    console.log("All Teams", response.data);
    teamsList.value = response.data.filter(team => {
      return team.workspace_id == currentWorkspaceId && team.members.some(member => member.team_role.role == "administrator");
    });
    console.log("Filtered Teams", teamsList.value);
  }).catch(error => {
    console.error("Error fetching teams", error);
  });
});



watch(name, (newValue) => {  // Add the 'watch' function
  slug.value = newValue.substring(0, 4).toLowerCase();
});

function TeamDropdownChange(team) {

  if (team && team.id) {
    selectedTeam.value = team;
  }
}
function onSelectToggle() {
  checkSelectBox.value = !checkSelectBox.value;
  // Make the API request
}
function onAddTeam() {
  // Make the API request
  errors.value = [];
  let workspace_id = localStorage.getItem("workSpaceID");
  let team_id = localStorage.getItem("workSpaceID");
  if (name.value == "") {
    errors.value.push('Name is required');
  }
  if (slug.value == "") {
    errors.value.push('Identifier is required');
  }

  if (errors.value.length > 0) {
    return false;
  }

  api
    .post("teams", {
      workspace_id,
      name: name.value,
      slug: slug.value,
      copy_existing_settings: selectedTeam.value ? true : false,
      team_id: 30,
      include_existing_members: includeExistingMembers.value,
      id: 30,
      icon: iconToBeSaved.value
    })
    .then(async (response) => {
      // Handle the API response
      if (response.status === 200) {
        await db.teams.add(response.data);
        router.push("/teams");
        toast("Your changes are saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      }
    })
    .catch((error) => {
      // Handle any errors
      console.error(error);
    });
}
const showAvatarModal = ref(false);
const showColorComponentForIcons = ref(false);
const iconToBeSaved = ref(null);
const iconColorToShow = ref("");
const iconToShow = ref("");
const iconCustomColor = ref("#000000");
function convertImage(file) {
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      iconToBeSaved.value = reader.result;
    };
    reader.readAsDataURL(file);
  } else {
    iconToBeSaved.value = "";
  }
}
function setColor(color) {
  iconToBeSaved.value = color;
}
function selectIcon(icon) {
  let iconArray = icon.split(';');
  iconToShow.value = iconArray[0].slice(4);
  iconColorToShow.value = iconArray[1].slice(6);
  iconToBeSaved.value = icon;
}
function setColorForIcons(color) {
  iconCustomColor.value = color;
  showColorComponentForIcons.value = false;
}
</script>

<template>
  <div>
    <AppHeader heading="Add New Team" :backbutton=true breadCrumbs=""></AppHeader>
    <div style="padding: 32px 0px">
      <div>
        <div class="row">
          <p class="errors" v-if="errors.length">
            <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
          </p>
          <div class="col-1">
            <div class="select-box-container">
              <label>Icon</label>
              <div class="">
                <div class="cupicon" style="text-align: center; cursor: pointer;" @click="showAvatarModal = true">
                  <CupIcon v-if="iconToBeSaved == null"></CupIcon>
                  <img id="previewImage" class="image-logo" style="width: 32px !important; height: 32px !important;"
                    :src="iconToBeSaved ? iconToBeSaved : './../../../assets/images/profile.png'
      " v-else-if="!iconToBeSaved.startsWith('#') && !iconToBeSaved.startsWith('ico')" />
                  <i v-else-if="iconToBeSaved.startsWith('ico')" class="material-icons fa-icon"
                    :style="{ color: iconColorToShow }" style="font-size: 1.5rem !important;">
                    {{ iconToShow }}
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>

        <div class="row" style="margin-top: 32px !important">
          <div class="col-12 col-md-6">
            <div>
              <label>Name</label>
              <div>
                <InputField placeholder="Name" v-model="name"></InputField>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div>
              <label>Identifier</label>
              <div>
                <InputField v-model="slug" placeholder="Identifier should be 2-3 letters"></InputField>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <div class="row" style="margin-top: 10px !important">
          <div class="col-12 col-md-4">
            <div class="d-flex align-items-center justify-content-between">
              <label style="white-space: nowrap" class="m-0">
                Copy settings from existing team
              </label>
              <Switch v-model="enabled" :style="enabled ? 'background: #F96332;' : 'background: #EAEAEA;'
      " style="" class="custom-switch transition-colors duration-200 ease-in-out">
                <span class="sr-only">Use setting</span>
                <span aria-hidden="true" :class="enabled ? 'translate-x-9' : 'translate-x-0'"
                  class="custom-transform custom-transition duration-200 ease-in-out"></span>
              </Switch>
            </div>
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
        <div v-if="enabled">
          <div class="row" style="margin-top: 32px !important">
            <div class="col-12 col-md-4">
              <div class="select-box-container">
                <label>Team</label>
                <!-- <div class="select-box"> -->
                <!-- <div class="selected-div">Select</div>
                  <div class="chevron-down">
                    <img src="assets/images/arrow-down.svg" />
                  </div> -->
                <SelectDropdown :items="teamsList" placeholder="Select Teams" :displayKey="'name'"
                  @onSelectValue="TeamDropdownChange($event)">
                </SelectDropdown>
                <!-- </div> -->
              </div>
            </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
          <div class="row" style="margin-top: 32px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="first-box" v-model="includeExistingMembers"></CheckBoxInput>
              <label>Include existing members</label>
            </div>
          </div>
          <div class="row" style="margin-top: 5px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="second-box" v-model="includeLabels"></CheckBoxInput>
              <label>Include labels</label>
            </div>
          </div>
          <div class="row" style="margin-top: 5px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="third-box" v-model="includeWorkflows"></CheckBoxInput>
              <label>Include Workflows</label>
            </div>
          </div>
          <div class="row" style="margin-top: 5px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="fourth-box" v-model="includeIssueTypes"></CheckBoxInput>
              <label>Include Issue Types</label>
            </div>
          </div>
          <div class="row" style="margin-top: 5px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="fifth-box" v-model="includeTemplates"></CheckBoxInput>
              <label>Include Templates</label>
            </div>
          </div>
          <div class="row" style="margin-top: 5px !important">
            <div class="d-flex col" style="gap: 8px">
              <CheckBoxInput id="sixth-box" v-model="includeEstimates"></CheckBoxInput>
              <label>Include Estimates</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 32px !important">
        <div class="col-12">
          <button class="custom-primary-btn" @click="onAddTeam">
            <strong>Save</strong>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showAvatarModal" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 550px;">
      <AvatarModal @convertImage="convertImage" @setColor="setColor" @close="showAvatarModal = false"
        @showColorComponent="showColorComponentForIcons = true" :customIconColor="iconCustomColor"
        @selectedIcon="selectIcon" />
    </div>
  </div>
  <div v-if="showColorComponentForIcons" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <ColorComponent @update:showPopup="showColorComponentForIcons = $event"
        @update:colorSelected="setColorForIcons($event)" />
    </div>
  </div>
</template>

<style lang="scss">
@media screen and (max-width: 768px) {
  select.form-select {
    height: 40px !important;
    font-size: 14px !important;
    color: #797e89 !important;
  }
}

.custom-transform {
  pointer-events: none;
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: inset 0 0 0 calc(0px + 2px) white;
  transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

label {
  margin-bottom: 6px;
  font-size: 14px;
  color: #2a2c32;
  font-weight: 500;
}

.select-box-container {
  .select-box {
    position: relative;
    height: 38px;
    cursor: pointer;
    border: 1px solid #c4c4ca;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;

    .selected-div {
      flex: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .chevron-down {
      width: 24px;
      height: 24px;
    }
  }
}

.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-switch {
  position: relative;
  display: inline-flex;
  height: 24px;
  width: 44px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 12px;
  border-width: 2px;
  border-color: transparent;
  padding: 0px;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 calc(2px + 2px) white;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.translate-x-9 {
  transform: translateX(20px);
}

.translate-x-0 {
  transform: translateX(0px);
}

.custom-transition {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

@media screen and (max-width: 768px) {
  .select-box-container .select-box {
    height: 50px !important;
    padding: 0px 11px !important;
  }
}
</style>
