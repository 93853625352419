<script setup>
import AssigneeIcon from "@/components/Icons/AssigneeIcon.vue";
import AddFilterIcon from "@/components/Icons/AddFilterIcon.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref, inject, watch } from "vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import DisplayAvatar from "../Pages/Profile/DisplayAvatar.vue";
const props = defineProps(['show', 'items', 'filtersSelected', 'type']);
const emits = defineEmits(['selected:filter']);
const filterQuery = inject('filterQuery');
const showDropdown = ref(false);
const alreadySelectedFilters = ref([]);
const icons = ref({
  'status': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99996 18.9577C5.05829 18.9577 1.04163 14.941 1.04163 9.99935C1.04163 5.05768 5.05829 1.04102 9.99996 1.04102C14.9416 1.04102 18.9583 5.05768 18.9583 9.99935C18.9583 14.941 14.9416 18.9577 9.99996 18.9577ZM9.99996 2.29102C5.74996 2.29102 2.29163 5.74935 2.29163 9.99935C2.29163 14.2493 5.74996 17.7077 9.99996 17.7077C14.25 17.7077 17.7083 14.2493 17.7083 9.99935C17.7083 5.74935 14.25 2.29102 9.99996 2.29102Z" fill="#7B8497"/><path d="M13.0916 13.2745C12.9833 13.2745 12.875 13.2495 12.775 13.1828L10.1916 11.6411C9.54995 11.2578 9.07495 10.4161 9.07495 9.67448V6.25781C9.07495 5.91615 9.35828 5.63281 9.69995 5.63281C10.0416 5.63281 10.325 5.91615 10.325 6.25781V9.67448C10.325 9.97448 10.575 10.4161 10.8333 10.5661L13.4166 12.1078C13.7166 12.2828 13.8083 12.6661 13.6333 12.9661C13.5083 13.1661 13.3 13.2745 13.0916 13.2745Z" fill="#7B8497"/></svg>',
  'priority': '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99996 18.9577C5.05829 18.9577 1.04163 14.941 1.04163 9.99935C1.04163 5.05768 5.05829 1.04102 9.99996 1.04102C14.9416 1.04102 18.9583 5.05768 18.9583 9.99935C18.9583 14.941 14.9416 18.9577 9.99996 18.9577ZM9.99996 2.29102C5.74996 2.29102 2.29163 5.74935 2.29163 9.99935C2.29163 14.2493 5.74996 17.7077 9.99996 17.7077C14.25 17.7077 17.7083 14.2493 17.7083 9.99935C17.7083 5.74935 14.25 2.29102 9.99996 2.29102Z" fill="#3D4046"/><path d="M8.09174 14.8658C7.85007 14.8658 7.66675 14.7825 7.55842 14.6991C7.33342 14.5491 6.99174 14.1658 7.2084 13.3074L7.85009 10.7158L7.08341 10.5241C6.30841 10.3325 6.13342 9.84081 6.10008 9.64081C6.06675 9.44081 6.05008 8.91582 6.70008 8.45749L11.0167 5.4658C11.7417 4.9658 12.2167 5.14912 12.4417 5.29912C12.6667 5.44912 13.0084 5.83249 12.7917 6.69083L12.1501 9.28248L12.9167 9.47413C13.6917 9.6658 13.8667 10.1574 13.9001 10.3658C13.9334 10.5741 13.9501 11.0908 13.3001 11.5491L8.98341 14.5408C8.62508 14.7824 8.3334 14.8658 8.09174 14.8658ZM7.59173 9.36579L8.75842 9.65749C9.09175 9.74082 9.30007 10.0825 9.21674 10.4158L8.4834 13.3658L12.4084 10.6408L11.2417 10.3492C10.9084 10.2658 10.7001 9.92414 10.7834 9.59081L11.5167 6.64078L7.59173 9.36579Z" fill="#3D4046"/></svg>'
});
onMounted(async () => {
  showDropdown.value = props.show;
  alreadySelectedFilters.value = filterQuery.value == null ? [] : (filterQuery.value[props.type] == undefined ? [] : filterQuery.value[props.type].filterValues);
});
watch(showDropdown, (newValue, oldValue) => {
  if (newValue == false) {
    emits('close:dropdown', false);
  }
});

watch(filterQuery, (newValue, oldValue) => {
  if (filterQuery.value == null) {
    alreadySelectedFilters.value = [];
  }
  else {
    if (filterQuery.value[props.type] == undefined) {
      alreadySelectedFilters.value = [];
    }
    else {
      alreadySelectedFilters.value = filterQuery.value[props.type].filterValues;
    }
  }
  console.log(alreadySelectedFilters.value);
});
function selectFilter(item) {
  let valueToCheck = item.id;
  let labelToAdd = item.label;
  if (filterQuery.value == null) {
    filterQuery.value = {};
  }
  if (filterQuery.value[props.type] != undefined) {
    if (filterQuery.value[props.type].filterValues.includes(valueToCheck)) {
      filterQuery.value[props.type].filterValues.splice(filterQuery.value[props.type].filterValues.indexOf(valueToCheck), 1);
      filterQuery.value[props.type].filterLabels.splice(filterQuery.value[props.type].filterLabels.indexOf(labelToAdd), 1);
    } else {
      filterQuery.value[props.type].filterValues.push(valueToCheck);
      filterQuery.value[props.type].filterLabels.push(labelToAdd);
    }
  }
  else {
    filterQuery.value[props.type] = {
      operator: 'is',
      filterValues: [valueToCheck],
      filterLabels: [labelToAdd],
      label: props.type,
      icon: icons.value[props.type]
    };
  }
  emits('selected:filter', {
    'selectedFilters': filterQuery.value[props.type].filterValues,
    'selectedLabels': filterQuery.value[props.type].filterLabels,
    'type': props.type,
    'icon': icons.value[props.type]
  });
}
</script>
<template>
  <BDropdown class="filterSubMenu" v-model="showDropdown" variant="link" toggle-class="text-decoration-none" no-caret>
    <div class="statusesUl">
      <a v-for="(item, index) in props.items" @click.stop="" :key="index">
        <label v-bind:for="item.value">
          <div class="row">
            <div class="d-flex col align-items-center" style="gap: 8px">
              <CheckBoxInput @click="selectFilter(item)" :model-value="alreadySelectedFilters.includes(item.id)" />
              <span v-if="props.type != 'assignee' && props.type != 'created_by'" v-html="item.icon"></span>
              <DisplayAvatar :avatar="item.icon" v-if="props.type == 'assignee' || props.type == 'created_by'" />
              {{ item.label }}
            </div>
          </div>
        </label>
      </a>
    </div>
  </BDropdown>
</template>