<script setup>
import CupIcon from '@/components/Icons/CupIcon.vue';
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';
import ClockIcon from '@/components/Icons/ClockIcon.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import CreatorIcon from '@/components/Icons/CreatorIcon.vue';
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import EstimateIcon from '@/components/Icons/EstimateIcon.vue';
import LabelTriangleIcon from '@/components/Icons/LabelTriangleIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import LabelIcon from '@/components/Icons/LabelIcon.vue';
import Danger from '../../ToastIcons/ErrorIcon.vue';
import Success from '../../ToastIcons/SuccessIcon.vue';
import DeleteIcon from '@/components/Icons/DeleteIcon.vue';
import InputField from '@/components/Fields/InputField.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
// import DatePicker from "vue-datepicker";
// import "vue-datepicker";
// import 'bootstrap-datepicker';
//import DatePicker from '@/components/Pages/Project/DatePicker.vue';
// const props = defineProps(['showPopup']);
const props = defineProps(['issueId'])
const route = useRoute()
const emits = defineEmits(['update:showPopup', 'selected:item']);
const selectedPriorityDropdown = ref(null);
const name = ref('');
const justMounted = ref(0);
const priority = ref([
  { 'label': 'No Priority', 'value': 'nopriority', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65984 15.0337C6.62651 15.0337 6.59318 15.0337 6.55984 15.0204C4.00651 14.5004 1.93318 12.6204 1.15984 10.127C1.07984 9.86036 1.22652 9.58036 1.48652 9.50036C1.75318 9.42036 2.03318 9.56703 2.11318 9.82703C2.77984 11.9737 4.55985 13.587 6.75985 14.0337C7.03318 14.087 7.20651 14.3537 7.14651 14.627C7.09985 14.867 6.88651 15.0337 6.65984 15.0337Z" fill="#7B8497"/><path d="M14.6335 7.82065C14.3801 7.82065 14.1601 7.62732 14.1335 7.37398C13.8135 4.21398 11.1735 1.83398 8.00015 1.83398C4.82015 1.83398 2.18682 4.21398 1.86682 7.36732C1.84015 7.64065 1.60015 7.84732 1.32015 7.81398C1.04682 7.78732 0.846812 7.54065 0.873479 7.26732C1.24681 3.60065 4.31348 0.833984 8.00015 0.833984C11.6935 0.833984 14.7601 3.60065 15.1268 7.26732C15.1535 7.54065 14.9535 7.78732 14.6801 7.81398C14.6668 7.82065 14.6468 7.82065 14.6335 7.82065Z" fill="#7B8497"/><path d="M9.34006 15.0339C9.10673 15.0339 8.90006 14.8739 8.85339 14.6339C8.80006 14.3605 8.97339 14.1005 9.24006 14.0472C11.4267 13.6005 13.2067 12.0005 13.8801 9.86721C13.9601 9.60055 14.2467 9.45388 14.5067 9.54055C14.7734 9.62055 14.9134 9.90721 14.8334 10.1672C14.0467 12.6472 11.9801 14.5072 9.44006 15.0272C9.40673 15.0272 9.37339 15.0339 9.34006 15.0339Z" fill="#7B8497"/></svg>' },
  { 'label': 'Urgent', 'value': 'urgent', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#E9203F"/><path d="M8 9.16732C7.72667 9.16732 7.5 8.94065 7.5 8.66732V5.33398C7.5 5.06065 7.72667 4.83398 8 4.83398C8.27333 4.83398 8.5 5.06065 8.5 5.33398V8.66732C8.5 8.94065 8.27333 9.16732 8 9.16732Z" fill="#E9203F"/><path d="M7.99998 11.3339C7.91331 11.3339 7.82665 11.3139 7.74665 11.2806C7.66665 11.2472 7.59331 11.2006 7.52665 11.1406C7.46665 11.0739 7.41998 11.0072 7.38665 10.9206C7.35331 10.8406 7.33331 10.7539 7.33331 10.6672C7.33331 10.5806 7.35331 10.4939 7.38665 10.4139C7.41998 10.3339 7.46665 10.2606 7.52665 10.1939C7.59331 10.1339 7.66665 10.0872 7.74665 10.0539C7.90665 9.98724 8.09331 9.98724 8.25331 10.0539C8.33331 10.0872 8.40665 10.1339 8.47331 10.1939C8.53331 10.2606 8.57998 10.3339 8.61331 10.4139C8.64665 10.4939 8.66665 10.5806 8.66665 10.6672C8.66665 10.7539 8.64665 10.8406 8.61331 10.9206C8.57998 11.0072 8.53331 11.0739 8.47331 11.1406C8.40665 11.2006 8.33331 11.2472 8.25331 11.2806C8.17331 11.3139 8.08665 11.3339 7.99998 11.3339Z" fill="#E9203F"/></svg>' },
  { 'label': 'Higher Priority', 'value': 'higherpriority', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#FF6700"/><path d="M8.00001 7.99728C7.88578 7.99728 7.77156 8.03909 7.68139 8.12868L5.55928 10.237C5.38494 10.4102 5.38494 10.6969 5.55928 10.8701C5.73362 11.0433 6.02218 11.0433 6.19651 10.8701L8.00001 9.07832L9.8035 10.8701C9.97783 11.0433 10.2664 11.0433 10.4407 10.8701C10.6151 10.6969 10.6151 10.4102 10.4407 10.237L8.31862 8.12868C8.22845 8.03909 8.11423 7.99728 8.00001 7.99728Z" fill="#FF6700"/><path d="M8.00001 4.99923C7.88578 4.99923 7.77156 5.04104 7.68139 5.13063L5.55928 7.23895C5.38494 7.41216 5.38494 7.69884 5.55928 7.87205C5.73362 8.04525 6.02218 8.04525 6.19651 7.87205L8.00001 6.08027L9.8035 7.87205C9.97783 8.04525 10.2664 8.04525 10.4407 7.87205C10.6151 7.69884 10.6151 7.41216 10.4407 7.23895L8.31862 5.13063C8.22845 5.04104 8.11423 4.99923 8.00001 4.99923Z" fill="#FF6700"/></svg>' },
  { 'label': 'High Priority', 'value': 'highpriority', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#FEB53E"/><path d="M10.3533 9.33995C10.2266 9.33995 10.1 9.29328 9.99998 9.19328L7.99998 7.19328L5.99998 9.19328C5.80665 9.38662 5.48665 9.38662 5.29332 9.19328C5.09998 8.99995 5.09998 8.67995 5.29332 8.48661L7.64665 6.13328C7.83998 5.93995 8.15998 5.93995 8.35332 6.13328L10.7066 8.48661C10.9 8.67995 10.9 8.99995 10.7066 9.19328C10.6066 9.29328 10.48 9.33995 10.3533 9.33995Z" fill="#FEB53E"/></svg>' },
  { 'label': 'Medium', 'value': 'medium', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#F5CD00"/><path d="M7.99998 8.66732C7.62665 8.66732 7.33331 8.36732 7.33331 8.00065C7.33331 7.63398 7.63331 7.33398 7.99998 7.33398C8.36665 7.33398 8.66665 7.63398 8.66665 8.00065C8.66665 8.36732 8.37331 8.66732 7.99998 8.66732Z" fill="#F5CD00"/><path d="M10.6667 8.66732C10.2933 8.66732 10 8.36732 10 8.00065C10 7.63398 10.3 7.33398 10.6667 7.33398C11.0333 7.33398 11.3333 7.63398 11.3333 8.00065C11.3333 8.36732 11.04 8.66732 10.6667 8.66732Z" fill="#F5CD00"/><path d="M5.33335 8.66732C4.96002 8.66732 4.66669 8.36732 4.66669 8.00065C4.66669 7.63398 4.96669 7.33398 5.33335 7.33398C5.70002 7.33398 6.00002 7.63398 6.00002 8.00065C6.00002 8.36732 5.70669 8.66732 5.33335 8.66732Z" fill="#F5CD00"/></svg>' },
  { 'label': 'Low Priority', 'value': 'lowpriority', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#00B84D"/><path d="M7.99998 10.006C7.87332 10.006 7.74665 9.9593 7.64665 9.8593L5.29332 7.50596C5.09998 7.31263 5.09998 6.99263 5.29332 6.7993C5.48665 6.60596 5.80665 6.60596 5.99998 6.7993L7.99998 8.7993L9.99998 6.7993C10.1933 6.60596 10.5133 6.60596 10.7066 6.7993C10.9 6.99263 10.9 7.31263 10.7066 7.50596L8.35332 9.8593C8.25332 9.9593 8.12665 10.006 7.99998 10.006Z" fill="#00B84D"/></svg>' },
  { 'label': 'Lowest Priority', 'value': 'lowestpriority', 'icon': '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#4783FF"/><path d="M8.00007 8.00272C7.88585 8.00272 7.77162 7.96091 7.68145 7.87132L5.55934 5.763C5.385 5.58979 5.385 5.30311 5.55934 5.1299C5.73368 4.9567 6.02224 4.9567 6.19658 5.1299L8.00007 6.92168L9.80356 5.1299C9.9779 4.9567 10.2665 4.9567 10.4408 5.1299C10.6151 5.30311 10.6151 5.58979 10.4408 5.763L8.31868 7.87132C8.22851 7.96091 8.11429 8.00272 8.00007 8.00272Z" fill="#4783FF"/><path d="M8.00007 11.0008C7.88585 11.0008 7.77162 10.959 7.68145 10.8694L5.55934 8.76105C5.385 8.58784 5.385 8.30116 5.55934 8.12795C5.73368 7.95475 6.02224 7.95475 6.19658 8.12795L8.00007 9.91973L9.80356 8.12795C9.9779 7.95475 10.2665 7.95475 10.4408 8.12795C10.6151 8.30116 10.6151 8.58784 10.4408 8.76105L8.31868 10.8694C8.22851 10.959 8.11429 11.0008 8.00007 11.0008Z" fill="#4783FF"/></svg>' },
]);
onMounted(() => {

})

function selectPriority(priority) {
  emits('selected:item', priority);
  closePopup();
}

function closePopup() {
  emits('update:showPopup', false);
}
function onClickOutside() {
  if (justMounted.value == 0) {
    justMounted.value = justMounted.value + 1;
    return;
  }
  closePopup();
}
</script>

<template>
  <div class="" style="min-width:180px;" :class="{ 'show': true }">
    <ul class="mb-0">
      <li v-for="(item, index) in priority" :key="index" style="cursor: pointer;">
        <label v-bind:for="item.value" @click="selectPriority(item)">
          <div class="row">
            <div class="dropdown-element-exclude d-flex col align-items-center" style="gap: 8px;">
              <span v-html="item.icon"></span> {{ item.label }}
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}


.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>