<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M21.97 6.41V12.91H2V6.41C2 3.98 3.98 2 6.41 2H17.56C19.99 2 21.97 3.98 21.97 6.41Z"
      fill="#7B8497"
    />
    <path
      d="M2 12.92V13.12C2 15.56 3.98 17.53 6.41 17.53H10.25C10.8 17.53 11.25 17.98 11.25 18.53V19.5C11.25 20.05 10.8 20.5 10.25 20.5H7.83C7.42 20.5 7.08 20.84 7.08 21.25C7.08 21.66 7.41 22 7.83 22H16.18C16.59 22 16.93 21.66 16.93 21.25C16.93 20.84 16.59 20.5 16.18 20.5H13.76C13.21 20.5 12.76 20.05 12.76 19.5V18.53C12.76 17.98 13.21 17.53 13.76 17.53H17.57C20.01 17.53 21.98 15.55 21.98 13.12V12.92H2Z"
      fill="#7B8497"
    />
  </svg>
</template>
