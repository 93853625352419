<template>
  <div class="d-flex flex-nowrap" style="height: 100vh; width: 100vw">
    <div style="height: 100vh">
      <SideBar></SideBar>
    </div>
    <main class="col overflow-auto">
      <div class="p-1 z-3">
        <div class="align-items-center d-flex mobile-header w-100">
          <button class="toggleNavBtn d-none" @click="showSideBar" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M12.5 18.9584H7.50002C2.97502 18.9584 1.04169 17.025 1.04169 12.5V7.50002C1.04169 2.97502 2.97502 1.04169 7.50002 1.04169H12.5C17.025 1.04169 18.9584 2.97502 18.9584 7.50002V12.5C18.9584 17.025 17.025 18.9584 12.5 18.9584ZM7.50002 2.29169C3.65835 2.29169 2.29169 3.65835 2.29169 7.50002V12.5C2.29169 16.3417 3.65835 17.7084 7.50002 17.7084H12.5C16.3417 17.7084 17.7084 16.3417 17.7084 12.5V7.50002C17.7084 3.65835 16.3417 2.29169 12.5 2.29169H7.50002Z"
                fill="#292D32" />
              <path
                d="M7.5 18.9584C7.15833 18.9584 6.875 18.675 6.875 18.3334V1.66669C6.875 1.32502 7.15833 1.04169 7.5 1.04169C7.84167 1.04169 8.125 1.32502 8.125 1.66669V18.3334C8.125 18.675 7.84167 18.9584 7.5 18.9584Z"
                fill="#292D32" />
            </svg>
          </button>
          <div class="d-md-none only-mobile w-50">
            <div class="logoSection d-flex justify-content-between">
              <!-- <img class="image-sidebar-logo" src="./../../assets/images/logo-icon.svg" alt="" /> -->
              <!-- Profile image button -->
              <div class="btn-group sidemenu">
                <!-- <button type="button" class="profileBtn dropdown-toggle" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img v-if="user && user.avatar && isImage" class="w-100 rounded-circle" :src="user.avatar" />
                  <span :id="user.avatar" v-if="user && user.avatar && isColor" :style="{
            backgroundColor: user.avatar,
          }" class="d-block rounded-circle w-100 h-100"></span>
                  <span v-if="user && !user.avatar" class="name-initials">
                    {{
            user && user.first_name && user.last_name
              ? user.first_name[0] + user.last_name[0]
              : user && user.username
                ? user.username[0] + user.username[1]
                : user && user.email
                  ? user.email[0] + user.email[1]
                    : ""
                    }}
                  </span>
                </button> -->
                <ul class="dropdown-menu profile-menu p-0">
                  <li>
                    <router-link to="/user-profile" class="px-2">
                      <!-- <span class="icon-view-profile"></span> -->
                      <ViewProfile />
                      <span class="ms-2">View Profile</span>
                    </router-link>
                  </li>
                  <li>
                    <!-- <a href="#" class="px-2"> -->
                    <router-link to="/user-preferences" class="px-2">
                      <!-- <span class="icon-preferences"></span> -->
                      <Preferences />
                      <span class="ms-2">Preferences</span>
                      <!-- </a> -->
                    </router-link>
                  </li>
                  <li>
                    <a href="#" class="px-2">
                      <!-- <span class="icon-linked-accounts"></span> -->
                      <LinkedAccounts />
                      <span class="ms-2">Linked Accounts</span>
                    </a>
                  </li>
                  <li>
                    <router-link to="/user-notifications" class="px-2">
                      <!-- <span class="icon-preferences"></span> -->
                      <Settings />
                      <span class="ms-2">Notification</span>
                      <!-- </a> -->
                    </router-link>
                  </li>
                  <!--
              <li>
                <a @click="logout">
                  <span class="icon-logout"></span>
                  <span>Log Out</span>
                </a>
              </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <slot>
          <router-view />
        </slot>
      </div>
    </main>
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";
import api from "../../api";
import Preferences from "../ProfileMenuIcons/PreferencesIcon";
import ViewProfile from "../ProfileMenuIcons/ViewProfileIcon";
import LinkedAccounts from "../ProfileMenuIcons/LinkedAccountsIcon";
import Settings from "../ProfileMenuIcons/SettingsIcon";
export default {
  name: "AdminLayout",
  components: {
    SideBar,
    Preferences,
    ViewProfile,
    LinkedAccounts,
    Settings,
  },
  mounted() {
    this.getProfile();
  },
  data() {
    return {};
  },
  methods: {
    showSideBar() {
      const appDiv = document.getElementById("app");
      appDiv.classList.toggle("showMenu");
    },
    getProfile() {
      api
        .get("user/profile")
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data.user;
            localStorage.setItem("userProfile", JSON.stringify(this.user));
            if (this.user && this.user.avatar) {
              if (this.user.avatar.startsWith("#")) {
                this.isColor = true;
                this.isImage = false;
              } else {
                this.isColor = false;
                this.isImage = true;
              }
            }
          }
        })
        .catch((error) => {
          console.error(error, "asdasdas");
        });
    },
  },
};
</script>

<style>
/* Add styles for the layout */
.layout {
  display: flex;
}

SideBar {
  width: 200px;
  background-color: #f1f1f1;
  /* Add any other styling properties as needed */
}

.content {
  flex-grow: 1;
  /* Add any other styling properties as needed */
}

.profile-menu {
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.sidemenu ul li a,
.sidemenu ul li button {
  padding: 6px;
  border-radius: 5px;
  transition: ease-in-out 0.15s;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
  text-decoration: none;
  display: flex;
  color: #212529 !important;
  align-items: center;
  border: 0;
  width: 100%;
}
</style>
