<script setup>
import AboutCard from "@/components/AboutCard";
import api from "../api";
import InputField from "@/components/Fields/InputField";
import RememberForgot from "@/components/Fields/RemeberForgot";
import LogoEmailMobile from "@/components/Fields/LogoEmailMobile";
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from "vue-toastification";
import Danger from "./ToastIcons/ErrorIcon";
import Success from "./ToastIcons/SuccessIcon.vue";
const toast = useToast();
const props = defineProps(['fetchData']) 
const route = useRoute()
const router = useRouter()
const showForm = ref(false);
const password = ref("");
const confirmpassword = ref("");
onMounted(() => {
  const user = route.params.to;
  checkUserAccount(user);
})
function checkUserAccount(user) {
  api.get(`public/invite/${user}/check`).then((response) => {
    if(response.status == 200) {
      if(response.data.is_active == false) {
        showForm.value = true;
      }
      else {
        api.get(`public/invite/${user}/accept`).then((response) => {
          if(response.status == 200) {
            toast("Invitation accepted successfully", {
              position: "top-right",
              timeout: 5025,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: Success,
              rtl: false,
            });
            router.push("/auth/login");
          }
        });
      }
    }
    else {
      toast("Something went wrong.", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    }
  });
}
function submitForm() {
  if(password.value == "") {
    toast("Please provide password.", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  else if(password.value != confirmpassword.value) {
    toast("Password and confirm password do not match.", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  const user = route.params.to;
  api.post(`public/invite/${user}/set-password`,{
    password: password.value
  }).then((response) => {
    if(response.status == 200) {
      api.get(`public/invite/${user}/accept`).then((response) => {
          if(response.status == 200) {
            toast("Invitation accepted successfully", {
              position: "top-right",
              timeout: 5025,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: Success,
              rtl: false,
            });
            router.push("/auth/login");
          }
        });
    }
  });
}
</script>
<template>
  <div class="px-0 mx-0" style="height: 100vh">
    <div class="row">
      <div class="col-md-6">
        <div class="card form-alignment">
          <div class="card-body d-flex justify-content-center">
            <LogoEmailMobile></LogoEmailMobile>
          </div>
          <div class="card-body px-3">
            <div class="text-center" v-if="showForm == false">
              <h3 class="fw-bold text-white">Thank you, your invitation has been accepted.</h3>
              <br>
              <p class="fw-bold text-white" style="font-size: 1rem;">You will be redirected shortly. Please wait...</p>
            </div>
            <div class="text-center" v-if="showForm == true">
              <h3 class="fw-bold text-white">Please set your password</h3>
            </div>
            <form @submit.prevent="submitForm" v-if="showForm == true">
              <InputField
                :icon="'fas fa-lock favicon'"
                placeholder="Password"
                type="password"
                id="password"
                ref="inputField"
                v-model="password"
              ></InputField>
              <InputField
                :icon="'fas fa-lock favicon'"
                placeholder="Confirm Password"
                type="password"
                id="cpassword"
                :isdisabled="false"
                v-model="confirmpassword"
              ></InputField>
              
              <div class="d-flex justify-content-center align-center">
                <button
                  class="custom-primary-btn"
                  style="font-family: Roboto"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <AboutCard />
      </div>
    </div>
  </div>
</template>

<style>
.about {
  padding-left: 100px;
  font-family: Roboto, sans-serif;
}

.form-alignment {
  left: 100px;
  top: 150px;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border: none;
  /*font-family: Roboto,sans-serif;*/
}

.card-header {
  margin-bottom: 8px;
}

h3 {
  font-size: 18px;
  /*font-family: Roboto,sans-serif;*/
}

p {
  font-size: 14px;
  color: #666;
}

.icons i {
  position: absolute;
  color: #0000006b;
}
.icons {
  width: 100%;
  margin-bottom: 10px;
}
.favicon {
  padding: 13px 0 0 10px;
  min-width: 40px;
}
.inputform {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding-left: 40px;
  padding-top: 0px;
  height: 45px;
  width: 100%;
  color: #070707;
}

.error {
  color: #f96332 !important;
}
.card-body {
  flex: 1 1 auto;
  padding: 0px 0px 15px 0px;
  color: var(--bs-card-color);
}
.signup-button {
  border: none;
  position: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  padding: 5px 15px;
  font-family: Roboto, serif;
  font-size: 22px;
}
button:hover {
  background-color: #f96332; /* Change to desired hover color */
  color: #ffffff; /* Change to desired hover color */
}
.image {
  height: 20px;
  border-radius: 10px;
  background: transparent;
  width: 20px;
}

@media screen and (max-width: 767px) {
  .form-alignment {
    left: auto;
    top: 30%;
  }
  button:hover {
    background-color: #f96332; /* Change to desired hover color */
    color: #f4f4f4 !important; /* Change to desired hover color */
  }
}
</style>
