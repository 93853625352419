<template>
    <node-view-wrapper class="video-view">
        <div class="downloadVideo" v-if="this.node.attrs.type != 'video/mp4'">
            <span>
                Your browser cannot play this video format. Try replacing it with another format, like mp4.
            </span>
            <br />
            <button @click="downloadVideo" class="custom-primary-btn" style="margin-top: 1.5rem;">Download
                Video</button>
        </div>
        <video width="100%" controls v-if="this.node.attrs.type == 'video/mp4'">
            <source :src="this.node.attrs.source"
                :type="this.node.attrs.type == 'video/quicktime' ? 'video/mov' : this.node.attrs.type">
            Your browser does not support the video tag
        </video>
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import api from "@/api";

export default {
    components: {
        NodeViewWrapper,
    },

    props: nodeViewProps,

    methods: {
        increase() {
            this.updateAttributes({
                count: this.node.attrs.count + 1,
            })
        },
        downloadVideo() {
            api
                .get(`storage/images/${this.node.attrs.filePath}`, { responseType: "blob" })
                .then((response) => {
                    if (response.status == 200) {
                        const blob = new Blob([response.data], { type: response.data["type"] });
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = this.node.attrs.filePath;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }
                });
        },
    },
}
</script>

<style lang="scss">
.video-view {
    max-width: 100%;
}

.downloadVideo {
    width: 100%;
    min-height: 150px;
    color: rgb(51, 51, 51);
    margin: 0 auto;
    background-color: #eaeaea;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>