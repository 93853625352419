<template>
  <div class="overflow-scroll mx-2 my-4">
    <div class="row">
      <div class="col-7 flex-grow-1 d-flex align-items-center">
        <span class="text_gray">
          {{ selectedIssuePreview.project?.name }}
        </span>
      </div>
      <div class="col-5 d-flex justify-content-evenly  position-relative"> 
        <StarIcon class="mt-2"/> 
        <FlagIcon  class="mt-2" />
        <span class="fw-bold">...</span>
        <div class="position-relative ms-4 fw-light">
        <i class="fa fa-close custom-fa-close close_icon" @click="closeIssuePreview(issue)"></i>
        </div>
      </div>
    </div>
    <div class="my-2">
        <h1>{{selectedIssuePreview.title}}</h1>
    </div>
    <div>
        <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores animi, repudiandae eius non sint natus optio explicabo, saepe amet fugit odio eos minus nemo temporibus.
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis deserunt fuga veritatis, quibusdam officiis accusamus accusantium iste! Doloribus ipsam eum labore quisquam optio possimus quibusdam obcaecati temporibus, sequi debitis dicta!
        </p>
    </div>
    <div class="d-flex justify-content-between">
        <div class="d-flex flex-wrap">
              <div class="mx-2">
                <DownArrowIcon :class="{ rotateArrowHead: selectedIssuePreview?.isSubtaskOpen }" style="cursor: pointer"
                  @click="selectedIssuePreview.isSubtaskOpen = !selectedIssuePreview?.isSubtaskOpen" />
              </div>
              <div>
                <span class="fw-medium">Subtasks</span>
                <span class="mx-2">
                  <AddCircleIconVue @click="props.showNewIssueWithStatus(index,selectedIssuePreviewIndex)" style="cursor: pointer" />
                </span>
              </div>
        </div>
        <div>
            <span class="fw-medium text-decoration-underline">hide completed </span>
        </div>
    </div>
    <div>
        <div v-show="!selectedIssuePreview.isSubtaskOpen">
             <template v-for="(issue,index ) of selectedIssuePreview.childIssues" :key="issue.id">
                <IssuePreviewSubTask :issueLabels="issueLabels" :editedTaskAssignee="props.editedTaskAssignee" :toggleLabelDropdown="props.toggleLabelDropdown" :getFormttedDate="props.getFormttedDate" :issue="issue"/>
             </template>
        </div>
    </div>
  </div>
</template>

<script setup>
import useState from "@/components/Utilities/useState";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import StarIcon from "@/components/Icons/StarIcon.vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';

import { onBeforeMount, onMounted, watch ,defineProps} from "vue";
import IssuePreviewSubTask from "@/components/Pages/Issues/IssuePreviewSubTask.vue";
const selectedIssuePreview = useState("currentIssuePreview");

function closeIssuePreview(issue){
    selectedIssuePreview.value=null;
}

const selectedIssuePreviewIndex=useState('selectedIssuePreviewIndex');
const props = defineProps(['editedTaskAssignee','showNewIssueWithStatus','index','issueLabels','toggleLabelDropdown','getFormttedDate']);
</script>

<style scoped>
.text_gray {
  color: rgb(157, 153, 153);
  font-size: medium;
}
.close_icon{
    top: 25%;
    right: 0px;
}
</style>
