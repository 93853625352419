<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m16.49 12l3.75 3.75m0 0l-3.75 3.75m3.75-3.75H3.74V4.499"
      />
    </svg>
  </div>
</template>
