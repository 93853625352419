<script setup>
import CupIcon from '@/components/Icons/CupIcon.vue';
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';
import ClockIcon from '@/components/Icons/ClockIcon.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import CreatorIcon from '@/components/Icons/CreatorIcon.vue';
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import EstimateIcon from '@/components/Icons/EstimateIcon.vue';
import LabelTriangleIcon from '@/components/Icons/LabelTriangleIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import LabelIcon from '@/components/Icons/LabelIcon.vue';
import Danger from '../../ToastIcons/ErrorIcon.vue';
import Success from '../../ToastIcons/SuccessIcon.vue';
import DeleteIcon from '@/components/Icons/DeleteIcon.vue';
import InputField from '@/components/Fields/InputField.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
// import DatePicker from "vue-datepicker";
// import "vue-datepicker";
// import 'bootstrap-datepicker';
//import DatePicker from '@/components/Pages/Project/DatePicker.vue';
// const props = defineProps(['showPopup']);
const props = defineProps(['issueId', 'selectedLabels'])
const route = useRoute()
const emits = defineEmits(['update:showPopup', 'selected:label']);
const selectedPriorityDropdown = ref(null);
const name = ref('');
const labels = ref([
  { 'label': 'Milestone1', 'id': '1', 'child': [{ 'label': 'M1.1', 'id': '6', 'color': '#000' }], 'color': '#ccc' },
  { 'label': 'Milestone2', 'id': '2', 'child': null, 'color': '#000' },
  { 'label': 'Milestone3', 'id': '3', 'child': null, 'color': '#FF0' },
  { 'label': 'Milestone4', 'id': '4', 'child': null, 'color': '#F00' },
  { 'label': 'Milestone5', 'id': '5', 'child': null, 'color': '#C40C56' },
]);
onMounted(() => {

})

function selectLabel(labelId) {
  let tempArrayLabels = props.selectedLabels;
  if (tempArrayLabels.includes(labelId)) {
    tempArrayLabels = tempArrayLabels.filter((i) => i !== labelId);
  } else {
    tempArrayLabels.push(labelId);
  }
  emits('selected:label', tempArrayLabels);
  closePopup();
}

function closePopup() {
  emits('update:showPopup', false);
}
const justMounted = ref(0);
function onClickOutside() {
  if (justMounted.value == 0) {
    justMounted.value = justMounted.value + 1;
    return;
  }
  closePopup();
}
</script>

<template>
  <div class="" style="min-width:180px; padding:1rem;" :class="{ 'show': true }">
    <ul class="mb-0">
      <li v-for="label in labels" :key="label.id" @click.stop="">
        <div class="row">
          <div class="col-md-2 p-0">
            <CheckBoxInput :id="'label-' + label.id" :modelValue="props.selectedLabels.includes(label.id)"
              @update:modelValue="selectLabel(label.id)" />
          </div>
          <div class="col-md-1 p-0" style="line-height:25px;padding-right:1rem !important;">
            <span v-if="label.child == null" :style="{
    backgroundColor: label.color,
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '10px'
  }"></span>
            <LabelTriangleIcon v-if="label.child != null" :stroke="label.color" />
          </div>
          <div class="col-md-7 p-0" style="line-height:25px; font-size:14px; color:#3D4046;">
            {{ label.label }}
          </div>
          <div class="col-md-1 p-0" v-if="label.child != null" style="line-height:25px; font-size:14px; color:#3D4046;">
            <DownArrowIcon />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul class="mb-0">
              <li v-for="child in label.child" :key="child.id">
                <div class="row">
                  <div class="col-md-2 p-0">
                    <CheckBoxInput :id="'label-child-' + child.id" :modelValue="props.selectedLabels.includes(child.id)"
                      @update:modelValue="selectLabel(child.id)" />
                  </div>
                  <div class="col-md-1 p-0" style="line-height:25px;padding-right:1rem !important;">
                    <span :style="{
    backgroundColor: child.color,
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '10px'
  }"></span>
                  </div>
                  <div class="col-md-8 p-0" style="line-height:25px; font-size:14px; color:#3D4046;">
                    {{ child.label }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width:768px) {
  .dropdown-menu {
    left: 0;
    right: initial;
  }
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .dropdown-menu.position-bottom {
  top: auto !important;
  bottom: 110% !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block !important;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>