import { createApp, ref } from "vue";
import App from "./App.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import router from "./router";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import api from "./api";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import datePicker from 'vue-datepicker';
import BootstrapVueNext from 'bootstrap-vue-next';
import VueApexCharts from 'vue3-apexcharts';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import "vue-datepicker-next/index.css";

// import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
const reloadIssues = ref(false);
const issuesFiltered = ref(false);
const reloadIssue = ref(false);
const reloadSubTasks = ref(false);
const isSyncing = ref(false);
const globalSyncingTiimer = ref(null);
const isLoggedIn = ref(false);
const showLeftSide = ref(true);
const showRightSide = ref(true);
const showSearch = ref(false);
const showNewIssue = ref(false);
const allIssuesCache = ref({});
const allStatuses = ref([]);
const filterQuery = ref(null);
const showFilterBox = ref(false);
const showNewView = ref(false);
const globalEditor = ref(null);
const globalProfileAvatar = ref("");
const priority = ref([
  {
    label: "No Priority",
    value: "nopriority",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.65984 15.0337C6.62651 15.0337 6.59318 15.0337 6.55984 15.0204C4.00651 14.5004 1.93318 12.6204 1.15984 10.127C1.07984 9.86036 1.22652 9.58036 1.48652 9.50036C1.75318 9.42036 2.03318 9.56703 2.11318 9.82703C2.77984 11.9737 4.55985 13.587 6.75985 14.0337C7.03318 14.087 7.20651 14.3537 7.14651 14.627C7.09985 14.867 6.88651 15.0337 6.65984 15.0337Z" fill="#7B8497"/><path d="M14.6335 7.82065C14.3801 7.82065 14.1601 7.62732 14.1335 7.37398C13.8135 4.21398 11.1735 1.83398 8.00015 1.83398C4.82015 1.83398 2.18682 4.21398 1.86682 7.36732C1.84015 7.64065 1.60015 7.84732 1.32015 7.81398C1.04682 7.78732 0.846812 7.54065 0.873479 7.26732C1.24681 3.60065 4.31348 0.833984 8.00015 0.833984C11.6935 0.833984 14.7601 3.60065 15.1268 7.26732C15.1535 7.54065 14.9535 7.78732 14.6801 7.81398C14.6668 7.82065 14.6468 7.82065 14.6335 7.82065Z" fill="#7B8497"/><path d="M9.34006 15.0339C9.10673 15.0339 8.90006 14.8739 8.85339 14.6339C8.80006 14.3605 8.97339 14.1005 9.24006 14.0472C11.4267 13.6005 13.2067 12.0005 13.8801 9.86721C13.9601 9.60055 14.2467 9.45388 14.5067 9.54055C14.7734 9.62055 14.9134 9.90721 14.8334 10.1672C14.0467 12.6472 11.9801 14.5072 9.44006 15.0272C9.40673 15.0272 9.37339 15.0339 9.34006 15.0339Z" fill="#7B8497"/></svg>',
  },
  {
    label: "Urgent",
    value: "urgent",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#E9203F"/><path d="M8 9.16732C7.72667 9.16732 7.5 8.94065 7.5 8.66732V5.33398C7.5 5.06065 7.72667 4.83398 8 4.83398C8.27333 4.83398 8.5 5.06065 8.5 5.33398V8.66732C8.5 8.94065 8.27333 9.16732 8 9.16732Z" fill="#E9203F"/><path d="M7.99998 11.3339C7.91331 11.3339 7.82665 11.3139 7.74665 11.2806C7.66665 11.2472 7.59331 11.2006 7.52665 11.1406C7.46665 11.0739 7.41998 11.0072 7.38665 10.9206C7.35331 10.8406 7.33331 10.7539 7.33331 10.6672C7.33331 10.5806 7.35331 10.4939 7.38665 10.4139C7.41998 10.3339 7.46665 10.2606 7.52665 10.1939C7.59331 10.1339 7.66665 10.0872 7.74665 10.0539C7.90665 9.98724 8.09331 9.98724 8.25331 10.0539C8.33331 10.0872 8.40665 10.1339 8.47331 10.1939C8.53331 10.2606 8.57998 10.3339 8.61331 10.4139C8.64665 10.4939 8.66665 10.5806 8.66665 10.6672C8.66665 10.7539 8.64665 10.8406 8.61331 10.9206C8.57998 11.0072 8.53331 11.0739 8.47331 11.1406C8.40665 11.2006 8.33331 11.2472 8.25331 11.2806C8.17331 11.3139 8.08665 11.3339 7.99998 11.3339Z" fill="#E9203F"/></svg>',
  },
  {
    label: "Higher Priority",
    value: "higherpriority",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#FF6700"/><path d="M8.00001 7.99728C7.88578 7.99728 7.77156 8.03909 7.68139 8.12868L5.55928 10.237C5.38494 10.4102 5.38494 10.6969 5.55928 10.8701C5.73362 11.0433 6.02218 11.0433 6.19651 10.8701L8.00001 9.07832L9.8035 10.8701C9.97783 11.0433 10.2664 11.0433 10.4407 10.8701C10.6151 10.6969 10.6151 10.4102 10.4407 10.237L8.31862 8.12868C8.22845 8.03909 8.11423 7.99728 8.00001 7.99728Z" fill="#FF6700"/><path d="M8.00001 4.99923C7.88578 4.99923 7.77156 5.04104 7.68139 5.13063L5.55928 7.23895C5.38494 7.41216 5.38494 7.69884 5.55928 7.87205C5.73362 8.04525 6.02218 8.04525 6.19651 7.87205L8.00001 6.08027L9.8035 7.87205C9.97783 8.04525 10.2664 8.04525 10.4407 7.87205C10.6151 7.69884 10.6151 7.41216 10.4407 7.23895L8.31862 5.13063C8.22845 5.04104 8.11423 4.99923 8.00001 4.99923Z" fill="#FF6700"/></svg>',
  },
  {
    label: "High Priority",
    value: "highpriority",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#FEB53E"/><path d="M10.3533 9.33995C10.2266 9.33995 10.1 9.29328 9.99998 9.19328L7.99998 7.19328L5.99998 9.19328C5.80665 9.38662 5.48665 9.38662 5.29332 9.19328C5.09998 8.99995 5.09998 8.67995 5.29332 8.48661L7.64665 6.13328C7.83998 5.93995 8.15998 5.93995 8.35332 6.13328L10.7066 8.48661C10.9 8.67995 10.9 8.99995 10.7066 9.19328C10.6066 9.29328 10.48 9.33995 10.3533 9.33995Z" fill="#FEB53E"/></svg>',
  },
  {
    label: "Medium",
    value: "medium",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#F5CD00"/><path d="M7.99998 8.66732C7.62665 8.66732 7.33331 8.36732 7.33331 8.00065C7.33331 7.63398 7.63331 7.33398 7.99998 7.33398C8.36665 7.33398 8.66665 7.63398 8.66665 8.00065C8.66665 8.36732 8.37331 8.66732 7.99998 8.66732Z" fill="#F5CD00"/><path d="M10.6667 8.66732C10.2933 8.66732 10 8.36732 10 8.00065C10 7.63398 10.3 7.33398 10.6667 7.33398C11.0333 7.33398 11.3333 7.63398 11.3333 8.00065C11.3333 8.36732 11.04 8.66732 10.6667 8.66732Z" fill="#F5CD00"/><path d="M5.33335 8.66732C4.96002 8.66732 4.66669 8.36732 4.66669 8.00065C4.66669 7.63398 4.96669 7.33398 5.33335 7.33398C5.70002 7.33398 6.00002 7.63398 6.00002 8.00065C6.00002 8.36732 5.70669 8.66732 5.33335 8.66732Z" fill="#F5CD00"/></svg>',
  },
  {
    label: "Low Priority",
    value: "lowpriority",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#00B84D"/><path d="M7.99998 10.006C7.87332 10.006 7.74665 9.9593 7.64665 9.8593L5.29332 7.50596C5.09998 7.31263 5.09998 6.99263 5.29332 6.7993C5.48665 6.60596 5.80665 6.60596 5.99998 6.7993L7.99998 8.7993L9.99998 6.7993C10.1933 6.60596 10.5133 6.60596 10.7066 6.7993C10.9 6.99263 10.9 7.31263 10.7066 7.50596L8.35332 9.8593C8.25332 9.9593 8.12665 10.006 7.99998 10.006Z" fill="#00B84D"/></svg>',
  },
  {
    label: "Lowest Priority",
    value: "lowestpriority",
    icon: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.99998 15.1673C4.04665 15.1673 0.833313 11.954 0.833313 8.00065C0.833313 4.04732 4.04665 0.833984 7.99998 0.833984C11.9533 0.833984 15.1666 4.04732 15.1666 8.00065C15.1666 11.954 11.9533 15.1673 7.99998 15.1673ZM7.99998 1.83398C4.59998 1.83398 1.83331 4.60065 1.83331 8.00065C1.83331 11.4007 4.59998 14.1673 7.99998 14.1673C11.4 14.1673 14.1666 11.4007 14.1666 8.00065C14.1666 4.60065 11.4 1.83398 7.99998 1.83398Z" fill="#4783FF"/><path d="M8.00007 8.00272C7.88585 8.00272 7.77162 7.96091 7.68145 7.87132L5.55934 5.763C5.385 5.58979 5.385 5.30311 5.55934 5.1299C5.73368 4.9567 6.02224 4.9567 6.19658 5.1299L8.00007 6.92168L9.80356 5.1299C9.9779 4.9567 10.2665 4.9567 10.4408 5.1299C10.6151 5.30311 10.6151 5.58979 10.4408 5.763L8.31868 7.87132C8.22851 7.96091 8.11429 8.00272 8.00007 8.00272Z" fill="#4783FF"/><path d="M8.00007 11.0008C7.88585 11.0008 7.77162 10.959 7.68145 10.8694L5.55934 8.76105C5.385 8.58784 5.385 8.30116 5.55934 8.12795C5.73368 7.95475 6.02224 7.95475 6.19658 8.12795L8.00007 9.91973L9.80356 8.12795C9.9779 7.95475 10.2665 7.95475 10.4408 8.12795C10.6151 8.30116 10.6151 8.58784 10.4408 8.76105L8.31868 10.8694C8.22851 10.959 8.11429 11.0008 8.00007 11.0008Z" fill="#4783FF"/></svg>',
  },
]);
const filterTypes = ref(['status', 'priority']);
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      let classesToExclude = ['ce-popover-item__title', 'ce-popover-item', 'dropdown-element-exclude'];
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (event.target.classList.length == 0) {
          binding.value();
        }
        else if (!classesToExclude.includes(event.target.classList[0])) {
          binding.value();
        }
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
const app = createApp(App);
app.use(datePicker);
app.use(BootstrapVueNext);
app.use(router);
app.use(FloatingVue);
app.provide('reloadIssues', reloadIssues);
app.provide('reloadIssue', reloadIssue);
app.provide('reloadSubTasks', reloadSubTasks);
app.provide('isSyncing', isSyncing);
app.provide('isLoggedIn', isLoggedIn);
app.provide('issuesFiltered', issuesFiltered);
//Variables for short keys
app.provide('showLeftSide', showLeftSide);
app.provide('showRightSide', showRightSide);
app.provide('showSearch', showSearch);
app.provide('showNewIssue', showNewIssue);
app.provide('allIssuesCache', allIssuesCache);
app.provide('allStatuses', allStatuses);
app.provide('filterQuery', filterQuery);
app.provide('priority', priority);
app.provide('filterTypes', filterTypes);
app.provide('showFilterBox', showFilterBox);
app.provide('showNewView', showNewView);
app.provide('globalEditor', globalEditor);
app.provide('globalProfileAvatar', globalProfileAvatar);
app.use(VueApexCharts);

app.directive("click-outside", clickOutside);
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
});
app.mount("#app");
library.add(faFacebook);

