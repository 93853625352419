<template>
    <div class="border-bottom d-flex justify-content-evenly">
      <div class="statusWrapper" v-if="display_properties.isStatus">
        <BDropdown
          variant="link"
          v-model="sub_issue.openStatus"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <ClockIcon
              style="width: 16px; height: 16px"
              v-if="sub_issue.status == null"
            />
            <Backlog
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'backlog'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <NotStarted
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'notstarted'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Started
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'started'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Testing
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'testing'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Pending
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'pending'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Completed
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'completed'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Cancelled
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'cancelled'
              "
              :fill="sub_issue.issuestatus.color"
            />
          </template>
          <StatusDropdown
            :workflowId="sub_issue.issueType.workflow_id"
            v-if="sub_issue['openStatus']"
            @selected:item="props.editedTaskStatus(sub_issue, $event)"
            @update:showPopup="sub_issue['openStatus'] = $event"
          />
        </BDropdown>
      </div>
      <div>
         <p class="fw-medium text-start">{{ sub_issue.title }}</p>
      </div>
      <div class="rightWrapper" style="width: 30%">
        <div
          class="issuesListAssigneeWrapper"
          v-if="display_properties.isAssignee"
          style="float: right"
        >
          <BDropdown
            v-model="sub_issue['openAssignee']"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <DisplayAvatar
                :avatar="
                  sub_issue.assigneeUser == null
                    ? null
                    : sub_issue.assigneeUser['avatar']
                "
              />
            </template>
            <AssigneeDropdown
              v-if="sub_issue.team_id != undefined"
              :teamId="sub_issue.team_id"
              class="issuesAssignee"
              @selected:item="props.editedTaskAssignee(sub_issue, $event)"
            />
          </BDropdown>
        </div>
        <div
          class="time-wrapper"
          v-if="display_properties.isCreated"
          style="
            float: right;
            padding: 0 5px;
            line-height: 25px;
            font-size: 12px;
          "
        >
          {{ getFormttedDate(sub_issue.created_at) }}
        </div>
        <template v-if="display_properties.isLabels">
          <div
            class="labelWrapper d-none d-md-inline"
            v-if="sub_issue['labelsArray']?.length <= 2"
            style="float: right"
          >
            <div
              v-for="(label, indexlabel) in sub_issue['labelsArray']"
              :key="indexlabel"
              :style="{
                background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                'border-radius': '20px',
                padding: '5px 10px',
                'font-size': '10px',
                'margin-right': '5px',
                display: 'inline-block',
                'margin-bottom': '5px',
              }"
            >
              <span
                :style="{
                  background: label.color,
                  width: '8px',
                  height: '8px',
                  'border-radius': '10px',
                  display: 'inline-block',
                }"
              ></span>
              {{ label.label }}
            </div>
          </div>
          <div
            style="float: right"
            class="labelWrapper position-relative d-none d-md-inline"
            v-if="sub_issue['labelsArray']?.length > 2"
          >
            <div
              @click="props.toggleLabelDropdown(issue)"
              :style="{
                background: `rgba(${sub_issue['labelsArray']?.[0]?.['colorArray'][0]},${sub_issue['labelsArray']?.[0]?.['colorArray']?.[1]},${sub_issue['labelsArray']?.[0]?.['colorArray']?.[2]}, 0.3)`,
                'border-radius': '20px',
                padding: '5px 10px',
                'font-size': '10px',
                'margin-right': '5px',
                display: 'inline-block',
                'margin-bottom': '5px',
              }"
            >
              <span
                :style="{
                  background: sub_issue['labelsArray']?.[0]?.['color'],
                  width: '8px',
                  height: '8px',
                  'border-radius': '10px',
                  display: 'inline-block',
                }"
              ></span>
              {{ sub_issue["labelsArray"]?.length + " labels" }}
            </div>
            <div
              v-if="sub_issue?.LabelsDropdownOpen"
              class="position-absolute z-3 bg-white rounded-2 border-1 shadow-lg end-50"
            >
              <div class="ps-4 py-2 pt-3 d-flex align-items-center gap-2">
                <SearchIcon />
                <input
                  type="text"
                  class="border-0 input-no-outline"
                  placeholder="Search ..."
                />
              </div>
              <ul class="mb-0 p-2">
                <li
                  v-for="(itemLabel, index) in issueLabels"
                  class="list-unstyled"
                  :key="index"
                >
                  <div class="row">
                    <div class="d-flex my-1 align-items-center">
                      <CheckBoxInput
                        @click="itemLabel.value = !itemLabel.value"
                        :modelValue="itemLabel.value"
                        class="me-4 mt-1"
                      />
                      <div class="d-flex align-items-center px-2 me-5">
                        <span
                          :style="{
                            background: itemLabel.color,
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"
                        ></span>
                        <span class="ms-2">{{ itemLabel.label }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
      <span class="fw-normal">...</span>
    </div>
</template>
<script setup>
import { defineProps,onMounted,ref, watch } from 'vue';
import useState from '@/components/Utilities/useState';
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import SubtaskIcon from "@/components/Icons/SubtaskIcon.vue";
import AssigneeDropdown from "@/components/Pages/General/AssigneeDropdown.vue";
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PriorityDropdown from "@/components/Pages/General/PriorityDropdown.vue";
import StatusDropdown from "@/components/Pages/General/StatusDropdown.vue";

import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";

const display_properties=useState('display_properties')
const props=defineProps(['issue','editedTaskAssignee','issueLabels','toggleLabelDropdown','getFormttedDate']);
//ref
const sub_issue=ref(props.issue)
watch(()=> props.issue,(newValue)=>{
sub_issue.value=newValue
},{deep:true})
</script>