<script setup>
import { onMounted, ref, inject, watch,computed } from "vue";
import { Container, Draggable } from "vue-dndrop";
import KanbanSaturn from "@/components/Icons/KanbanSaturn.vue";
import KanbanCalendar from "@/components/Icons/KanbanCalendar.vue";
import KanbanFlag from "@/components/Icons/KanbanFlag.vue";
import KanbanLight from "@/components/Icons/KanbanLight.vue";
import KanbanProgress from "@/components/Icons/KanbanProgress.vue";
import KanbanSite from "@/components/Icons/KanbanSite.vue";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import KanbanBlock from "@/components/Icons/KanbanBlock.vue";
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import NewIssue from '@/components/Pages/Issues/NewIssue.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import PriorityDropdown from '@/components/Pages/General/PriorityDropdown.vue';
import useShowEmptyGroupinginMyissue from "@/components/Utilities/useShowEmptyGroupinginMyissue.js"; 
const { isShow:showEmptyGrouping } = useShowEmptyGroupinginMyissue();
import useState from "@/components/Utilities/useState";
import IssuePreview from "@/components/Pages/Issues/IssuePreview.vue"

const selectedIssuePreview = useState('currentIssuePreview'); 

const props = defineProps(['issues', 'statuses',"issuesList",'displayProperties']);
const emits = defineEmits(['updateIssueStatus', 'updateIssuePriority']);
const selectedStatusForNewIssue = ref('');
const showPopup = ref(false);
const localIssues = ref(JSON.parse(JSON.stringify(props.issues)));
const issuesFiltered = inject('issuesFiltered');
const workspaceSlug = ref('temp');
workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
watch(issuesFiltered, (newValue, oldValue) => {
  scene.value = {
    type: 'container',
    props: {
      orientation: 'horizontal',
    },
    children: setupColumns(),
  };
});

function togglePriorityDropdown(columnIndex, cardIndex) {
  scene.value.children[columnIndex]['children'][cardIndex].props['openPriority'] = !scene.value.children[columnIndex]['children'][cardIndex].props['openPriority'];
}
function showNewIssueWithStatus(index) {
  selectedStatusForNewIssue.value = props.statuses[index];
  showPopup.value = true;
}

function getCardPayload(columnId) {
  return (index) => {
    return scene.value.children.filter((p) => p.id === columnId)[0].children[
      index
    ];
  };
}

function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

const dropPlaceholderOptions = {
  className: "drop-preview",
  animationDuration: "150",
  showOnTop: true,
};

function setupColumns() {
  let finalReturnArray = [];
  for (let sta of props.statuses) {
    let columnChild = {
      id: `column${sta.id}`,
      type: 'container',
      name: sta.label,
      props: {
        orientation: 'vertical',
        className: 'card-container',
        icon: sta.icon
      },
    };
    let issuesArray = [];
    if (props.issues['status' + sta.label] != undefined) {
      for (let issue of props.issues['status' + sta.label]) {
        let issueDic = {
          type: 'draggable',
          id: issue.id,
          props: {
            className: 'card',
            project: issue.project != null ? issue.project.name : "",
            createdBy: issue.createdBy,
            priority: issue.priority,
            labelsArray: issue.labelsArray,
            openPriority: false,
            openLabels: false
          },
          number: issue.identifier,
          data: issue.title
        };
        issuesArray.push(issueDic);
      }
      columnChild['children'] = issuesArray;
      finalReturnArray.push(columnChild);
    }
    else {
      columnChild['children'] = [];
      finalReturnArray.push(columnChild);
    }
  }
  return showEmptyGrouping.value ? finalReturnArray : finalReturnArray?.filter((v)=>{
      return v.children?.length > 0;
    });
}
const scene = ref({
  type: 'container',
  props: {
    orientation: 'horizontal',
  },
  children: setupColumns(),
});
function onColumnDrop(dropResult) {
  const localScene = Object.assign({}, scene.value);
  localScene.children = applyDrag(localScene.children, dropResult);
  scene.value = localScene;
}
let counter = 0;
function onCardDrop(columnId, dropResult) {
  if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
    const localscene = Object.assign({}, scene.value);
    const column = localscene.children.filter((p) => p.id === columnId)[0];
    const columnIndex = localscene.children.indexOf(column);

    const newColumn = Object.assign({}, column);
    newColumn.children = applyDrag(newColumn.children, dropResult);
    localscene.children.splice(columnIndex, 1, newColumn);
    scene.value = localscene;
    if (dropResult.addedIndex !== null) {

      let status = null;
      for (let sta of props.statuses) {
        if (`column${sta.id}` == columnId) {
          status = sta;
          break;
        }
      }
      let issueTemp = getIssueFromCardId(dropResult.payload.id);
      if (status != null && issueTemp != null) {
        emits('updateIssueStatus', issueTemp, status);
      }
    }
    counter++;
  }
}

function getIssueFromCardId(cardId) {
  let issueTemp = null;
  for (let sta of props.statuses) {
    let found = false;
    if (props.issues['status' + sta.label] != undefined) {
      for (let iss of props.issues['status' + sta.label]) {
        if (iss.id == cardId) {
          issueTemp = iss;
          found = true;
          break;
        }
      }
    }
    if (found) {
      break;
    }
  }
  return issueTemp;
}

function dragStart() {

}
function log(...params) {

}

function editedTaskPriority(columnIndex, cardIndex, cardId, prio) {
  scene.value.children[columnIndex]['children'][cardIndex].props['priority'] = prio;
  let issueTemp = getIssueFromCardId(cardId);
  emits('updateIssuePriority', issueTemp, prio);
}

function toggleLabelDropdown(columnIndex, cardIndex) {
  scene.value.children[columnIndex]['children'][cardIndex].props['openLabels'] = !scene.value.children[columnIndex]['children'][cardIndex].props['openLabels'];
}


// toggle grouping status  
watch(()=> showEmptyGrouping.value,(newValue)=>{
  if(!props.issuesList.isGroupingExceptStatus){
    scene.value.children = setupColumns();
  }
})

// issues by grouping except status modified
const issuesByGroupingExceptStatus = computed(()=> {
  const oredrLables = props.issuesList.orderingCriteriaLables;
  const modifiedStatusLabel = Array.from({ length: oredrLables.length }, () => 
  [...props.statuses]).flat().slice(0, oredrLables.length);  
  const modfiedHaeders = modifiedStatusLabel.map((v,index)=>{
    return {...v,label:oredrLables[index]?.label,value:oredrLables[index]?.label}
  })
  let finalReturnArray = [];
  for (let sta of modfiedHaeders) {
    let columnChild = {
      id: `column${sta.id}`,
      type: 'container',
      name: sta.label,
      props: {
        orientation: 'vertical',
        className: 'card-container',
        icon: sta.icon
      },
    };
    let issuesArray = [];
        for (let issue of props.issuesList.orderingCriteriaIssueList[sta.label]) {
          let issueDic = {
            type: 'draggable',
            id: issue.id,
            props: {
              className: 'card',
              project: issue.project != null ? issue.project.name : "",
              createdBy: issue.createdBy,
              priority: issue.priority,
              labelsArray: issue.labelsArray,
              openPriority: false,
              openLabels: false
            },
            number: issue.identifier,
            data: issue.title
          };
          issuesArray.push(issueDic);
        }
        columnChild['children'] = issuesArray;
        finalReturnArray.push(columnChild);

    }
    return showEmptyGrouping.value ? finalReturnArray : finalReturnArray?.filter((v)=>{
      return v.children?.length > 0;
    });
 });

 // issues by notgrouping modified
 const issuesByGrouping = computed(()=>{
  return props.issuesList.issues.map((issue)=>{
     return  {
            type: 'draggable',
            id: issue.id,
            props: {
              className: 'card',
              project: issue.project != null ? issue.project.name : "",
              createdBy: issue.createdBy,
              priority: issue.priority,
              labelsArray: issue.labelsArray,
              openPriority: false,
              openLabels: false
            },
            number: issue.identifier,
            data: issue.title
          };
        }) ?? [];
 })

// wacthes
watch([()=> props.issuesList.orderingCriteriaIssueList,()=>props.issuesList.isGroupingExceptStatus],([_,newValue])=>{
  if(newValue){
    scene.value.children = issuesByGroupingExceptStatus.value; 
  }
  else{
    scene.value.children = setupColumns();
  }
},{deep:true})


// get date in formatted
function getFormttedDate(date){
  const date_obj = new Date(date);
  const options = { month: 'short', day: 'numeric' };
  return  date_obj.toLocaleDateString('en-US', options);
}


function onSelectingIssuePreview(issue){
  const issue_id=issue?.id;
  const previewIssue=props.issuesList.issues.find((issue_temp)=>{
    return issue_temp.id===issue_id
  })
  if(!previewIssue) return 
    selectedIssuePreview.value = previewIssue;
  
}

watch(()=> props.issuesList.issues,(newValue)=>{
scene.value.children=setupColumns();
  
},{deep:true})
</script>
<template>
  <div class="row">
     <div class="list_template_body" :class="[selectedIssuePreview ? 'col-8':'col-12']">
      <div v-if="props.issuesList.isNotGrouping" class="d-flex flex-wrap justify-content-center gap-2 align-content-center mx-auto">
         <div v-for="(child, cardIndex) of issuesByGrouping"  @click="onSelectingIssuePreview(child)" class="__block"  :key="child.id">
            <div class="card my-1 px-3 py-3 mx-1 bg-hover" :class="[child?.id===selectedIssuePreview?.id && 'bg_click']" :id="child.id">
              <div class="lineone">
                <span class="issueNumber" v-if="props.displayProperties?.isProjects">
                  <KanbanSaturn />
                  {{ child.number }}
                  <span class="projectTitle"> > {{ child.props?.project }}</span>
                </span>
                <span style="float: right; height: 30px;" v-if="props.displayProperties?.isCreated">
                  <DisplayAvatar :avatar="child.props.createdBy == null ? null : child.props.createdBy['avatar']" />
                </span>
              </div>
              <div class="linetwo">
                <router-link class="issueLink card-text"
                  :to="`/${workspaceSlug.slug}/issue/${child.number.toString().replaceAll(' ', '')}`">
                  <span style="cursor: pointer;">{{ child.data }}</span>
                </router-link>
              </div>
              <div class="linethree">
                <div class="priorityWrapper" v-if="props.displayProperties?.isPriority">
                  <BDropdown variant="link" v-model="child.props['openPriority']" toggle-class="text-decoration-none"
                    no-caret>
                    <template #button-content>
                      <PriorityIcon style="width:16px; height:16px;"
                        v-if="child.props.priority == null || child.props.priority.value == null" />
                      <span v-if="child.props.priority != null && child.props.priority.value != null"
                        v-html="child.props.priority.icon"></span>
                    </template>
                    <PriorityDropdown v-if="child.props['openPriority']"
                      @selected:item="editedTaskPriority(columnIndex, cardIndex, child.id, $event)"
                      @update:showPopup="child.props['openPriority'] = $event" />
                  </BDropdown>
                </div>
                <div class="subtasks">
                  <span>
                    <KanbanProgress /> <span>2/6</span>
                  </span>
                </div>
                <div class="calendaricon">
                  <KanbanCalendar />
                </div>
                <div class="allLabels" v-if="props.displayProperties?.isLabels">
                  <div class="labelWrapper" v-if="child.props['labelsArray']?.length <= 2" style="float: right;">
                    <div v-for="(label, indexlabel) in child.props['labelsArray']" :key="indexlabel" :style="{
                          background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                          'border-radius': '20px',
                          padding: '5px 10px',
                          'font-size': '10px',
                          'margin-right': '5px',
                          'display': 'inline-block',
                          'margin-bottom': '5px'
                        }">
                          <span :style="{
                          background: label.color,
                          width: '8px',
                          height: '8px',
                          'border-radius': '10px',
                          'display': 'inline-block'
                        }"></span>
                      {{ label.label }}
                    </div>
                  </div>
                  <div style="float: right;" class="labelWrapper" @click="toggleLabelDropdown(columnIndex, cardIndex)"
                    v-if="child.props['labelsArray']?.length > 2">
                    <div :style="{
                      background: `rgba(${child.props['labelsArray']?.[0]?.['colorArray']?.[0]},${child.props['labelsArray']?.[0]?.['colorArray']?.[1]},${child.props['labelsArray']?.[0]?.['colorArray']?.[2]}, 0.3)`,
                      'border-radius': '20px',
                      padding: '5px 10px',
                      'font-size': '10px',
                      'margin-right': '5px',
                      'display': 'inline-block',
                      'margin-bottom': '5px'
                    }">
                      <span :style="{
                        background: child.props['labelsArray']?.[0]?.['color'],
                        width: '8px',
                        height: '8px',
                        'border-radius': '10px',
                        'display': 'inline-block'
                      }"></span>
                      {{ child.props['labelsArray']?.length + ' labels' }}
                    </div>
                    <div class="dropdown-menu position-bottom"
                      style="min-width:120px !important; width:120px !important;"
                      :class="{ 'show': child.props['openLabels'] }">
                      <ul class="mb-0">
                        <li v-for="(itemLabel, index) in child.props['labelsArray']" :key="index">
                          <div class="row">
                            <div :style="{
                              background: `rgba(${itemLabel.colorArray[0]},${itemLabel.colorArray[1]},${itemLabel.colorArray[2]}, 0.3)`,
                              'border-radius': '20px',
                              padding: '5px 10px',
                              'font-size': '10px',
                              'margin-right': '5px',
                              'display': 'inline-block',
                              'margin-bottom': '5px'
                            }">
                              <span :style="{
                                background: itemLabel.color,
                                width: '8px',
                                height: '8px',
                                'border-radius': '10px',
                                'display': 'inline-block'
                              }"></span>
                              {{ itemLabel.label }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="linefour">
                <div class="site">
                  <KanbanSite /> <span> Website Builder Website Builder Website Builder </span>
                </div>
                <div class="flag">
                  <KanbanFlag /> <span> Q1 </span>
                </div>
                <div class="light">
                  <KanbanLight /> <span> 2 </span>
                </div>
                <div class="block">
                  <KanbanBlock />
                </div>
              </div>
            </div>
          </div>
      </div>
      <Container v-else orientation="horizontal" @drop="onColumnDrop($event)" drag-handle-selector=".column-drag-handle"
        @drag-start="dragStart">
        <Draggable v-for="(column, columnIndex) in scene.children" :key="column.id">
          <div class="card-container">
            <div class="card-column-header">
              <span class="column-drag-handle" v-html="column.props.icon"></span>
              <span class="statusLabel">{{ column.name }}</span>
              <span class="issueCounter">({{ column.children != undefined ? column.children.length : '0'
                }})</span>
              <span style="float: right;" >
                <AddCircleIconVue @click="showNewIssueWithStatus(column.id)" style="cursor: pointer;" />
              </span>
            </div>
            <Container group-name="col" @drop="(e) => onCardDrop(column.id, e)" drag-class="card-ghost"
              :get-child-payload="getCardPayload(column.id)" drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions">
              <Draggable v-for="(card, cardIndex) of column.children" :key="card.id">
                <div class="card bg-hover" :class="[card?.id===selectedIssuePreview?.id && 'bg_click']" @click="onSelectingIssuePreview(card)" :id="card.id">
                  <div class="lineone">
                    <span class="issueNumber" v-if="props.displayProperties?.isProjects">
                      <KanbanSaturn />
                      {{ card.number }}
                      <span class="projectTitle"> > {{ card.props.project }}</span>
                    </span>
                    <span style="float: right; height: 30px;" v-if="props.displayProperties?.isCreated">
                      <DisplayAvatar :avatar="card.props.createdBy == null ? null : card.props.createdBy['avatar']" />
                    </span>
                  </div>
                  <div class="linetwo">
                    <router-link class="issueLink card-text"
                      :to="`/${workspaceSlug.slug}/issue/${card.number.toString().replaceAll(' ', '')}`">
                      <span style="cursor: pointer;">{{ card.data }}</span>
                    </router-link>
                  </div>
                  <div class="linethree">
                    <div class="priorityWrapper" v-if="props.displayProperties?.isPriority">
                      <BDropdown variant="link" v-model="card.props['openPriority']" toggle-class="text-decoration-none"
                        no-caret>
                        <template #button-content>
                          <PriorityIcon style="width:16px; height:16px;"
                            v-if="card.props.priority == null || card.props.priority.value == null" />
                          <span v-if="card.props.priority != null && card.props.priority.value != null"
                            v-html="card.props.priority.icon"></span>
                        </template>
                        <PriorityDropdown v-if="card.props['openPriority']"
                          @selected:item="editedTaskPriority(columnIndex, cardIndex, card.id, $event)"
                          @update:showPopup="card.props['openPriority'] = $event" />
                      </BDropdown>
                    </div>
                    <div class="subtasks">
                      <span>
                        <KanbanProgress /> <span>2/6</span>
                      </span>
                    </div>
                    <div class="calendaricon">
                      <KanbanCalendar />
                    </div>
                    <div class="allLabels" v-if="props.displayProperties?.isLabels">
                      <div class="labelWrapper" v-if="card.props['labelsArray']?.length <= 2" style="float: right;">
                        <div v-for="(label, indexlabel) in card.props['labelsArray']" :key="indexlabel" :style="{
                              background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                              'border-radius': '20px',
                              padding: '5px 10px',
                              'font-size': '10px',
                              'margin-right': '5px',
                              'display': 'inline-block',
                              'margin-bottom': '5px'
                            }">
                                                <span :style="{
                              background: label.color,
                              width: '8px',
                              height: '8px',
                              'border-radius': '10px',
                              'display': 'inline-block'
                            }"></span>
                          {{ label.label }}
                        </div>
                      </div>
                      <div style="float: right;" class="labelWrapper" @click="toggleLabelDropdown(columnIndex, cardIndex)"
                        v-if="card.props['labelsArray']?.length > 2">
                        <div :style="{
                              background: `rgba(${card.props['labelsArray'][0]['colorArray'][0]},${card.props['labelsArray'][0]['colorArray'][1]},${card.props['labelsArray'][0]['colorArray'][2]}, 0.3)`,
                              'border-radius': '20px',
                              padding: '5px 10px',
                              'font-size': '10px',
                              'margin-right': '5px',
                              'display': 'inline-block',
                              'margin-bottom': '5px'
                            }">
                                                <span :style="{
                              background: card.props['labelsArray'][0]['color'],
                              width: '8px',
                              height: '8px',
                              'border-radius': '10px',
                              'display': 'inline-block'
                            }"></span>
                          {{ card.props['labelsArray']?.length + ' labels' }}
                        </div>
                        <div class="dropdown-menu position-bottom"
                          style="min-width:120px !important; width:120px !important;"
                          :class="{ 'show': card.props['openLabels'] }">
                          <ul class="mb-0">
                            <li v-for="(itemLabel, index) in card.props['labelsArray']" :key="index">
                              <div class="row">
                                <div :style="{
                                    background: `rgba(${itemLabel.colorArray[0]},${itemLabel.colorArray[1]},${itemLabel.colorArray[2]}, 0.3)`,
                                    'border-radius': '20px',
                                    padding: '5px 10px',
                                    'font-size': '10px',
                                    'margin-right': '5px',
                                    'display': 'inline-block',
                                    'margin-bottom': '5px'
                                  }">
                                                              <span :style="{
                                    background: itemLabel.color,
                                    width: '8px',
                                    height: '8px',
                                    'border-radius': '10px',
                                    'display': 'inline-block'
                                  }"></span>
                                  {{ itemLabel.label }}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="linefour">
                    <div class="site">
                      <KanbanSite /> <span> Website Builder Website Builder Website Builder </span>
                    </div>
                    <div class="flag">
                      <KanbanFlag /> <span> Q1 </span>
                    </div>
                    <div class="light">
                      <KanbanLight /> <span> 2 </span>
                    </div>
                    <div class="block">
                      <KanbanBlock />
                    </div>
                  </div>
                </div>
              </Draggable>
            </Container>
          </div>
        </Draggable>
      </Container>
     </div>
     <div v-if="selectedIssuePreview" class="col-4 issue_preview list_template_body">
          <IssuePreview :editedTaskAssignee="editedTaskAssignee" :toggleLabelDropdown="toggleLabelDropdown" :getFormttedDate="getFormttedDate" />
      </div>
  </div>
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>
</template>

<style>
.kanban_container {
  overflow: scroll;
  height: 90vh;
}

.kanban_container .dndrop-container.vertical {
  height: 100vh;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card {
  width: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  height: 146px;
  background: #FFFFFF;
  padding: 10px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper {
  margin-top: 10px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  border-radius: 8px;
  overflow: inherit;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.issueNumber {
  color: #3D4046;
  font-size: 12px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.projectTitle {
  color: #7B8497;
  padding-left: 5px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card p {
  font-size: 16px;
  font-weight: 500;
  color: #3D4046;

}

.subtasks {
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  width: 51px;
  height: 24px;
  float: left;
  text-align: center;
  margin-left: 5px;
}

.subtasks span {
  color: #7B8497;
  font-size: 12px !important;

}

.calendaricon {
  margin: 0 10px;
  float: left;
}

.linefour {
  margin-top: 10px;
}

.linefour .site {
  float: left;
  border: 1px solid #EAEAEA;
  width: 90px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .flag {
  float: left;
  border: 1px solid #EAEAEA;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin: 0 5px;
}

.linefour .light {
  float: left;
  border: 1px solid #EAEAEA;
  width: 39px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .block {
  float: left;
  border: 1px solid #EAEAEA;
  width: 28px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin-left: 5px;
}

.linefour span {
  color: #7B8497;
  font-size: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kanban_container .card-container {
  width: 260px;
  margin-right: 15px;
}

.kanban_container .card-column-header {
  width: 100%;
  background: #EAEAEA;
  border-radius: 8px;
  padding: 0 10px;
  line-height: 40px;
}

.kanban_container .card-column-header .statusLabel {
  padding-left: 8px;
  color: #2A2C32;
  font-size: 14px;
  font-weight: 500 !important;
}

.kanban_container .card-column-header .issueCounter {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #7B8497;

}

.__block{
  margin-top: 10px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  border-radius: 8px;
  overflow: inherit;
  box-sizing: border-box;
  display: block;
}
</style>
<style scoped>
.issue_preview {
  border: 1px solid rgb(197, 193, 193);
  border-radius: 20px;
}
.list_template_body{
  overflow: scroll;
  height: 78dvh;
}
.bg-hover:hover {
  background-color: #eaeef1 !important; /* Light gray on hover */
}
.bg-hover {
  transition: background-color 0.5s ease; /* Adjust the duration and timing function as needed */
}

.bg_click{
   background-color: #eaeef1 !important; /* Light gray on hover */ 
}
</style>