<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import api from "../../../api";
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField.vue";
import SuccessIcon from "../../ToastIcons/SuccessIcon.vue";
import Danger from "@/components/TeamIcons/Danger.vue";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
const router = useRouter();
const toast = useToast();

const selectedMember = ref(null);
const teams_id = ref(null);
const currentTeam = ref(null);
const data = ref(null);
const searchTerm = ref('');
const memberRoles = ref([
  { 'value': 'administrator', 'label': 'Administrator' },
  { 'value': 'collaborator', 'label': 'Collaborator' },
  { 'value': 'guest', 'label': 'Guest' }
]);
const newmember = {
  role: '',
  image: '',
  userid: ''
}
const userlist = ref([])
onMounted(() => {
  const user_id = localStorage.getItem("workspace");
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  fetchData();
  fetchUsers();
});

function onRoleSelection(value, memberId) {
  api.put(`teams/${teams_id.value}/members/${memberId}/role`, { role: value })
    .then((response) => {
      if (response.status == 200) {
        toast("Member role updated successfully", { position: "top-right", timeout: 4025, icon: SuccessIcon });
        fetchData();
      } else {
        toast("Something went wrong", { position: "top-right", timeout: 4025, icon: Danger });
      }
    });
}
function fetchUsers() {
  api.get(`user/getAllUser`).then((response) => {
    console.log("users", response.data)
    userlist.value = response.data
  });
}
function fetchData() {
  api.get(`teams/${teams_id.value}`).then((response) => {
    currentTeam.value = response.data;
  });
}

function onDetailsClicked(item) {
  selectedMember.value = item;
}

function onRevokeClicked(item) {
  api.post(`teams/members/remove`, { team_id: teams_id.value, user_id: item.id })
    .then((response) => {
      if (response.status == 200) {
        fetchData();
        toast("Invitation revoked", { position: "top-right", timeout: 4025, icon: SuccessIcon });
      } else {
        toast("Something went wrong", { position: "top-right", timeout: 4025, icon: Danger });
      }
    });
}

function onResendClicked(item) {
  api.post(`teams/members/invites`, [{ team_ids: [teams_id.value], user_id: item.id }])
    .then((response) => {
      if (response.status == 200) {
        fetchData();
        toast("Invite resent", { position: "top-right", timeout: 4025, icon: SuccessIcon });
      } else {
        toast("Something went wrong", { position: "top-right", timeout: 4025, icon: Danger });
      }
    });
}

function onRemoveMember(e) {
  api.post(`teams/members/remove`, { team_id: e.id, user_id: data.value })
    .then((response) => {
      if (response.status === 200) {
        fetchData();
        toast("Your changes are saved successfully", { position: "top-right", timeout: 4025, icon: SuccessIcon });
      }
    })
    .catch((error) => {
      console.error(error, "Error");
    });
}

const filteredMembers = computed(() => {
  if (!currentTeam.value || !currentTeam.value.members) return [];
  return currentTeam.value.members.filter(member => {
    const fullName = `${member.first_name || ''} ${member.last_name || ''}`.toLowerCase();
    const userName = (member.name || '').toLowerCase();
    const search = searchTerm.value.toLowerCase();
    return fullName.includes(search) || userName.includes(search);
  });
});
function addmember() {
  console.log("************", newmember.image)
  var form = new FormData();
  form.append("userId", parseInt(newmember.userid));
  form.append("role", newmember.role);
  form.append("image", newmember.image);
  api.post(`teams/member/${teams_id.value}/create`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'  // optional, Axios should detect this automatically
    }
  })
    .then((response) => {
      if (response.status === 200) {
        console.log("addmember@@@@@@", response.data)
        alert(response.data.message)
      }
    })
    .catch((error) => {
      console.error(error, "Error");
    });
}
function close_addmember() {
  const modalElement = document.getElementById('AddMemberModal');
  // eslint-disable-next-line no-undef
  const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance.hide();
  newmember.value = { image: '', userid: '', role: '' }
}
function onFileChange(event) {
  // Save the file object to `newmember.image`
  newmember.image = event.target.files[0];
}
</script>

<template>
  <AppHeader heading="Teams" breadCrumbs="Issues Task Team Members"></AppHeader>
  <div class="px-3">
    <div class="py-4">
      <div class="d-flex justify-content-between">
        <div class="me-4 w-auto">
          <InputField placeholder="Search" type="text" v-model="searchTerm" :svgIcon="true"></InputField>
        </div>
        <div>
          <button class="custom-primary-btn me-2" type="button" data-bs-toggle="modal" data-bs-target="#AddMemberModal">
            <strong>Add Member</strong>
          </button>
          <router-link to="/invite-members" class="text-decoration-none">
          <button class="custom-primary-btn" type="button">
            <strong>Invite Member</strong>
          </button>
        </router-link>
        </div>

      </div>
    </div>

    <div class="tab-wrapper p-3">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-view">
            <tr class="head-row-view text-center align-middle">
              <th scope="col">Team</th>
              <th scope="col">Name</th>
              <th scope="col">User ID</th>
              <th scope="col">Role</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody class="tab-body">
            <tr class="row-view" v-for="(member, index) in filteredMembers" :key="index">
              <td>{{ currentTeam?.name }}</td>
              <td>{{ member.first_name == null ? '' : member.first_name + " " + member.last_name == null ? '' :
                member.last_name }}</td>
              <td>{{ member.name }}</td>
              <td style="min-width: 130px;">
                <select v-model="member.team_role.role" @change="onRoleSelection($event.target.value, member.id)"
                  class="form-select">
                  <option v-for="item in memberRoles" :key="item.value" :value="item.value">{{ item.label }}</option>
                </select>
              </td>
              <td>
                <span v-if="!member.status || member.status == 'invited'"
                  class="badge rounded-pill text-warning">Pending Invite</span>
                <span v-if="member.status == 'declined'" class="badge rounded-pill text-danger">Declined</span>
                <span v-if="member.status == 'resend_invite'" class="badge rounded-pill text-warning">Resend
                  Invite</span>
                <span v-if="member.status == 'accepted'" class="badge rounded-pill text-success">Accepted</span>
              </td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px"
                        v-if="member.status == 'accepted' || member.status == 'invited' || !member.status"
                        data-bs-toggle="modal" data-bs-target="#teamMemberDetailsModal"
                        @click="onDetailsClicked(member)">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" v-if="member.status == 'invited' || !member.status"
                        @click="onResendClicked(member)">Resend Invite</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" v-if="member.status == 'invited' || !member.status"
                        @click="onRevokeClicked(member)">Revoke Invite</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" v-if="member.status != 'invited' && member.status"
                        @click="onRemoveMember(member)">Remove from team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!--Add Member -->
  <div class="modal fade" id="AddMemberModal" tabindex="-1" aria-labelledby="teamMemberDetailsModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="teamMemberDetailsModalLabel">Add Member</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <select v-model="newmember.role" class="form-select mb-4">
            <option disabled value="">Select a role</option>
            <option v-for="item in memberRoles" :key="item.value" :value="item.value">{{ item.label }}</option>
          </select>
          <select v-model="newmember.userid" class="form-select mb-3">
            <option disabled value="">Select a user</option>
            <option v-for="(user, index) in userlist" :key="index" :value="user.id">{{ user.name }}</option>
          </select>
          <!-- <input type="file" @change="onFileChange" class="mt-4 mb-2" /> -->
          <div class="d-flex justify-content-end">
            <button class="custom-secondary-btn me-2" type="button" data-bs-dismiss="modal" @click="close_addmember()">
              <strong>Cancel</strong>
            </button>
            <button class="custom-primary-btn" data-bs-dismiss="modal" type="button" @click="addmember()">
              <strong>Add</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TeamMember Details Modal -->
  <div class="modal fade" id="teamMemberDetailsModal" tabindex="-1" aria-labelledby="teamMemberDetailsModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="teamMemberDetailsModalLabel">Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            Invited By: {{ selectedMember?.inviter.first_name + " " + selectedMember?.inviter.last_name }}
            {{ "Invitee image not available in API" }}
          </p>
          <p>Member since: {{ "Not Available in API" }}</p>
          <p>Last Logged: {{ selectedMember?.last_login }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>

