<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import CreateNew from "@/components/Pages/Teams/FieldPopups/CreateNew.vue";
import FieldTypeOptions from "@/components/Pages/Teams/FieldPopups/FieldTypeOptions.vue";
import CreateField from "@/components/Pages/Teams/FieldPopups/CreateField.vue";
import { onMounted, ref } from "vue";
import api from "../../../api";
var router = useRouter();
const selectedMember = ref(null);
let teams_id = ref(null);
let fields = ref(null);
let data = ref(null);
const toast = useToast();
const addFieldPopup = ref(false);
const showFieldOptions = ref(false);
const fieldType = ref(null);
const showCreateField = ref(false);
onMounted(() => {
  const user_id = localStorage.getItem("workspace")
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  fetchData();
});

function fetchData() {
  api.get(`teams/${teams_id.value}/fields`).then((response) => {
    fields.value = response.data;
  });
}

function showFieldOptionsPopup(type) {
  fieldType.value = type;
  showCreateField.value = true;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


</script>
<template>
  <div class="px-3">
    <div class="py-4">
      <div class="d-flex justify-content-between">
        <div class="col-6 col-md-4">
          <InputField placeholder="Search" type="text" :svgIcon="true"></InputField>
        </div>
        <button @click="addFieldPopup = true" class="custom-primary-btn" type="button">
          <strong>Add New Field</strong>
        </button>
      </div>
    </div>

    <div class="tab-wrapper p-3">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-view">
            <tr class="head-row-view text-center align-middle">
              <th scope="col">Field Name</th>
              <th scope="col" class="d-none d-md-table-cell">Type</th>
              <th scope="col">Used on Issue Type</th>
              <th scope="col" class="d-none d-md-table-cell">Location</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody class="tab-body">
            <tr class="row-view" v-for="(item, index) of fields" :key="index">
              <td>{{ item.name }}</td>
              <td class="d-none d-md-table-cell">{{ capitalizeFirstLetter(item.type) }}</td>
              <td>{{ "Campbell" }}</td>
              <td class="d-none d-md-table-cell">{{ "N/A" }}</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal"
                        data-bs-target="#teamMemberDetailsModal">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px">Remove from
                        team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div v-if="addFieldPopup" class="popup-overlay custom-height-fields">
    <div class="popup" style="width: 80% !important; max-width: 570px;">
      <CreateNew @close:popup="addFieldPopup = $event" @setting:selected="showFieldOptions = $event" />
    </div>
  </div>
  <div v-if="showFieldOptions" class="popup-overlay custom-height-fields">
    <div class="popup" style="width: 80% !important; max-width: 570px;">
      <FieldTypeOptions @close:popup="showFieldOptions = $event" @setting:selected="showFieldOptionsPopup($event)" />
    </div>
  </div>

  <div v-if="showCreateField" class="popup-overlay custom-height-fields">
    <div class="popup" style="width: 80% !important; max-width: 570px;">
      <CreateField :fieldType="fieldType" @close:popup="showCreateField = $event"
        @setting:selected="fieldType = $event" />
    </div>
  </div>
</template>
<style lang="scss">
.custom-height-fields .input-field {
  height: 46px;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>
