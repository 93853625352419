import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import AttachmentNodeComponent from './AttachmentNodeComponent.vue';
export default Node.create({
    name: 'attachmentView',

    group: 'block',

    atom: true,

    addAttributes() {
        return {
            source: {
                default: '',
            },
            filePath: {
                default: '',
            },
            type: {
                default: '',
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'attachment-view',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['attachment-view', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(AttachmentNodeComponent)

    },
})