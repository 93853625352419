<template>
  <div class="preview">
    <div>
      <span style="padding-right: 10px">
        <LayerIcon />
      </span>
      <span style="padding-right: 10px">
        {{ props.searchIndivisualPreview?.project?.slug }}-{{
          props.searchIndivisualPreview?.issue_number
        }}
      </span>
      <span style="padding-right: 10px; font-weight: 500">
        <a
          target="_blank"
          style="color: #2a2c32; text-decoration: none"
          :href="
            baseUrl +
            '/' +
            workspace?.slug +
            '/issue/' +
            props.searchIndivisualPreview?.project?.slug +
            '-' +
            props.searchIndivisualPreview?.issue_number
          "
        >
          {{ props.searchIndivisualPreview?.title }}
        </a> </span
      ><br />
    </div>
    <div class="my-2">
      <span>{{ props.searchIndivisualPreview?.assigneeUser?.name }}</span>
    </div>
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error enim
        excepturi voluptas magnam minima cupiditate deleniti dolorum
        exercitationem autem consequatur?
      </p>
    </div>
  </div>
</template>

<script setup>
import LayerIcon from "@/components/Icons/LayerIcon.vue";
const props = defineProps(["searchIndivisualPreview"]);
</script>

<style scoped>
.preview {
  border: 1px solid gainsboro;
  box-shadow: 0px 0px 8px 0px #0000001a;
  background: white;
  border-radius: 10px;
  width: 350px;
  padding: 1rem !important;
}
</style>
