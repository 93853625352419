<script setup>
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import DisplayAvatar from '@/components/Pages/Profile/DisplayAvatar.vue';
const props = defineProps(['issueId', 'teamMembers', 'issueSubscribers', 'isOpen'])
const route = useRoute()
const show1 = ref(false);
const emits = defineEmits(['selected:member']);
onMounted(() => {
})

console.log(props.issueSubscribers);

function selectMember(memId) {
  emits('selected:member', memId);
}
</script>

<template>
  <ul class="mb-0" @click.stop>
    <li v-for="mem in props.teamMembers" :key="mem.user_id">
      <div class="wrapper">
        <div class="p-0" style="line-height: 35px; float: left; padding-right: 0.3rem !important;">
          <CheckBoxInput :id="'label-' + mem.user_id" :modelValue="props.issueSubscribers.includes(mem.user_id)"
            @update:modelValue="selectMember(mem.user_id)" />
        </div>
        <div class="p-0" style="line-height:25px;padding-right:0.3rem !important; float: left;">
          <DisplayAvatar :avatar="mem.avatar" />
        </div>
        <div class="p-0" style="line-height:38px; font-size:14px; color:#3D4046; font-weight: bold; float: left;">
          {{ (mem == null) ? '' : ((mem['first_name'] == '' || mem['first_name'] == null) &&
      (mem['last_name'] == '' || mem['last_name'] == null)) ?
      mem['name'] : mem['first_name'] + ' ' + mem['last_name'] }}
        </div>
      </div>
    </li>
  </ul>
</template>

<style scoped>
.dropdown-menu .wrapper {
  width: 100%;
  float: left;
}

.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 268px;
  min-height: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .dropdown-menu.position-bottom {
  top: auto !important;
  bottom: 110% !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>