// useState.js
import { ref } from "vue";

const state = ref(new Map());

function useState(id, data = null) {
  const hasExistState = state.value.has(id);
  if (hasExistState && data === null) {
    return state.value.get(id);
  }
  state.value.set(id, ref(data));
  return state.value.get(id);
}

export default useState;
