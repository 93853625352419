<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 0C7.16379 0 0 7.16379 0 16C0 24.8362 7.16379 32 16 32C24.8362 32 32 24.8362 32 16C32 7.16379 24.8362 0 16 0Z"
      fill="#E9203F"
    />
    <path
      d="M16 25.1425C17.2625 25.1425 18.2859 24.1191 18.2859 22.8566C18.2859 21.5942 17.2625 20.5708 16 20.5708C14.7376 20.5708 13.7142 21.5942 13.7142 22.8566C13.7142 24.1191 14.7376 25.1425 16 25.1425Z"
      fill="white"
    />
    <path
      d="M17.8287 17.257C17.7698 17.8446 17.2724 18.291 16.6813 18.2857H15.3098C14.7223 18.2865 14.2303 17.8419 14.1713 17.257L13.257 8.11366C13.1963 7.48327 13.6588 6.92252 14.2892 6.8618C14.3276 6.85823 14.366 6.85645 14.4044 6.85645H17.6046C18.2385 6.85823 18.7502 7.37433 18.7484 8.0074C18.7484 8.04312 18.7466 8.07794 18.743 8.11277L17.8287 17.257Z"
      fill="white"
    />
  </svg>
</template>
