<script setup>
import CupIcon from '@/components/Icons/CupIcon.vue';
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import CalendarIcon from '@/components/Icons/CalendarIcon.vue';
import ClockIcon from '@/components/Icons/ClockIcon.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import CreatorIcon from '@/components/Icons/CreatorIcon.vue';
import AssigneeIcon from '@/components/Icons/AssigneeIcon.vue';
import EstimateIcon from '@/components/Icons/EstimateIcon.vue';
import LabelTriangleIcon from '@/components/Icons/LabelTriangleIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import LabelIcon from '@/components/Icons/LabelIcon.vue';
import Danger from '../../ToastIcons/ErrorIcon.vue';
import Success from '../../ToastIcons/SuccessIcon.vue';
import DeleteIcon from '@/components/Icons/DeleteIcon.vue';
import InputField from '@/components/Fields/InputField.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
// import DatePicker from "vue-datepicker";
// import "vue-datepicker";
// import 'bootstrap-datepicker';
//import DatePicker from '@/components/Pages/Project/DatePicker.vue';
// const props = defineProps(['showPopup']);
const props = defineProps(['issueId'])
const route = useRoute()
const emits = defineEmits(['update:showPopup']);
const toast = useToast();
const status = ref('');
const name = ref('');
const filePath = ref('');
const file = ref(null);
onMounted(() => {

})

function onFileChanged($event) {
  const target = $event.target;
  if (target && target.files) {
    file.value = target.files[0];
  }
}


function formatDate(date) {
  if (date == "" || date == null) {
    return '';
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`
  }
  return `${year}-${month}-${day}`;
}

function addAttachment() {
  if (name.value == '' || file.value == null) {
    toast("All fields are required", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
  }
  var formData = new FormData();
  formData.append("file", file.value);

  api
    .post(`utils/uploader`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response.status == 200) {
        api
          .post(`issues/${props.issueId}/attachments`, {
            size: file.value['size'],
            name: name.value,
            path: response.data['path']
          })
          .then((response) => {
            if (response.status == 201) {
              toast("Attachment added", {
                position: "top-right",
                timeout: 5025,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: Success,
                rtl: false,
              });
              closePopup();
            }
          });
      }
    });
}


function closePopup() {
  emits('update:showPopup', false);
}
</script>

<template>
  <div class="popup-overlay">
    <div class="popup">
      <div class="d-flex align-items-center pt-3 mb-4">
        <h2>
          Add New Attachment
        </h2>
        <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3" style="padding-left:0;">
          <label>File Name</label>
          <input type="text" class="input-field" v-model="name" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-3 p-0">
          <label>File Upload</label>
        </div>
        <div class="col-md-12">
          <input type="file" @change="onFileChanged($event)" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <div class="d-flex mt-3" style="float:right;">
            <button type="button" class="custom-secondary-btn me-4 " @click="closePopup">
              Cancel
            </button>
            <button class="custom-primary-btn" type="button" @click="addAttachment">
              Save
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  min-width: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>