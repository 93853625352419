<script setup>
import { ColorPicker } from 'vue-accessible-color-picker';
import { ref, inject } from 'vue'
const emits = defineEmits(['update:showPopup', 'update:colorSelected']);
let selectedColor = ref('');
function updateColor(eventData) {
  selectedColor = eventData.cssColor;
}
function closePopup() {
  emits('update:showPopup', false);
}
function selectColorAndClose() {
  emits('update:colorSelected', selectedColor);
}
</script>
<template>
  <div>
    <ColorPicker :color="'#F96332'" :visible-formats="['hex']" @color-change="updateColor" />
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-center mt-3">
        <button type="button" class="custom-secondary-btn me-4 " @click="closePopup">
          Close
        </button>
        <button class="custom-primary-btn" type="button" @click="selectColorAndClose">
          Select
        </button>

      </div>
    </div>
  </div>
</template>