<template>
    <label :for="inputName"> 
         {{ LabelText }}
    </label>
    <input :type="inputType" class="customInput w-100" :placeholder="inputPlaceHolder" :name="inputName" v-model="inputValue" @input="emitInputValue" />
</template>

<script setup>

import { defineProps, ref, defineEmits } from 'vue';

const props = defineProps({
    inputType: String,
    inputPlaceHolder: String,
    inputName: String,
    LabelText: String,
});

const inputValue = ref('');

const emitInputValue = (event) => {
    inputValue.value = event.target.value;
    emit('update:modelValue', inputValue.value);
};

const emit = defineEmits(['update:modelValue']);

</script>

<style scoped>
.customInput {
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #C4C4CA;
}

*{
    outline : none !important;
}

label{
    color:#2A2C32;
    font-size: 14px;
    line-height: 16.41px;
    font-weight: 500;
}

::placeholder{
    color:#7B8497 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

</style>
