<script setup>
import Danger from '../../ToastIcons/ErrorIcon.vue';
import Success from '../../ToastIcons/SuccessIcon.vue';
import InputField from '@/components/Fields/InputField.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { onMounted } from 'vue';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import api from '@/api';
import { useToast } from "vue-toastification";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import debounce from 'lodash/debounce';
// import DatePicker from "vue-datepicker";
// import "vue-datepicker";
// import 'bootstrap-datepicker';
//import DatePicker from '@/components/Pages/Project/DatePicker.vue';
// const props = defineProps(['showPopup']);
const props = defineProps(['issueId', 'editTime', 'billedTime', 'nonBilledTime']);
const router = useRoute()
const emits = defineEmits(['update:showPopup', 'created:time']);
const toast = useToast();
const billable = ref(true);
const startDate = ref(null);
const endDate = ref(null);
const description = ref('');
const timeSpent = ref('');
const timeRemaining = ref('');
onMounted(() => {
  if (props.editTime != null) {
    billable.value = props.editTime['billable'];
    timeSpent.value = props.editTime['spent_time'];
    timeRemaining.value = props.editTime['remaining_time'];
    description.value = props.editTime['description'];
    startDate.value = props.editTime['start_date'];
    endDate.value = props.editTime['end_date'];
  }
})

function toggleBillable() {
  billable.value = !billable.value;
}

function formatDate(date) {
  if (date == "" || date == null) {
    return null;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`
  }
  return `${month}-${day}-${year}`;
}

function logTime() {
  if (timeSpent.value == '' && timeRemaining.value == "" && startDate.value == null && endDate.value == null) {
    toast("One of the 4 time fields are mandatory to enter", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  if (props.editTime == null) {
    createTimeLog();
  }
  else {
    editTimeLog();
  }
}

function createTimeLog() {
  api
    .post(`issues/${props.issueId}/time-trackings`, {
      spent_time: timeSpent.value,
      remaining_time: timeRemaining.value,
      billable: billable.value,
      start_date: formatDate(startDate.value),
      end_date: formatDate(endDate.value),
      description: description.value
    })
    .then((response) => {
      if (response.status == 201) {
        toast("Time logged successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
        closePopup();
      }
    });

}

function editTimeLog() {
  api
    .put(`issues/${props.issueId}/time-trackings/${props.editTime['id']}`, {
      spent_time: timeSpent.value,
      remaining_time: timeRemaining.value,
      billable: billable.value,
      start_date: formatDate(startDate.value),
      end_date: formatDate(endDate.value),
      description: description.value
    })
    .then((response) => {
      if (response.status == 200) {
        toast("Time logged successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });

        closePopup();
      }
    });
}
const istypingInfields = ref(false);
function startTyping() {
  if (timeSpent.value == "" && timeRemaining.value == "") {
    istypingInfields.value = false;
  }
  else {
    istypingInfields.value = true;
  }
}

function closePopup() {
  emits('update:showPopup', false);
  emits('created:time', true);
}

const dateSelected = ref(false);
function dateCleared(whichDate) {
  if (startDate.value == null && endDate.value == null) {
    dateSelected.value = false;
  }
  else {
    dateSelected.value = true;
  }
}
function startDateOpened() {
  endDate.value = new Date();
}
</script>

<template>
  <div class="popup-overlay">
    <div class="popup">
      <div class="d-flex align-items-center pt-3 mb-4">
        <h2>
          Time Tracking
        </h2>
        <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
      </div>
      <span v-if="billedTime != ''">
        <span style="width:100%; height:8px; border-radius:16px; display:block; background-color:#F96332;"></span>
        <span style="display:block; margin:5px 0 10px 0;">{{ billedTime }} logged</span>
      </span>
      <span v-if="nonBilledTime != ''">
        <span style="width:100%; height:8px; border-radius:16px; display:block; background-color:#0844A4;"></span>
        <span style="display:block; margin:5px 0 10px 0;">{{ nonBilledTime }} logged</span>
      </span>
      <div class="row" v-if="!dateSelected">
        <div class="col-md-6 col-6 mb-3 px-sm-3">
          <label for="">Time Spent</label>
          <input type="text" :onKeyup="startTyping()" class="input-field" v-model="timeSpent" />
        </div>
        <div class="col-md-6 col-6 mb-3 px-sm-3">
          <label for="">Time Remaining</label>
          <input type="text" :onKeyup="startTyping()" class="input-field" v-model="timeRemaining" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <span class="gray-color">Use the format: 2w 4d 6h 45m</span>
          <ul style="list-style: outside; color:#7B8497;">
            <li><span class="gray-color">w = weeks</span></li>
            <li><span class="gray-color">d = days</span></li>
            <li><span class="gray-color">h = hours</span></li>
            <li><span class="gray-color">m = minutes</span></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-1 col-1">
          <CheckBoxInput id="billable" :modelValue="billable" @update:modelValue="toggleBillable" />
        </div>
        <div class="col-md-11 col">
          <label>Billable</label>
        </div>
      </div>
      <div class="row" v-if="!istypingInfields">
        <div class="col-md-6 col-6 p-0 px-sm-3 mt-3 start-date-mobile">
          <label>Start Date</label>
          <VueDatePicker v-model="startDate" textInput @cleared="dateCleared('startDate')"
            @closed="dateCleared('startDate')" @open="startDateOpened()" />
        </div>
        <div class="col-md-6 col-6 px-sm-3 mt-3 end-date-mobile">
          <label>End Date</label>
          <VueDatePicker v-model="endDate" textInput @cleared="dateCleared('endDate')"
            @closed="dateCleared('endDate')" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-3 p-0 px-sm-3">
          <label>Work Description</label>
        </div>
        <div class="col-md-12 mt-3 px-sm-3">
          <textarea v-model="description" class="input-field" style="resize:none; width:100%; height:150px;"
            placeholder="Enter"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 px-sm-3 p-0">
          <div class="d-flex mt-3 justify-content-center justify-content-md-end">
            <button type="button" class="custom-secondary-btn me-4 " @click="closePopup">
              Cancel
            </button>
            <button class="custom-primary-btn" type="button" @click="logTime">
              Save
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style scoped>
.gray-color {
  color: #7B8497;
  font-size: 14px;
}

.mr-1 {
  margin-right: 1rem;
}

.subtask-button {
  width: 80%;
  float: right;
}

.white-btn {
  background: #FFF;
  font-weight: normal;
  color: #000;
  margin-left: 1rem;
}

.white-btn:hover {
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  min-width: 200px;
  padding: 10px 14px;
  border-radius: 12px;
  border: 1px solid #C4C4CA;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.dropdown-wrapper {
  position: relative;
}

.dropdown-wrapper .dropdown-menu {
  top: 2.5rem !important;
  left: 0 !important;
  width: auto !important;
}

.dropdown-wrapper .subtask {
  top: -1000% !important;
  left: -400% !important;
  min-height: 150px !important;
}

/* Add the 'show' class when isDropdownOpen is true */
.dropdown-menu.show {
  display: block;
}

.position-top {
  top: 110%;
  max-height: 351px;
}

.position-bottom {
  bottom: 110%;
  max-height: 392px;
}

.dropdown-menu input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: none;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu ul li label {
  display: block;
  margin-bottom: 5px;
}

/* Style the checkbox however you like */
.dropdown-menu ul li input[type="checkbox"] {
  margin-right: 5px;
}

.selected-leader {
  background: #EAEAEA;
}

.popup {
  width: 50% !important;
}

@media screen and (max-width:768px) {
  .popup {
    width: 90% !important;
  }
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.more {
  background-color: #EAEAEA;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  min-width: 60px;
}
</style>