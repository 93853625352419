<script setup>
import EmojiIcon from "@/components/Icons/EmojiIcon.vue";
import ReplyIcon from "@/components/Icons/ReplyIcon.vue";
import EyeIcon from "@/components/Icons/EyeIcon.vue";
import { useToast } from "vue-toastification";
import { onMounted, ref, watch } from "vue";
import api from "../../../api";
import Danger from "../../ToastIcons/ErrorIcon.vue";
import EmojiPicker from 'vue3-emoji-picker';
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import Editor from '@/components/Editor/Editor.vue';
import { useRoute, useRouter } from "vue-router";
import { getPageCaching, getUserName } from "@/components/Utilities/Utilities";
import { db } from "@/localdatabase/dbConfig";
var router = useRouter();
const toast = useToast();
const comment = ref("");
const hideFromGuest = ref(false);
const parentComment = ref("0");
const commentsArray = ref([]);
const showemojiPopup = ref(false);
let commentReply = ref("");
let userProfile = ref(null);
async function uploadImageAsAttachment(base64, file) {
  var formData = new FormData();
  formData.append("file", file);
  let responseFile = await api.post(`utils/uploader`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (responseFile.status == 200) {
    let responseAttachment = await api.post(
      `issues/${commentProps.globalIssueId}/attachments`,
      {
        size: file["size"],
        name: file["name"],
        path: responseFile.data["path"],
      }
    );
    if (responseAttachment.status == 201) {
      return responseFile;
    }
  }
}
const replyFields = ref([]);
let parentCommentSelected = ref(-1);
let commentProps = defineProps([
  "id",
  "sorting",
  "renderView",
  "globalIssueId",
  "comments",
  "issue"
]);
const commentEmits = defineEmits(["reload:comments", "loadmore:comments"]);
let currentUserprofile = JSON.parse(localStorage.getItem("userProfile"));
const route = useRoute();
onMounted(() => {
  if (localStorage.getItem("userProfile")) {
    userProfile.value = JSON.parse(localStorage.getItem("userProfile"));
  }
  document.addEventListener("keydown", handleShortcuts, false);
  fetchComments();
});

watch(route, (newValue, oldValue) => {
  fetchComments();
});

watch(() => commentProps.sorting, (first, second) => {
  globalOrder.value = commentProps.sorting;
  commentPage.value = 1;
  issueComments.value = [];
  fetchComments();
});

const issueComments = ref([]);
const commentPage = ref(1);
const commentSize = ref(20);
const globalOrder = ref("asc");
const pageCaching = getPageCaching(commentProps.globalIssueId);
if (pageCaching != null) {
  globalOrder.value = pageCaching.newestFirst == false ? 'ASC' : 'DESC';
}

const allEmojisForTask = ref(null);
function setAllReactions() {
  let objectType = "Comment";
  db.reaction.where({ object_type: objectType }).toArray().then(async (reactions) => {
    if (reactions.length > 0) {
      const members = await db.members.toArray();
      if (allEmojisForTask.value == null) {
        allEmojisForTask.value = {};
      }
      for (let reaction of reactions) {
        const commentId = reaction.object_id;
        const reactionData = {
          user_id: reaction.user_id,
          object_id: reaction.object_id,
          object_type: reaction.object_type,
          emoji: reaction.emoji
        };
        if (allEmojisForTask.value[commentId] == undefined) {
          allEmojisForTask.value[commentId] = {};
        }
        if (allEmojisForTask.value[commentId][reaction.emoji] == undefined) {
          let phrase = '';
          if (currentUserprofile['id'] == reactionData['user_id']) {
            phrase = 'You, ';
          }
          else {
            for (let mem of members) {
              if (mem.user_id == reactionData['user_id']) {
                phrase = mem.name + ", ";
                break;
              }
            }
          }
          allEmojisForTask.value[commentId][reaction.emoji] = {
            count: 1,
            data: [reactionData],
            username: phrase
          };
        }
        else {
          let phrase = allEmojisForTask.value[commentId][reaction.emoji]['username'];
          if (currentUserprofile['id'] == reactionData['user_id']) {
            phrase += 'You, ';
          }
          else {
            for (let mem of members) {
              if (mem.user_id == reactionData['user_id']) {
                phrase += mem.name + ", ";
                break;
              }
            }
          }
          allEmojisForTask.value[commentId][reaction.emoji].count = allEmojisForTask.value[commentId][reaction.emoji].count + 1;
          allEmojisForTask.value[commentId][reaction.emoji].username = phrase;
          allEmojisForTask.value[commentId][reaction.emoji].data.push(reactionData);
        }
      }
    }
  });
}

function onSelectEmoji(emoji, commentId) {
  let theEmoji = "";
  if (emoji.i == undefined) {
    theEmoji = emoji;
  }
  else {
    theEmoji = emoji.i;
  }
  if (allEmojisForTask.value == null) {
    allEmojisForTask.value = {};
  }
  let objectType = "Comment";
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  const reactionData = {
    user_id: userProfile.id,
    object_id: commentId,
    object_type: objectType,
    emoji: theEmoji,
  };
  if (allEmojisForTask.value[commentId] == undefined) {
    allEmojisForTask.value[commentId] = {};
  }
  if (allEmojisForTask.value[commentId][theEmoji] == undefined) {
    allEmojisForTask.value[commentId][theEmoji] = {
      count: 1,
      data: [reactionData],
      username: 'You '
    };
  }
  else {
    //Check For  the removal of the emoji if it is clicked again by the same user
    let removalIndex = -1;
    let indexToRemove = -1;
    let reactionToRemove = null;
    for (const userReaction of allEmojisForTask.value[commentId][theEmoji].data) {
      removalIndex++;
      if (userReaction.user_id == currentUserprofile['id']) {
        //to be removed
        indexToRemove = removalIndex;
        reactionToRemove = userReaction;
        break;
      }
    }
    if (indexToRemove > -1) {
      allEmojisForTask.value[commentId][theEmoji].data.splice(indexToRemove, 1);
      if (allEmojisForTask.value[commentId][theEmoji].data.length <= 0) {
        delete allEmojisForTask.value[commentId][theEmoji];
      }
      else {
        allEmojisForTask.value[commentId][theEmoji].count = allEmojisForTask.value[commentId][theEmoji].count - 1;
        allEmojisForTask.value[commentId][theEmoji].username = allEmojisForTask.value[commentId][theEmoji].username.replace('You, ', '');
      }
      db.reaction.where(
        {
          user_id: reactionToRemove.user_id,
          object_id: reactionToRemove.object_id,
          object_type: reactionToRemove.object_type,
          emoji: reactionToRemove.emoji
        }).toArray().then(async (response) => {
          for (const userReactionLocal of response) {
            await db.reaction.delete(userReactionLocal.id);
            await api.delete(`reactions/${userReactionLocal.id}`);
          }
        });
      return;
    }
    //Check For  the removal of the emoji if it is clicked again by the same user
    let phrase = allEmojisForTask.value[commentId][theEmoji]['username'];
    phrase += 'You, ';
    allEmojisForTask.value[commentId][theEmoji].count = allEmojisForTask.value[commentId][theEmoji].count + 1;
    allEmojisForTask.value[commentId][theEmoji].username = phrase;
    allEmojisForTask.value[commentId][theEmoji].data.push(reactionData);
  }
  api.post('reactions', reactionData).then(async (response) => {
    if (response.status == 200) {
      await db.reaction.add(response.data);
    }
  });
}

function loadMoreComments() {
  commentPage.value = commentPage.value + 1;
  fetchComments();
}
const totalCommentsCount = ref(0);
function fetchComments() {
  let issueId = commentProps.globalIssueId;
  //issueComments.value = [];
  api
    .get(
      `issues/${issueId}/comments?page=${commentPage.value}&count=${commentSize.value}&order=${globalOrder.value}`
    )
    .then((response) => {
      if (response.status == 200) {
        let commentsTemp = response.data.data;
        totalCommentsCount.value = response.data.total;
        let commentsFinalArray = issueComments.value;
        for (let comtemp of commentsTemp) {
          comtemp["showEmoji"] = false;
          comtemp["showMenu"] = false;
          comtemp["selectedEmoji"] = "";
          comtemp["showReply"] = false;
          if (comtemp["parent_id"] == null) {
            let found = false;
            for (
              let commentCounter = 0;
              commentCounter < commentsFinalArray.length;
              commentCounter++
            ) {
              if (commentsFinalArray[commentCounter]["id"] == comtemp["id"]) {
                found = true;
                commentsFinalArray[commentCounter] = comtemp;
                break;
              }
            }
            if (!found) {
              commentsFinalArray.push(comtemp);
            }
          }
        }
        issueComments.value = commentsFinalArray;
        setAllReactions();
      }
    });
}

function handleShortcuts(event) {
  var inputs = ["input", "select", "textarea"];
  if (
    event.srcElement.classList.contains("ce-paragraph") ||
    event.srcElement.classList.contains("ql-editor") ||
    event.srcElement.classList.contains("tiptap") ||
    inputs.indexOf(event.srcElement.tagName.toLowerCase()) !== -1
  ) {
    return;
  }
  if (event.key == "c") {
    showEditor.value = true;
  }
  if (event.key == "r" && event.metaKey == true) {
    parentCommentSelected.value = issueComments.value[issueComments.value.length - 1]["id"];
    issueComments.value[issueComments.value.length - 1]['showReply'] = true;
  }
}
function toggleHideFromGuests(value) {
  hideFromGuest.value = value;
}
const commentHTML = ref(null);
function saveDescription(comment) {
  commentHTML.value = comment;
}
function postComment(html = '') {
  commentHTML.value = html == '' ? null : html;
  if (commentHTML.value == null) {
    toast("Please enter comment", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  comment.value = commentHTML.value;
  let issueId = commentProps.globalIssueId;
  api
    .post(`issues/${issueId}/comments`, {
      content: comment.value,
      guest_access: hideFromGuest.value,
    })
    .then((response) => {
      if (response.status == 201) {
        comment.value = "";
        hideFromGuest.value = false;
        fetchComments();
        showEditor.value = false;
      } else {
        toast("Something went wrong", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}
function showEmoji() {
  showemojiPopup.value = false;
  return;
}
function showEmojiPopupMenu(commIndex) {
  commentsArray.value = issueComments.value;
  commentsArray.value[commIndex]["showEmoji"] =
    !commentsArray.value[commIndex]["showEmoji"];
}

function showReply(commIndex) {
  commentsArray.value = issueComments.value;
  parentCommentSelected.value = commentsArray.value[commIndex]["id"];
  commentsArray.value[commIndex]["showReply"] =
    !commentsArray.value[commIndex]["showReply"];
  for (
    let commTempIndex = 0;
    commTempIndex < commentsArray.value.length;
    commTempIndex++
  ) {
    if (commIndex != commTempIndex) {
      commentsArray.value[commTempIndex]["showReply"] = false;
    }
  }
  commentReply.value = "";
}
function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) <= 0
    ? "1 seconds"
    : Math.floor(seconds) + " seconds";
}
function postCommentReply(reply) {
  commentReply.value = reply;
  if (commentReply.value == "") {
    toast("Please enter some reply", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  let issueId = commentProps.globalIssueId;
  api
    .post(`issues/${issueId}/comments`, {
      content: commentReply.value,
      guest_access: hideFromGuest.value,
      parent_id: parentCommentSelected.value,
    })
    .then((response) => {
      if (response.status == 201) {
        commentReply.value = "";
        fetchComments();
      } else {
        toast("Something went wrong", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}
const commentToBeDeleted = ref(null);
function onDeleteClickFromModal() {
  let issueId = commentProps.globalIssueId;
  let btn = document.getElementById("commentsModalCancelBtn");
  btn.click();
  api
    .delete(`issues/${issueId}/comments/${commentToBeDeleted.value.id}`)
    .then((response) => {
      if (response.status == 200) {
        fetchComments();
      }
    });
}

function deleteComment(comment) {
  commentToBeDeleted.value = comment;
}
const showEditor = ref(false);
function displayEditor() {
  showEditor.value = !showEditor.value;
}
</script>
<template>
  <div class="row">
    <input class="input-field mt-2" readonly placeholder="Leave comment..." v-show="!showEditor"
      @click="displayEditor" />
    <div class="col-md-12 mt-2 comment-wrapper pb-0" v-if="showEditor" style="
        min-height: 100px;
        position: relative;
        border: 1px solid #c4c4ca;
        border-radius: 8px;
        padding: 1rem;
        background-color: #FFFFFF;
      ">
      <Editor :id="'issueComment-' + commentProps.globalIssueId" @update:description="saveDescription($event)"
        :showBottomButtons="false" :commentShortKey="true" @post:comment="postComment" :editorType="'comment'"
        @hide:guest="toggleHideFromGuests($event)" :issue="commentProps.issue" />
    </div>

    <div class="p-0" v-for="(comm, index) in issueComments" :key="index">
      <div class="d-flex justify-content-between mt-3 p-0">
        <div class="commentuser">
          <DisplayAvatar :avatar="comm.creator['avatar']" />
        </div>
        <div class="comment-details" :style="{ background: comm.guest_access == false ? '#FFFFFF' : '#fffee0' }"
          @mouseover="comm.showMenu = true" @mouseleave="comm.showMenu = false">
          <div class="row">
            <div class="col-md-9 mt-1 comment-wrapper">
              <span class="comment-user-name">
                {{ getUserName(comm.creator) }}
              </span>
              <span class="comment-user-name">{{ timeSince(new Date(comm.created_at)) }} ago</span>
              <div class="actual-comment mt-2">
                <span v-html="comm.content"></span>
              </div>
              <div class="child-comment-wrapper mt-1" v-for="(childcomm, childIndex) in comm.childComments"
                :key="childIndex">
                <div class="border-vertical"></div>
                <div class="child-comments">
                  <div class="child-commentuser">
                    <DisplayAvatar :avatar="childcomm.creator['avatar']" />
                  </div>
                  <div class="child-actual-comment">
                    <span class="comment-user-name">
                      {{ getUserName(childcomm.creator) }}
                    </span>
                    <span class="comment-user-name">{{ timeSince(new Date(childcomm.created_at)) }} ago</span>
                    <div class="actual-comment mt-2">
                      <span v-html="childcomm.content"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 mt-1" style="text-align: right" v-if="comm.showMenu">
              <div class="cursor-pointer comment-menu" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i style="font-size: 1.1rem; line-height: 1.4rem" class="fa-solid fa-ellipsis"></i>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px">Copy Link</a>
                  </li>
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px">Edit</a>
                  </li>
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px" data-bs-toggle="modal" @click="deleteComment(comm)"
                      data-bs-target="#deleteCommentModel">
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
              <div class="comment-icons">
                <div class="emojis">
                  <BDropdown variant="link" v-model="comm['showEmoji']" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <EmojiIcon class="iconInner" />
                    </template>
                    <EmojiPicker @click.stop="" :native="true" @select="onSelectEmoji($event, comm.id)"
                      :additional-groups="{
      my_custom_group: [
        { n: ['grinning face', 'grinning'], u: '1f600' },
        { n: ['grinning face with smiling eyes', 'grin'], u: '1f601' },
      ],
    }" :group-names="{ my_custom_group: 'Frequently used' }" />
                  </BDropdown>
                </div>
                <ReplyIcon class="dropdown-element-exclude iconInner" @click="showReply(index)" />
                <div class="cursor-pointer comment-menu" id="dropdownMenuWhoSeen" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <EyeIcon class="iconInner" />
                  <ul class="dropdown-menu seen-menu" aria-labelledby="dropdownMenuWhoSeen">
                    <li class="text-darkgray">
                      <span class="seen-name">Maria James</span>
                      <span class="seen-date">Seen on 28 May 2023 | 12:14 PM CT</span>
                    </li>
                    <li class="text-darkgray">
                      <span class="seen-name">Maria James</span>
                      <span class="seen-date">Seen on 28 May 2023 | 12:14 PM CT</span>
                    </li>
                    <li class="text-darkgray">
                      <span class="seen-name">Maria James</span>
                      <span class="seen-date">Seen on 28 May 2023 | 12:14 PM CT</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="allEmojisForTask != null && allEmojisForTask[comm.id]" style="padding:0.5rem 1rem;">
            <span class="emoji" v-for="(detail, index) in allEmojisForTask[comm.id]" :key="index"
              v-tooltip="detail.username.slice(0, -2) + ' reacted'" @click="onSelectEmoji(index, comm.id)"
              style="cursor: pointer; float:left; margin-right: 0.2rem; background-color: #f9633230 !important; width: 50px; height: 28px; border-radius: 14px; padding: 0 0.5rem; line-height: 28px;">
              <span style="font-size: 20px;">{{ index }}</span><span style="font-size: 12px;">{{ detail.count
                }}</span>&nbsp;
            </span>
          </div>
          <div class="row">
            <div class="child-comment-wrapper mt-1" :class="{ hideReply: !comm.showReply }">
              <div class="border-vertical"></div>
              <div class="child-comments">
                <div class="child-commentuser">
                  <DisplayAvatar :avatar="currentUserprofile['avatar']" />
                </div>
                <div class="child-actual-comment new-comment">
                  <span class="comment-user-name">
                    {{ getUserName(currentUserprofile) }}
                  </span>
                  <span class="comment-user-name"></span>
                  <div class="actual-comment mt-2">
                    <Editor v-if="comm.showReply" :replyComment="true" @update:description="saveDescription($event)"
                      :showBottomButtons="false" @post:comment="postCommentReply($event)" :editorType="'comment'"
                      :id="'commentReply' + index" :issue="commentProps.issue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="totalCommentsCount > 12 && (totalCommentsCount - issueComments.length) > 0">
      <div class="col-md-12 pt-4 pb-4 text-center">
        <button class="custom-primary-btn" @click="loadMoreComments">
          View Next {{ (totalCommentsCount - issueComments.length) > 20 ? '20' : totalCommentsCount -
      issueComments.length }} Comments
        </button>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="deleteCommentModel" tabindex="-1" aria-labelledby="deleteCommentModelLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteCommentModelLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete this comment?
        </div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="commentsModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onDeleteClickFromModal">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.comment-wrapper .ql-container.ql-snow {
  border: none;
  padding-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .comment-wrapper .ql-container.ql-snow {
    padding-bottom: 125px;
  }
}

.comment-wrapper .ql-toolbar.ql-snow {
  border: none;
}

.comment-wrapper .comment-editor-toobar {
  border-top: 1px solid #c4c4ca;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hideReply {
  display: none;
}

.label-float {
  float: right;
  padding-left: 5px;
}

.emoji-mart-bar-preview {
  display: none !important;
}

.comment-reply-field {
  width: 80%;
  border: none;
  font-size: 12px;
  outline: none;
}

.child-actual-comment.new-comment {
  width: 95%;
}

.child-comment-wrapper {
  clear: both;
}

ul.seen-menu {
  padding: 0 !important;
}

span.seen-date {
  border-bottom: 1px solid #eaeaea;
}

li.text-darkgray:last-child span.seen-date {
  border-bottom: none;
}

.seen-name {
  font-size: 14px;
  color: #2a2c32;
  display: block;
  padding: 5px 10px 0px 10px;
}

.seen-date {
  font-size: 12px;
  color: #2a2c32;
  display: block;
  padding: 0 10px 5px 10px;
}

.iconInner {
  margin-right: 10px;
  cursor: pointer;
}

.comment-menu {
  float: right;
}

.comment-icons {
  float: right;
  position: relative;
}

.emoji-popup {
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 100%;
}

.child-commentuser {
  float: left;
}

.child-actual-comment {
  float: left;
  padding-left: 10px;
}

.border-vertical {
  width: 1px;
  height: 23px;
  background-color: #c4c4ca;
  margin-left: 10px;
}

.actual-comment {
  font-size: 14px;
  color: #3d4046;
}

.comment-details {
  width: 97%;
  border: 1px solid #c4c4ca;
  border-radius: 10px;
  min-height: 65px;
  padding-bottom: 0;
}

.comment-user-name {
  font-size: 12px;
  color: #7b8497;
  padding-right: 15px;
}

.custom-comment-button {
  padding: 7px 15px !important;
  font-size: 14px !important;
}

.custom-comment-button.reply {
  font-size: 12px !important;
  float: right;
}

.comment-wrapper {
  position: relative;
}

.comment-button {
  position: absolute;
  bottom: 0.5rem;
  right: 1.2em;
  width: 45%;
}

h5 {
  font-size: 16px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table tbody tr {
  border: none;
}

.responsive-table td {
  padding: 10px;
}

.headerCard {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1;
}

.setting {
  padding-left: 12px;
}

button:hover {
  background-color: #f96332;
  color: #f4f4f4;
}

.searchBar input {
  border: 1px solid #acacac;
  padding-left: 2.5rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.col-3 {
  flex: 0 0 auto;
  width: 20%;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px !important;
}

.statusHeader {
  background: #eaeaea;

  margin-top: 20px !important;

  padding: 6px;
}

.issuewrapper {
  border-bottom: 1px solid #eaeaea;
  border-top: none;
}

.ql-editor {
  padding: 4px !important;
  line-height: 3 !important;
}

.ql-toolbar.ql-snow {
  padding-block: 5px !important;
  padding-inline: 8px !important;
}

.statusHeader span {
  padding-right: 5px;
}

@media screen and (max-width: 768px) {
  .comment-details {
    width: 93%;
  }
}

.comment-wrapper .actual-comment p,
.comment-wrapper .actual-comment span {
  margin-bottom: 0;
}
</style>
