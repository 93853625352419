<template>
  <div class="px-0 mx-0">
    <UserHead userTemplates=" "></UserHead>
  </div>

  <div class="tab-wrapper p-3">
    <div class="col-3 float-end mt-3 input-team-color">
      <InputField v-model="SearchTemplate" placeholder="Search" type="text" :svgIcon="true"></InputField>
    </div>
    <div class="col-12 table-responsive mt-5">

      <table class="table table-borderless align-middle text-center">
        <thead class="thead-view">
          <tr class="head-row-view text-center align-middle">
            <th scope="col">Template Name</th>
            <th scope="col">Type</th>
            <th scope="col" class="d-none d-md-table-cell">Location</th>
            <th scope="col" class="d-none d-md-table-cell">Owner</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody class="tab-body">
          <tr class="row-view" v-for="(item, index) in filteredTemplates" :key="index">
            <td>
              <a href="javascript:void(0);" style="color: #000; text-decoration: none">{{ item.name }}</a>
            </td>
            <td>{{ item.identifier }}</td>
            <td class="d-none d-md-table-cell">
              <p>{{ item.location }}</p>
            </td>
            <td class="d-none d-md-table-cell">
              <img src="/upload.png" style="width:30px; height:30px; border-radius:50px;" />
            </td>
            <td>
              <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i style="font-size: 1.5rem" class="fa-solid fa-ellipsis"></i>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a>
                  </li>
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px" href="#">Archive</a>
                  </li>
                  <li class="text-darkgray">
                    <a class="dropdown-item fs-14px" data-bs-toggle="modal" data-bs-target="#exampleModal"
                      @click="onDeleteClick(item)">Delete</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserHead from "@/components/Pages/WorkSpace/UserHead";
import InputField from "@/components/Fields/InputField";
// Ensure to import DisplayAvatar component if not already imported

export default {
  name: "WorkSpaceTemplates",
  components: { UserHead,InputField },
  computed: {
    filteredTemplates() {
      if (this.SearchTemplate != '') {
        return this.data.filter((templateobj) => {
          var name = templateobj.name.toLowerCase();
          var tempsearch = this.SearchTemplate.toLowerCase();
          if (name.match(tempsearch))
            return name.match(tempsearch);
        });
      }
      return this.data
    }
  },
  data() {
    return {
      searchTerm: "",
      data: [
        {
          name: "Template 1",
          identifier: "Issue",
          location: "Project Build",

          // Add more fields as needed
        },
        // Add more data objects as needed
      ],
      SearchTemplate: ''
    };
  },
  methods: {
    onEditClick(item) {
      // Implement edit logic
    },
    onDeleteClick(item) {
      // Implement delete logic
    },
    // Add more methods as needed
  },
};
</script>

<style>
.searchWeb {
  display: block;
}

.searchMob {
  padding-top: 10px;
  display: none;
}

.card {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border: none;
}

.card-header {
  margin-bottom: 8px;
}

.badge {
  text-transform: uppercase;
  line-height: 0.75;
}

.bg-label-primary {
  background-color: #e7e7ff !important;
  color: #696cff !important;
}

.badge {
  --bs-badge-padding-x: 0.593em;
  --bs-badge-padding-y: 0.52em;
  --bs-badge-font-size: 0.8125em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: #f96332;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

h3 {
  font-size: 29px;
  font-family: Roboto, sans-serif;
}

p {
  font-size: 14px;
  color: #666;
  font-family: Roboto-Regular, sans-serif;
}

.icons i {
  position: absolute;
  color: #0000006b;
}

.icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 15px 0 0 10px;
  min-width: 40px;
  font-family: Roboto, sans-serif;
}

.card-body {
  flex: 1 1 auto;
  padding: 0px 0px 15px 0px;
  color: var(--bs-card-color);
}

.signup-button {
  border: none;
  position: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  padding: 5px 15px;
  font-family: Roboto;
  font-size: 16px !important;
}

button:hover {
  background-color: #f96332;
  /* Change to desired hover color */
  color: #ffffff;
  /* Change to desired hover color */
}

.image {
  height: 20px;
  border-radius: 10px;
  background: transparent;
  width: 20px;
}

input[disabled] {
  background-color: #f4f4f4 !important;
}

@media screen and (max-width: 767px) {
  .status-class {
    display: none;
  }

  .searchMob {
    display: block;
  }

  .searchWeb {
    display: none;
  }

  .emailWeb {
    display: none;
  }
}
</style>

