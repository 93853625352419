<template>
  <div :class="loading ? 'pe-none' : ''">
    <UserHead userProfile="text-bold"></UserHead>
    <div class="row">
      <div class="col-sm-12 row">
        <div style="padding-bottom: 10px">
          <div class="ico-trainer my-4" style="text-align: center; margin-right: 0 !important; float: left;">
            <!-- <ProfileAvatar id="previewImage" username="Ahmad Ali"
                v-if="!data.avatar.startsWith('#') && !data.avatar.startsWith('ico')" class="image-logo" /> -->
            <img id="previewImage" class="image-logo" style="width: 112px !important; height: 112px !important;"
              :src="imageIcon ? imageIcon : './../../../assets/images/profile.png'"
              v-if="data.avatar != null && !data.avatar.startsWith('#') && !data.avatar.startsWith('ico')" />
            <span :id="color" v-if="data.avatar != null && data.avatar.startsWith('#')"
              :style="{ backgroundColor: this.color }"
              style="width: 112px !important; height: 112px !important; text-align: center; line-height: 112px; font-size: 3.5rem; color: white; font-weight: bold;"
              class="circle image-logo">
              {{ currentUserNameToDisplay }}
            </span>
            <i v-if="data.avatar != null && data.avatar.startsWith('ico')" class="material-icons fa-icon mx-3 mt-2"
              :style="{ color: iconColorToShow }" style="font-size: 5rem !important;">
              {{ iconToShow }}
            </i>
            <!-- <div class="status-circle"></div> -->
            <a class="mt-2" style="display: inline-block; cursor: pointer;" @click="avatarModal">
              Click to Change
            </a>
          </div>
          <div class="mt-5"
            style="border: 1px solid #c4c4c4; border-radius: 8px; padding: 0 1rem; float: left; box-shadow: 0px 4px 6px -2px #10182808; box-shadow: 0px 12px 16px -4px #10182814">
            <div class="color" style="height: 50px !important; display: flex">
              <template v-for="(color, index) in colorsArray" :key="index">
                <span :style="{ backgroundColor: color }" @click="setColor(color)" class="circle">
                  <i v-if="selectedColor === color" class="material-icons check-icon" aria-hidden="true"
                    style="position: absolute; right: 5px; top: 6px">
                    check
                  </i>
                </span>
              </template>
              <span @click="showColorPopup = true" class="inputcustomcolor"
                style="line-height: 30px !important; font-size: 1rem !important; cursor: pointer;">
                #
              </span>
            </div>
          </div>
        </div>
        <div class="row mb_2">
          <div class="col-sm-12">
            <div class="line my-2">
              Upload File in JPEG , PNG or SVG format upto 5MB
            </div>
          </div>
        </div>
        <div class="row mb_2">
          <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 px-2">
            <label>First Name</label>
            <InputField placeholder="" type="text" :id="data.first_name" ref="inputField" v-model="data.first_name">
            </InputField>
          </div>
          <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 px-2">
            <label>Last Name</label>
            <InputField placeholder="" type="text" id="data.last_name" ref="inputField" v-model="data.last_name">
            </InputField>
          </div>
          <div class="col-12 col-xl-4 col-lg-6 col-md-6 col-sm-12 px-2">
            <label>Display Name
              <span class="line">{{ "(used in mention and activity)" }}</span></label>
            <InputField placeholder="" type="text" id="data.username" ref="inputField" v-model="data.username">
            </InputField>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-16 px-2">
            <label>Mention Email <span class="line">{{ "(User ID)" }}</span></label>
            <InputField placeholder="Email" type="email" id="email" ref="inputField" v-model="data.email"
              :isdisabled="IsChangeUserId"></InputField>
          </div>
          <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 px-2">
            <label class="w-100 d-md-none d-block">&nbsp;</label>
            <button class="custom-secondary-btn mt-4" @click="changeUserId">
              <strong>Change User ID?</strong>
            </button>
          </div>
        </div>
        <div class="row mb_2">
          <div class="col-12 col-xl-3 col-lg-4 col-md-6 col-sm-12 px-2">
            <button class="custom-secondary-btn mt-2">
              <strong>Logout from all other devices?</strong>
            </button>
          </div>
          <div class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 px-2">
            <button class="custom-secondary-btn mt-2">
              <strong>Delete Account?</strong>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <button class="custom-secondary-btn me-2" type="button"  @click="cancel_save()">
              <strong>Cancel</strong>
            </button>
            <button class="custom-primary-btn" type="button" @click="save_profile()">
              <strong>Save</strong>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-if="showAvatarModal" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 550px;">
      <AvatarModal @convertImage="convertImage" @setColor="setColor" @close="showAvatarModal = false"
        @showColorComponent="showColorComponentForIcons = true" :customIconColor="iconCustomColor"
        @selectedIcon="selectIcon" />
    </div>
  </div>
  <div v-if="showColorComponentForIcons" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <ColorComponent @update:showPopup="showColorComponentForIcons = $event"
        @update:colorSelected="setColorForIcons($event)" />
    </div>
  </div>
  <div v-if="showColorPopup" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <ColorComponent @update:showPopup="showColorPopup = $event" @update:colorSelected="setColor($event)" />
    </div>
  </div>
  <!-- <scale-loader
    :loading="loading"
    :color="'#f96332'"
    :height="'35px'"
    :width="'7px'"
  ></scale-loader> -->
</template>

<script>
import UserHead from "@/components/Pages/Profile/UserHead";
import InputField from "@/components/Fields/InputField";
import api from "../../../api";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import ErrorIcon from "../../ToastIcons/ErrorIcon";
import AvatarModal from "@/components/Fields/AvatarModal";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import { inject, watch } from "vue";
export default {
  name: "UserProfile",
  components: {
    InputField,
    UserHead,
    AvatarModal,
    ColorComponent,
  },
  beforeMount() {
    this.loading = true;
    if (localStorage.getItem("userProfile")) {
      this.data = JSON.parse(localStorage.getItem("userProfile"));
      if (this.data.avatar != null && this.data.avatar.startsWith("ico")) {
        let iconArray = this.data.avatar.split(';');
        this.iconToShow = iconArray[0].slice(4);
        this.iconColorToShow = iconArray[1].slice(6);
      }
      if (this.data.avatar) {
        if (this.data.avatar.startsWith("#")) {
          this.logo = false;
          this.IsImage = false;
          this.backgroundColor = true;
          this.color = this.data.avatar;
        } else {
          this.logo = false;
          this.backgroundColor = false;
          this.color = "";
          this.imageIcon = this.data.avatar;
          this.IsImage = true;
        }
      }
      this.loading = false;
    }
    this.getProfile();
  },
  data() {
    return {
      IsChangeUserId: false,
      email: "",
      data: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        avatar: "",
        imageIcon: "", // Define imageIcon property
      },
      modalVisible: false,
      loading: false,
      error: false,
      backgroundColor: false,
      IsImage: false,
      logo: true,
      color: "",
      colorsArray: ["#C4C4CA", "#7B8497", "#12CD95", "#CFB111", "#F81414", "#9B10F1", "#00E0FF"],
      showColorPopup: false,
      showAvatarModal: false,
      showColorComponentForIcons: false,
      iconCustomColor: "#000000",
      iconColorToShow: "",
      iconToShow: "",
      finalIcon: "",
      currentUserNameToDisplay: null,
      currentUser: null,
    };
  },
  setup() {
    const toast = useToast();
    const globalProfileAvatar = inject('globalProfileAvatar');
    return { toast, globalProfileAvatar };
  },
  methods: {
    cancel_save() {
      // this.IsChangeUserId = false
      this.getProfile()
    },
    save_profile() {
      this.onInputChange()
    },
    profilePicModal() {
      this.showAvatarModal = true; // Show the modal by updating the data property
    },
    changeUserId() {
      this.IsChangeUserId = false;
    },
    avatarModal() {
      this.showAvatarModal = true; // Show the modal by updating the data property
    },
    hideModal() {
      this.showAvatarModal = false; // Hide the modal by updating the data property
      this.showColorComponentForIcons = false;
      this.showColorPopup = false;
    },
    selectIcon(icon) {
      let iconArray = icon.split(';');
      this.iconToShow = iconArray[0].slice(4);
      this.iconColorToShow = iconArray[1].slice(6);
      this.finalIcon = icon;
      this.onInputChange();
    },
    setColorForIcons(color) {
      this.iconCustomColor = color;
      this.showColorComponentForIcons = false;
    },
    getProfile() {
      api
        .get("user/profile")
        .then((response) => {
          if (response.status === 200) {
            this.data = response.data.user;
            this.currentUser = this.data;
            localStorage.setItem("userProfile", JSON.stringify(this.data));
            this.currentUserNameToDisplay = this.currentUser && this.currentUser.first_name && this.currentUser.last_name
              ? this.currentUser.first_name[0] + this.currentUser.last_name[0]
              : this.currentUser && this.currentUser.username
                ? this.currentUser.username[0] + this.currentUser.username[1]
                : this.currentUser && this.currentUser.email
                  ? this.currentUser.email[0] + this.currentUser.email[1]
                  : "";
            if (this.data.avatar) {
              if (this.data.avatar.startsWith("#")) {
                this.logo = false;
                this.IsImage = false;
                this.backgroundColor = true;
                this.color = this.data.avatar;
              } else {
                this.logo = false;
                this.backgroundColor = false;
                this.color = "";
                this.imageIcon = this.data.avatar;
                this.IsImage = true;
              }
            }
            if (this.data.email) {
              this.IsChangeUserId = true;
            }
          }
          this.loading = false;
          this.hideModal();
        })
        .catch((error) => {
          console.error(error, "asdasdas");
          this.loading = false;
        });
    },
    onInputChange() {
      this.loading = true;
      api
        .post("user/profile", {
          id: this.data.id,
          first_name: this.data.first_name,
          last_name: this.data.last_name,
          username: this.data.username,
          avatar: this.finalIcon != "" ? this.finalIcon : this.data.avatar,
          email:this.data.email
        })
        .then((response) => {
          if (response.status === 200) {
            this.globalProfileAvatar = response.data.avatar;
            this.toast("Your changes are saved successfully", {
              position: "top-right",
              timeout: 4025,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: SuccessIcon,
              rtl: false,
            });
            this.getProfile();
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          console.error(error, "asdasdas");
          this.loading = false;
          this.toast("Your changes didn't save successfully", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: ErrorIcon,
            rtl: false,
          });
        });
    },

    uploadAvatar(file) {
      this.logo = false;
      this.backgroundColor = false;
      this.IsImage = true;

      if (file) {
        console.log("its my file", file);
        this.file = file; // Set the file property
        this.imageIcon = URL.createObjectURL(file);

        this.toast("Your changes are saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      }
    },

    convertImage(file) {
      this.logo = false;
      this.backgroundColor = false;
      this.IsImage = true;
      if (file) {
        console.log("here i scutom File", file);
        const reader = new FileReader();
        reader.onload = () => {
          this.imageIcon = reader.result;
          this.finalIcon = this.imageIcon;
          this.onInputChange();
        };
        reader.readAsDataURL(file);
      } else {
        this.imageIcon = ""; // Clear the preview if no image selected
      }
    },

    setColor(color) {
      this.backgroundColor = true;
      this.logo = false;
      this.IsImage = false;
      this.color = color;
      this.imageIcon = "";
      this.finalIcon = color;
      this.onInputChange();
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.name-initials {
  width: 240px;
  height: 240px;
  background: #f96332;
  border-radius: 50%;
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb_2 {
  margin-bottom: 15px !important;
}
</style>

