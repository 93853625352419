<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import SprintIcon from "@/components/Icons/SprintIcon.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import DeleteIcon from "../../ToastIcons/DeleteIcon";
import { onMounted, ref, computed } from "vue";
import api from "../../../api";
import { db } from "@/localdatabase/dbConfig";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import sprintTeamd from "../../../assets/team-sprint.png";
import Success from "../../ToastIcons/SuccessIcon.vue";

const router = useRouter();
const data = ref([]);
const selectedTeam = ref(null);
const toast = useToast();
const leader = ref(null);
const searchTerm = ref("");
const SearchTeam = ref("")
const CurrentWorkspaceId = localStorage.getItem("workSpaceID");

onMounted(() => {
  fetchData();
  const CurrentWorkspaceId = localStorage.getItem("workSpaceID");
});

const teamData = ref([]);

const teamModal = ref("");

let activeTeam = ref();

function fetchData() {
  api
    .get("teams")
    .then((response) => {
      if (response.status === 200) {
        data.value = response.data;
      }
    })
    .catch((error) => {
      if (error) {
        return false;
      }
    });
}

const inviteUser = () => {
  alert("Invite User");
};

function gotoIssueList(item) {
  const teamId = item.id;
  const info = localStorage.getItem("workspace");

  if (info) {
    try {
      const workspace = JSON.parse(info);
      const workspaceName = workspace.name;

      if (workspaceName) {
        console.log(workspaceName);
        router.push({
          path: `/${workspaceName}/issues`,
          query: { teamId: teamId },
        });
      } else {
        alert("Workspace name is missing.");
      }
    } catch (error) {
      console.error("Failed to parse workspace information:", error);
      alert("Invalid workspace information.");
    }
  } else {
    alert("No workspace found for this team.");
  }
}

function navigateToTeammember(item) {
  router.push(`/team-members/${item.id}`);
}

function navigateToProject(item) {
  router.push(`teams/${item}/projects`);
}

const selectMember = (item, memberId) => {
  console.log("Update Team", item);
  const requestBody = {
    name: item.name,
    slug: item.slug,
    icon: "updated",
    lead_by: memberId,
  };

  api.put(`/teams/${item.id}`, requestBody).then((response) => {
    fetchData();
    toast("Team leader has been updated.", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Success,
      rtl: false,
    });
  });
};

function onEditClick(item) {
  router.push(`/edit-team/${item.id}`);
}
const teamTitle = ref("");
function onDeleteClick(item) {
  console.log("Being Deleted ", item);
  activeTeam = item.id;
  teamTitle.value = item.name;
}

const membersAssign = (member) => {
  alert("Assigned as Administrator", member);
};

const wdeleteTeam = (item) => {
  api.delete(`teams/${item}`).then((response) => {
    let btn = document.getElementById("teamsModalCancelBtn");
    btn.click();

    fetchData();
    toast("Your team has been deleted.", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: DeleteIcon,
      rtl: false,
    });
  });
}

function selectLeader(teamId, leaderId) {
  leader.value = leader.value === leaderId ? null : leaderId;
}

const filteredMembers = (members) => {
  return members.filter((member) =>
    member.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
};
const filteredTeams = computed(() => {
  return data.value.filter((team) =>
    team.name.toLowerCase().includes(SearchTeam.value.toLowerCase())
  );
});
const sortColumn = ref('name');
const sortOrder = ref('asc');

const sortedTeams = computed(() => {
  return [...filteredTeams.value].sort((a, b) => {
    if (!sortColumn.value) return 0;
    const valA = getNestedValue(a, sortColumn.value);
    const valB = getNestedValue(b, sortColumn.value);

    if (sortOrder.value === 'asc') {
      return valA > valB ? 1 : valA < valB ? -1 : 0;
    } else {
      return valA < valB ? 1 : valA > valB ? -1 : 0;
    }
  });
});
function getNestedValue(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
function sortTable(column) {
  if (sortColumn.value === column) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortOrder.value = 'asc';
  }
}

</script>

<template>
  <AppHeader heading="Teams"></AppHeader>
  <div class="px-3">
    <div class="py-4">
      <div class="d-flex justify-content-between">
        <div class="input-team-color">
          <InputField v-model="SearchTeam" placeholder="Search" type="text" :svgIcon="true"></InputField>
        </div>
        <router-link to="add-team" class="text-decoration-none">
          <button class="custom-primary-btn" type="button">
            <strong>Add Team</strong>
          </button>
        </router-link>
      </div>
    </div>

    <div class="tab-wrapper p-3">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-view">
            <tr class="head-row-view text-center align-middle">
            <th scope="col" @click="sortTable('name')">
              Name
              <i v-if="sortColumn === 'name' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'name' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" @click="sortTable('slug')">
              Identifier
              <i v-if="sortColumn === 'slug' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'slug' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" class="d-none d-md-table-cell" @click="sortTable('location')">
              Location
              <i v-if="sortColumn === 'location' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'location' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" class="d-none d-md-table-cell" @click="sortTable('leader.name')">
              Lead
              <i v-if="sortColumn === 'leader.name' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'leader.name' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" class="d-none d-md-table-cell">Sprints</th>
            <th scope="col" class="d-none d-md-table-cell">Projects</th>
            <th scope="col" class="d-none d-md-table-cell">Members</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody class="tab-body">
            <tr class="row-view" v-for="(item, index) in sortedTeams" :key="index">
              <td>
                <a href="javascript:void(0);" style="color: #000; text-decoration: none" @click="gotoIssueList(item)">
                  {{ item.name }}
                </a>
              </td>
              <td>{{ item.slug }}</td>
              <td class="d-none d-md-table-cell">
                <span v-if="item.workspace_id === CurrentWorkspaceId">Internal</span>
                <span v-else>External</span>
              </td>
              <td class="d-none d-md-table-cell">
                <DisplayAvatar id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"
                  :avatar="item.leader.avatar" />
                <div class="dropdown">
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li>
                      <input type="text" placeholder="Search Member" class="form-control border" v-model="searchTerm" />
                    </li>
                    <li v-for="(member, memberIndex) in filteredMembers(item.members)" :key="member.id">
                      <a class="dropdown-item d-flex align-items-center gap-2" href="#"
                        @click="selectMember(item, member.id)">
                        <DisplayAvatar :avatar="member.avatar" />
                        {{ member.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
              <td class="d-none d-md-table-cell">
                <router-link to="/sprint">
                  <a href="javascript:void(0);" class="text-dark" style="text-decoration: none !important">
                    <img src="../../../assets/team-sprint.png" />
                    +2
                  </a>
                </router-link>
              </td>
              <td class="d-none d-md-table-cell" @click="navigateToProject(item.id)">
                <div class="d-flex align-items-center justify-content-center">
                  <img style="margin-left: -7px" width="35" height="35" src="../../../assets/images/project-1.png"
                    v-for="(project, index) in [1, 2, 3, 4]" :key="index" />
                  <span class="text-lightgray"> +22 </span>
                </div>
              </td>
              <td class="d-none d-md-table-cell" @click="navigateToTeammember(item)" style="cursor: pointer">
                <div class="d-flex align-items-center justify-content-center">
                  <span v-for="(member, index) in item.members" :key="member.id"
                    :style="index != 0 ? 'margin-left: -7px' : ''" class="round-placeholder">
                    <DisplayAvatar :avatar="member.avatar" />
                  </span>
                  <span v-if="item.members.length > 4" class="text-lightgray" style="min-width: 30px">
                    + {{ item.members.length - 4 }}
                  </span>
                </div>
              </td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a></li>
                    <li><a class="dropdown-item fs-14px" href="#">Archive</a></li>
                    <li><a class="dropdown-item fs-14px" data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="onDeleteClick(item)">Delete</a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">Enter team name of "{{ teamTitle }}" to delete</div>
        <input type="" class="form-control w-50 mx-4 mb-3" v-model="teamModal" placeholder="Team Name">
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="teamsModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="wdeleteTeam(activeTeam)">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.selected-leader {
  background: #eaeaea;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .table-responsive {
    overflow-y: auto;
    height: 80vh;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
        position: sticky;
        top: 0px;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}

.modal-dialog {
  top: 30%;
}

@media screen and (max-width: 767px) {
  .input-team-color .input-field {
    background-color: #f2f2f2 !important;
    padding-left: 20px !important;
  }
}
</style>

