<script setup>
import LinkIcon from '@/components/Icons/LinkIcon.vue';
const props = defineProps(['issueNumber', 'issueLink', 'issueTitle', 'status'])
function copyIssueLink() {
  const el = document.createElement('textarea');
  el.value = props.issueLink;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}
</script>

<template>
  <i :class="{
    'fa': true,
    'fa-close': true,
    'custom-fa-close': true
  }" @click="$emit('close-toast')"></i>
  <h2>Issue Created</h2>
  <span v-html="props.status.icon" style="margin-right: 5px;"></span><span>{{ props.issueNumber }} - {{ props.issueTitle
  }}</span><br>
  <a :href="props.issueLink">View Issue</a>
  <LinkIcon :style="{
    'margin-left': '20px'
  }" @click="copyIssueLink" style="cursor: pointer;" />
</template>