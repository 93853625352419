import Dexie from "dexie";
import { Activity } from "./activity";
import { Comment } from "./comment";
import { CommentEmoji } from "./commentEmoji";
import { CommentView } from "./commentView";
import { Document } from "./document";
import { Issue } from "./issue";
import { IssueSubscrbers } from "./issueSubscribers";
import { IssueTypes } from "./issueType";
import { Label } from "./label";
import { Member } from "./member";
import { Milestone } from "./milestone";
import { PeerReview } from "./peerReview";
import { Projects } from "./project";
import { ProjectMembers } from "./projectMember";
import { Subtask } from "./subtask";
import { Team } from "./team";
import { Template } from "./template";
import { TimeTracking } from "./timeTracking";
import { User } from "./user";
import { UserProfile } from "./userprofile";
import { View } from "./view";
import { Workflow } from "./workflow";
import { WorkflowStatus } from "./workflowStatus";
import { WorkSpace } from "./workspace";
import { Reaction } from "./reaction";
import { Sprint } from "./sprint";
export let db = null;
export const defaultDatabase = "issuesTasks";
export function initiateDatabase() {
    db = new Dexie(defaultDatabase);
    db.version(1).stores({
        activities: Activity,
        comment_emojis: CommentEmoji,
        comment_views: CommentView,
        comments: Comment,
        documents: Document,
        issue_subscribers: IssueSubscrbers,
        issue_types: IssueTypes,
        issues: Issue,
        labels: Label,
        members: Member,
        milestones: Milestone,
        peer_reviews: PeerReview,
        project_members: ProjectMembers,
        projects: Projects,
        subtasks: Subtask,
        teams: Team,
        templates: Template,
        time_trackings: TimeTracking,
        user_profiles: UserProfile,
        users: User,
        views: View,
        workflow_statuses: WorkflowStatus,
        workflows: Workflow,
        workspaces: WorkSpace,
        reaction: Reaction,
        sprint: Sprint
    });
}

initiateDatabase();