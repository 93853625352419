<script setup>
import AddCircleIcon from "@/components/Icons/AddCircleIcon.vue";
import CrossIcon from "@/components/Icons/CrossIcon.vue";
import TickIcon from "@/components/Icons/TickIcon.vue";
import MenuIcon from "@/components/Icons/MenuIcon.vue";
import DeleteIcon from "@/components/Icons/DeleteIcon";
import EditIcon from "@/components/Icons/EditIcon.vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import { Container, Draggable } from "vue-dndrop";
import { onMounted, ref } from "vue";
import api from "../../../api";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import ErrorIcon from '../../ToastIcons/ErrorIcon.vue';
import SixDots from '@/components/Icons/SixDots.vue';
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
var router = useRouter();
const selectedMember = ref(null);
let showColorPopup = ref(false);
let teams_id = ref(null);
let workFlowArray = ref(null);
const statusesDictionary = ref({});
let defaultWorkflowHeadings = ref([
  {
    id: 1,
    label: "Backlog",
    value: "backlog",
  },
  {
    id: 2,
    label: "Not Started",
    value: "notstarted",
  },
  {
    id: 3,
    label: "Started",
    value: "started",
  },
  {
    id: 4,
    label: "Completed",
    value: "completed",
  },

  {
    id: 5,
    label: "Cancelled",
    value: "cancelled",
  },
]);
let defaultStatusesDic = ref({
  "backlog": [
    {
      "label": "backlog",
      "name": "Backlog",
      "description": "Item is in backlog",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "new": false,
      "id": "backlog-0",
      "showIcons": false,
      "serverId": ""
    }
  ],
  "notstarted": [
    {
      "label": "notstarted",
      "name": "To Do",
      "description": "Item is in ToDo",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "new": false,
      "id": "notstarted-0",
      "showIcons": false,
      "serverId": ""
    }
  ],
  "started": [
    {
      "label": "started",
      "name": "In Progress",
      "description": "Item is in progress",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "showIcons": false,
      "new": false,
      "id": "started-0",
      "serverId": ""
    }
  ],
  "completed": [
    {
      "label": "completed",
      "name": "Completed",
      "description": "Item is completed",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "showIcons": false,
      "new": false,
      "id": "completed-0",
      "serverId": ""
    }
  ],
  "cancelled": [
    {
      "label": "cancelled",
      "name": "Cancelled",
      "description": "Item is cancelled",
      "is_default": false,
      "color": "#7B8497",
      "showIcons": false,
      "include": true,
      "new": false,
      "id": "cancelled-0",
      "serverId": ""
    }
  ]
});

function setupStatusesDictionary() {
  let finalArray = [];
  for (const head of defaultWorkflowHeadings.value) {
    let childrenCount = 0;
    for (const child of defaultStatusesDic.value[head.value]) {
      if (child.include) {
        childrenCount++;
      }
    }
    let temp = {
      id: head.id,
      label: head.label,
      value: head.value,
      children: defaultStatusesDic.value[head.value],
      childrenCount: childrenCount
    };
    finalArray.push(temp);
  }
  statusesDictionary.value = finalArray;
}

function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

function onDrop(parent, dropResult) {
  defaultStatusesDic.value[statusesDictionary.value[parent]['value']] = applyDrag(defaultStatusesDic.value[statusesDictionary.value[parent]['value']], dropResult);
  setupStatusesDictionary();
}


let name = ref("");
const toast = useToast();
const showPopup = ref(false);
let selectedWorkFlow = ref(null);

onMounted(async () => {
  const user_id = localStorage.getItem("workspace")
  teams_id.value = router.currentRoute.value.params.id;
  await fetchData();
});
const team = ref(null);
async function fetchData() {
  var team_id = router.currentRoute.value.params.id;
  api.get(`teams/${team_id}`).then((response) => {
    team.value = response.data;
    api.get(`teams/${teams_id.value}/workflows`).then(async (response) => {
      workFlowArray.value = response.data;
      /* for (let wf of workFlowArray.value) {
        const wfLocal = await db.workflows.get({ id: wf.id });
        if (!wfLocal) {
          await db.workflows.add(wf);
        }
        else {
          await db.workflows.put(wf, wf.id);
        }
      } */
    });
  });
}

function closePopUp() {
  showPopup.value = false;
}

function onClickSaveWorkFlow() {

  if (name.value == "") {
    toast("Workflow name is required", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: ErrorIcon,
      rtl: false,
    });
    return;
  }
  if (selectedWorkFlow.value != null) {
    editWorkFlow();
  }
  else {
    saveWorkFlow();
  }
}

function saveWorkFlow() {
  let workspace_id = localStorage.getItem("workSpaceID");
  const team_id = router.currentRoute.value.params.id;
  api
    .post("teams/" + team_id + "/workflows", {
      workspace_id,
      name: name.value,
    })
    .then((response) => {
      if (response.status === 201) {
        let totalStatuses = 0;
        let completedStauses = 0;
        for (let statusHead of statusesDictionary.value) {
          totalStatuses += statusHead.children.length;
        }
        var order = 0;
        for (let statusHead of statusesDictionary.value) {
          for (let status of statusHead.children) {
            if (status.include) {
              status.order = order;
              order++;
              api
                .post(`teams/${team_id}/workflows/${response.data.id}/statuses`, status)
                .then(async (response) => {
                  completedStauses++;
                  if (completedStauses >= totalStatuses) {
                    await fetchData();
                  }
                })
            }
          }
        }
        closePopUp();
        toast("Your changes are saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      }
    })
    .catch((error) => {
      if (error) {
        return false;
      }
    });
}

function editWorkFlow() {
  let workspace_id = localStorage.getItem("workSpaceID");
  const team_id = router.currentRoute.value.params.id;
  api
    .put(`teams/${team_id}/workflows/${selectedWorkFlow.value.id}`, {
      workspace_id,
      name: name.value,
    })
    .then((response) => {
      if (response.status === 200) {
        var x = 0;
        let totalStatuses = 0;
        let completedStauses = 0;
        for (let statusHead of statusesDictionary.value) {
          totalStatuses += statusHead.children.length;
        }
        var order = 0;
        for (let statusHead of statusesDictionary.value) {
          for (let status of statusHead.children) {
            if (!status.include) {
              if (status.serverId != "") {
                api
                  .delete(`teams/${team_id}/workflows/${response.data.id}/statuses/${status.serverId}`)
                  .then(async (response) => {
                    completedStauses++;
                    if (completedStauses >= totalStatuses) {
                      await fetchData();
                    }
                  })
              }
            }
            else if (status.serverId != "") {
              status.order = order;
              order++;
              api
                .put(`teams/${team_id}/workflows/${response.data.id}/statuses/${status.serverId}`, status)
                .then(async (response) => {
                  completedStauses++;
                  if (completedStauses >= totalStatuses) {
                    await fetchData();
                  }
                })
            }
            else {
              status.order = order;
              order++;
              api
                .post(`teams/${team_id}/workflows/${response.data.id}/statuses`, status)
                .then(async (response) => {
                  completedStauses++;
                  if (completedStauses >= totalStatuses) {
                    await fetchData();
                  }
                })
            }
          }
        }
        closePopUp();
        toast("Your changes are saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      }
    })
    .catch((error) => {
      if (error) {
        return false;
      }
    });
}
let parentFromWhichToDelete = ref(null);
let statusToDelete = ref(null);
function deleteWorkflowStatus(parent, index) {
  parentFromWhichToDelete.value = parent;
  statusToDelete.value = index
}
function onDeleteWorkflowStatus(parent, index) {
  let btn = document.getElementById("issueModalCancelBtn");
  btn.click();
  if (parentFromWhichToDelete.value != null && statusToDelete.value != null) {
    defaultStatusesDic.value[parentFromWhichToDelete.value][statusToDelete.value]['include'] = false;
    setupStatusesDictionary();
  }
}
const selectedStatusIndex = ref(-1);
function editWorkFlowItem(parent, index) {
  selectedStatusIndex.value = index;
  defaultStatusesDic.value[parent][index]['include'] = false;
  addworkflowitem(parent, defaultStatusesDic.value[parent][index]);
}
let parentForColorToBeSelected = ref('');
let indexForWhichTheColorToBeSelected = ref(-1);
function showColorComponent(parent, index) {
  parentForColorToBeSelected.value = parent;
  indexForWhichTheColorToBeSelected.value = index;
  showColorPopup.value = true;


}
const newStatusDictionary = ref(null);
function addworkflowitem(parent, temp = { name: "", description: "", is_default: false, color: "#7B8497", serverId: "" }) {
  var id = "";
  newStatusDictionary.value = {
    "label": parent,
    "name": temp['name'],
    "description": temp['description'],
    "is_default": temp['is_default'],
    "color": temp['color'],
    "include": true,
    "new": true,
    "id": id,
    "serverId": temp["serverId"],
    "for": parent
  };
}

function makeAllDefaultsFalse() {
  for (let x = 0; x < defaultStatusesDic.value["backlog"].length; x++) {
    defaultStatusesDic.value["backlog"][x]['is_default'] = false;
  }
  for (let x = 0; x < defaultStatusesDic.value["notstarted"].length; x++) {
    defaultStatusesDic.value["notstarted"][x]['is_default'] = false;
  }
  for (let x = 0; x < defaultStatusesDic.value["started"].length; x++) {
    defaultStatusesDic.value["started"][x]['is_default'] = false;
  }
  for (let x = 0; x < defaultStatusesDic.value["completed"].length; x++) {
    defaultStatusesDic.value["completed"][x]['is_default'] = false;
  }
  for (let x = 0; x < defaultStatusesDic.value["cancelled"].length; x++) {
    defaultStatusesDic.value["cancelled"][x]['is_default'] = false;
  }
}

function saveWorkFlowAdded(parent, parentIndex) {
  newStatusDictionary.value.new = false;
  if (selectedStatusIndex.value == -1) {
    defaultStatusesDic.value[parent].push(newStatusDictionary.value);
    newStatusDictionary.value = null;
  }
  else {
    defaultStatusesDic.value[parent][selectedStatusIndex.value] = newStatusDictionary.value;
    defaultStatusesDic.value[parent][selectedStatusIndex.value]['include'] = true;
    newStatusDictionary.value = null;
  }
  setupStatusesDictionary();
  selectedStatusIndex.value = -1;
}

function removeWorkFlowItem(parent) {
  newStatusDictionary.value = null;
  if (selectedStatusIndex.value != -1) {
    defaultStatusesDic.value[parent][selectedStatusIndex.value]['include'] = true;
  }
  setupStatusesDictionary();
  selectedStatusIndex.value = -1;
}

function onDetailsClicked(item) {
  selectedWorkFlow.value = item;
  populateDataForEdit();
  showPopup.value = true;
}

function onNewWorkFlowClicked() {
  selectedWorkFlow.value = null;
  resetDataForNew();
  showPopup.value = true;
}

function onDeleteClick(item) {
  selectedWorkFlow.value = item;
}

function populateDataForEdit() {
  name.value = selectedWorkFlow.value.name;
  var index = 0;
  var id = "";
  defaultStatusesDic.value["backlog"] = [];
  defaultStatusesDic.value["started"] = [];
  defaultStatusesDic.value["notstarted"] = [];
  defaultStatusesDic.value["completed"] = [];
  defaultStatusesDic.value["cancelled"] = [];
  for (index = 0; index < selectedWorkFlow.value.statuses.length; index++) {
    let tempStatusArray = selectedWorkFlow.value.statuses[index];
    if (tempStatusArray.label == "backlog") {
      id = "backlog-" + defaultStatusesDic.value["backlog"].length;
      defaultStatusesDic.value["backlog"].push({
        "label": "backlog",
        "name": tempStatusArray.name,
        "description": tempStatusArray.description,
        "is_default": tempStatusArray.is_default,
        "color": tempStatusArray.color,
        "include": true,
        "id": id,
        "serverId": tempStatusArray.id
      });
    }
    if (tempStatusArray.label == "notstarted") {
      id = "notstarted-" + defaultStatusesDic.value["notstarted"].length;
      defaultStatusesDic.value["notstarted"].push({
        "label": "notstarted",
        "name": tempStatusArray.name,
        "description": tempStatusArray.description,
        "is_default": tempStatusArray.is_default,
        "color": tempStatusArray.color,
        "include": true,
        "id": id,
        "serverId": tempStatusArray.id
      });
    }
    if (tempStatusArray.label == "started") {
      id = "started-" + defaultStatusesDic.value["started"].length;
      defaultStatusesDic.value["started"].push({
        "label": "started",
        "name": tempStatusArray.name,
        "description": tempStatusArray.description,
        "is_default": tempStatusArray.is_default,
        "color": tempStatusArray.color,
        "include": true,
        "id": id,
        "serverId": tempStatusArray.id
      });
    }
    if (tempStatusArray.label == "completed") {
      id = "completed-" + defaultStatusesDic.value["completed"].length;
      defaultStatusesDic.value["completed"].push({
        "label": "completed",
        "name": tempStatusArray.name,
        "description": tempStatusArray.description,
        "is_default": tempStatusArray.is_default,
        "color": tempStatusArray.color,
        "include": true,
        "id": id,
        "serverId": tempStatusArray.id
      });
    }
    if (tempStatusArray.label == "cancelled") {
      id = "cancelled-" + defaultStatusesDic.value["cancelled"].length;
      defaultStatusesDic.value["cancelled"].push({
        "label": "cancelled",
        "name": tempStatusArray.name,
        "description": tempStatusArray.description,
        "is_default": tempStatusArray.is_default,
        "color": tempStatusArray.color,
        "include": true,
        "id": id,
        "serverId": tempStatusArray.id
      });
    }
  }
  setupStatusesDictionary();
}
function resetDataForNew() {
  name.value = "";
  defaultStatusesDic.value["backlog"] = [];
  defaultStatusesDic.value["started"] = [];
  defaultStatusesDic.value["notstarted"] = [];
  defaultStatusesDic.value["completed"] = [];
  defaultStatusesDic.value["cancelled"] = [];
  var id = "";
  if (defaultStatusesDic.value["backlog"].length <= 0) {
    id = "backlog-" + defaultStatusesDic.value["backlog"].length;
    defaultStatusesDic.value["backlog"].push({
      "label": "backlog",
      "name": "Backlog",
      "description": "Item is in Backlog",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "id": id,
      "serverId": ""
    });
  }
  if (defaultStatusesDic.value["notstarted"].length <= 0) {
    id = "notstarted-" + defaultStatusesDic.value["notstarted"].length;
    defaultStatusesDic.value["notstarted"].push({
      "label": "notstarted",
      "name": "To Do",
      "description": "Item is in To Do",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "id": id,
      "serverId": ""
    });
  }
  if (defaultStatusesDic.value["started"].length <= 0) {
    id = "started-" + defaultStatusesDic.value["started"].length;
    defaultStatusesDic.value["started"].push({
      "label": "started",
      "name": "In Progress",
      "description": "Item is in progress",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "id": id,
      "serverId": ""
    });
  }
  if (defaultStatusesDic.value["completed"].length <= 0) {
    id = "completed-" + defaultStatusesDic.value["started"].length;
    defaultStatusesDic.value["completed"].push({
      "label": "completed",
      "name": "Completed",
      "description": "Item completed",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "id": id,
      "serverId": ""
    });
  }
  if (defaultStatusesDic.value["cancelled"].length <= 0) {
    id = "cancelled-" + defaultStatusesDic.value["started"].length;
    defaultStatusesDic.value["cancelled"].push({
      "label": "cancelled",
      "name": "Cancelled",
      "description": "Item archived",
      "is_default": false,
      "color": "#7B8497",
      "include": true,
      "id": id,
      "serverId": ""
    });
  }
  setupStatusesDictionary();
}

function colorSelected(color) {
  showColorPopup.value = false;
  const tempStatusMatchingArray = {
    "backlog": "status-heading-1",
    "notstarted": "status-heading-2",
    "started": "status-heading-3",
    "completed": "status-heading-4",
    "cancelled": "status-heading-5",
  };
  if (parentForColorToBeSelected.value != "" && indexForWhichTheColorToBeSelected.value != -1) {
    newStatusDictionary.value['color'] = color;
  }
}

function onDeleteClickFromModal() {
  const team_id = router.currentRoute.value.params.id;
  db.workflows.delete(selectedWorkFlow.value.id).then((response) => {
    api.delete(`teams/${team_id}/workflows/${selectedWorkFlow.value.id}`).then(async (response) => {
      let btn = document.getElementById("workFlowModalCancelBtn");
      btn.click();
      await fetchData();
      toast("Your workflow has been deleted.", {
        position: "top-right",
        timeout: 4025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: DeleteIcon,
        rtl: false,
      });
    });
  });
}
</script>
<style>
@import url('vue-accessible-color-picker/styles');
</style>
<template>
  <div class="">
    <div class="py-4">
      <div class="d-flex flex-wrap justify-content-between">
        <div class="col-6 col-md-4">
          <InputField placeholder="Search" type="text" :svgIcon="true"></InputField>
        </div>
        <!-- <router-link to="/invite-members" class="text-decoration-none"> -->
        <button class="custom-primary-btn" type="button" @click="onNewWorkFlowClicked">
          <strong>New Workflow</strong>
        </button>
        <!-- </router-link> -->
      </div>
    </div>

    <!-- popup -->
    <div v-if="showPopup" class="popup-overlay">
      <div class="popup workflowpopup">
        <!-- Popup content -->
        <div class="icons position-relative d-flex align-items-center mb-3">
          <span class="mr-3">
            <ShareIcon size="32px" />
          </span>
          <h3 class="popup-heading">New Workflow</h3>
        </div>

        <div class="icons position-relative d-flex align-items-center mb-3">
          <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#3D4046" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M13.26 15.5302L9.73999 12.0002L13.26 8.47021" stroke="#3D4046" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <p class="popup-subheading">Use another teams workflow</p>
        </div>

        <div class="mb-1">
          <label for="workflowName">Workflow Name</label>
          <div class="d-flex flex-wrap mb-2">
            <div class="col-md-6 flex-grow-1 me-2">
              <input class="form-control workflow" v-model="name" type="text" placeholder="Enter">
            </div>
            <div class="col-2 col-md-auto">
              <div class="form-group icons position-relative mb-0 mx-md-3">
                <span class="share-icon">
                  <ShareIcon />
                </span>
                <input class="form-control workflow" type="button">
              </div>
            </div>
          </div>
        </div>
        <div v-for="(parent, parentIndex) in statusesDictionary" class="mb-3 card-container" :key="parent.id">
          <label :for="parent.value" :id="parent.value" class="myLabel">{{ parent.label }}</label>
          <AddCircleIcon class="cursor" @click="addworkflowitem(parent.value)" />
          <div class="newTopWrapper" v-if="newStatusDictionary != null && newStatusDictionary.for == parent.value">
            <div class="newWrapper">
              <div class="">
                <div class="inputBorder d-flex align-items-center justify-content-between px-3">
                  <div class="workflow-icon1">
                    <div @click="showColorComponent(newStatusDictionary.label, 0)"
                      :style="{ width: '16px', height: '16px', border: '2px solid', borderColor: newStatusDictionary.color, borderRadius: '16px' }">
                    </div>
                  </div>
                  <div class="col-md-3 col-3">
                    <input class="field fontsmall" type="text" placeholder="Name" v-model="newStatusDictionary.name">
                  </div>
                  <div class="col-md-6 items2 col-lg-7">
                    <input class="field" type="text" placeholder="| Description of the status"
                      v-model="newStatusDictionary.description">
                  </div>

                  <div class="workflow-icon1">
                    <span class="cursor-pointer" @click="saveWorkFlowAdded(parent.value, parentIndex)"
                      style="padding-top: 0; float: left; width: 40%;">
                      <TickIcon />
                    </span>
                    <span class="cursor-pointer" @click="removeWorkFlowItem(parent.value)"
                      style="padding-top: 0;float: left; width: 40%;">
                      <CrossIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div style="clear: both;"></div>
            <div class="defaultWrapper" style="width: 95%; float: right;">
              <CheckBoxInput style="float: left; padding-right: 10px;" :id="'newDefault' + newStatusDictionary.id"
                :modelValue="newStatusDictionary.is_default"
                @update:modelValue="newStatusDictionary.is_default = $event" />
              <span>Set as default for new issues</span>
            </div>
            <div style="clear: both;"></div>
          </div>
          <div class="simple-page">
            <Container @drop="(e) => onDrop(parentIndex, e)" lock-axis="y">
              <Draggable :tag="{ value: 'div', props: { class: 'animated' } }"
                :drag-not-allowed="parent.childrenCount <= 1" v-for="(status, statusIndex) in parent.children"
                :key="status.id">
                <div v-if="status.include == true" class="draggable-item" style="float: left; width: 100%;"
                  @mouseover="status.showIcons = true" @mouseleave="status.showIcons = false">
                  <div class="iconsWrapper" style="text-align: right;">
                    <div class="workflow-icon1 float-left workflowiconmobile onlydraggableicon"
                      style="padding: 0; width: 80%; line-height: 48px;">
                      <SixDots style="cursor: move;" v-show="status.showIcons == true && parent.childrenCount > 1" />
                    </div>
                  </div>
                  <div class="mt-1 inputBorder min-h-50" v-if="!status.new">
                    <div class="d-flex align-items-center pe-2 justify-content-between">
                      <div class="col-md-4">
                        <div class="px-1" style="width: 100%; float: left;">
                          <div class="bordercircleWrapper float-left" style="margin-top: 0.2rem; margin-left: 0.5rem;">
                            <div
                              :style="{ width: '16px', height: '16px', border: '2px solid', borderColor: status.color, borderRadius: '16px' }">
                            </div>
                          </div>
                          <div class="titleWrapper float-left" style="margin-left: 0.4rem; width: 80%;">
                            <span class="field">{{ status.name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 items2" style="overflow: hidden; line-height: 42px; height: 42px;">
                        <div class="titleWrapper" style="width: 90%; line-height: 42px; height: 42px;">
                          <span class="field">{{ status.description }}</span>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="EditIconWrapper">
                          <a style="cursor: pointer;" v-show="status.showIcons && parent.childrenCount > 1"
                            class="fs-14px" data-bs-toggle="modal"
                            @click="deleteWorkflowStatus(status.label, statusIndex)" data-bs-target="#deleteIssueModel">
                            <CrossIcon :stroke="'#000000'" class="cross-icon-mobile" />
                          </a>
                        </div>
                        <div class="EditIconWrapper">
                          <EditIcon style="cursor: pointer;" v-show="status.showIcons"
                            @click="editWorkFlowItem(status.label, statusIndex)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Draggable>
            </Container>
          </div>
        </div>
        <!-- <Container orientation="vertical" @drop="onColumnDrop($event)" drag-handle-selector=".column-drag-handle"
          @drag-start="dragStart">
          <Draggable v-for="(column, columnIndex) in scene.children" :key="column.id">
            <div class="mb-3 card-container">
              <label :for="column.name" :id="column.props.value" class="myLabel">{{ column.name }}</label>
              <AddCircleIcon class="cursor" @click="addworkflowitem(column.props.value)" />
              <Container group-name="col" @drop="(e) => onCardDrop(column.id, e)" drag-class="card-ghost"
                :get-child-payload="getCardPayload(column.id)" drop-class="card-ghost-drop"
                :drop-placeholder="dropPlaceholderOptions">
                <Draggable v-for="(card, cardIndex) in column.children" :key="card.id">
                  <div class="newTopWrapper" v-if="card.props.new">
                    <div class="newWrapper">
                      <div class="">
                        <div class="inputBorder d-flex align-items-center justify-content-between px-3">
                          <div class="workflow-icon1">
                            <div @click="showColorComponent(card.props.value, cardIndex)"
                              :style="{ width: '16px', height: '16px', border: '2px solid', borderColor: card.props.color, borderRadius: '16px' }">
                            </div>
                          </div>
                          <div class="col-md-3 col-3">
                            <input class="field fontsmall" type="text" placeholder="Name" v-model="card.data">
                          </div>
                          <div class="col-md-6 items2 col-lg-7">
                            <input class="field" type="text" placeholder="| Description of the status"
                              v-model="card.props.description">
                          </div>

                          <div class="workflow-icon1">
                            <span class="cursor-pointer subtask-button"
                              @click="saveWorkFlowAdded(card.props.value, cardIndex)"
                              style="padding-top: 7px; float: left; width: 40%;">
                              <TickIcon />
                            </span>
                            <span class="cursor-pointer subtask-button"
                              @click="removeWorkFlowItem(card.props.value, cardIndex)"
                              style="padding-top: 4px;float: left; width: 40%;">
                              <CrossIcon />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both;"></div>
                    <div class="defaultWrapper" style="width: 95%; float: right;">
                      <CheckBoxInput style="float: left; padding-right: 10px;" :id="'newDefault' + card.id"
                        :modelValue="card.props.default" @update:modelValue="card.props.default = $event" />
                      <span>Set as default for new issues</span>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                  <div class="mt-1 inputBorder min-h-50 pt-1" v-if="!card.props.new">
                    <div class="workflow-icon1 float-left"
                      style="min-width: 3% !important; width: 3% !important; padding: 0; margin-top: 1.1rem;">
                      <div v-if="card.props.default"
                        style="background-color:#F96332; width: 12px; height: 12px; border-radius: 12px;"></div>
                    </div>
                    <div class="workflow-icon1 float-left workflowiconmobile onlydraggableicon"
                      style="min-width: 5%; width: 5% !important; padding: 0;">
                      <MenuIcon v-if="card.props.showIcons" />
                      <div v-if="!card.props.showIcons">&nbsp;</div>
                    </div>
                    <div class="d-flex align-items-center py-2 pe-2 justify-content-between" @mouseover="card.props.showIcons = true"
                      @mouseleave="card.props.showIcons = false">
                      <div class="workflow-icon1 px-1">
                        <div :style="{
                          width: '16px',
                          height: '16px',
                          border: '2px solid',
                          borderColor: card.props.color,
                          borderRadius: '16px',
                        }"></div>
                      </div>
                      <div class="col-md-3 col-4">
                        <span class="field">{{ card.data }}</span>
                      </div>
                      <div class="col-md-6 items2">
                        <span class="field text-nowrap">{{ card.props.description }}</span>
                      </div>

                     
                    </div>
                  </div>
                </Draggable>
              </Container>
            </div>
          </Draggable>
        </Container> -->

        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end mt-3">
              <button type="button" class="custom-secondary-btn me-4 " @click="closePopUp">
                Close
              </button>
              <button class="custom-primary-btn" type="button" @click="onClickSaveWorkFlow">
                {{ selectedWorkFlow == null ? 'Create' : 'Update' }}
              </button>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="tab-wrapper">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-views">
            <tr class="head-row-view text-center align-middle">
              <th scope="col">Workflow Name</th>
              <th scope="col">Used on issue Type</th>
              <th scope="col" class="d-none d-md-table-cell">Location</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody class="tab-body">
            <tr class="row-view" v-for="(       item, index       ) in        workFlowArray       " :key="index">
              <td>{{ item.name }}</td>
              <td>
                <span v-for="(issueType, issueTypeIndex) in item.issueTypes" :key="issueTypeIndex">
                  {{ issueType.name }}<span v-if="issueTypeIndex < item.issueTypes.length - 1">, </span>
                </span>
              </td>
              <td class="d-none d-md-table-cell">{{ team.name }}</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" @click="onDetailsClicked(item)">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal" @click="onDeleteClick(item)"
                        data-bs-target="#workflowdeleteModal">Remove from team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="workflowdeleteModal" tabindex="-1" aria-labelledby="workflowdeleteModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">Are you sure you want to delete this workflow?</div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="workFlowModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onDeleteClickFromModal">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showColorPopup" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <ColorComponent @update:showPopup="showColorPopup = $event" @update:colorSelected="colorSelected($event)" />
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="deleteIssueModel" tabindex="-1" aria-labelledby="deleteIssueModelLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteIssueModelLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">Are you sure you want to delete this status?</div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="issueModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onDeleteWorkflowStatus">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.iconsWrapper {
  float: left;
  width: 5%;
}

.EditIconWrapper {
  float: right;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-views {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}

.newWrapper {
  background: #ffffff;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

.cursor {
  cursor: pointer;
}

.popup {
  background-color: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 45%;
  max-height: 100vh;
  overflow-y: auto;
  /* Centering the popup */
  position: fixed;
  /* Use fixed positioning */
  top: 50%;
  /* Move the top edge to the vertical center */
  left: 50%;
  /* Move the left edge to the horizontal center */
  transform: translate(-50%, -50%);
  /* Center the element */
}

.popup-heading {
  font-size: 2rem;
  //margin-bottom: 20px;
  display: inline-block;
  margin: 0px;
  line-height: 42px;
  padding-left: 6px;
}

.popup-subheading {
  font-size: 1.3rem;
  margin: 0px;
  padding-left: 6px;
  font-weight: bold;
}

.inputBorder {
  width: 95%;
  float: right;
  border: 1px solid #c4c4ca;
  border-radius: 8px;
  height: 44px;
  line-height: 41px;
}

/*[class^="col-md"] {
  padding: 0;
}*/
.field {
  padding: 8px 4px;
  border: none;
  font-size: 16px;
  height: 40px;
}

.field:focus {
  border: none;
  /* Remove the border on focus */
  outline: none;
  /* Remove the outline on focus */
}

.workflow {
  border-radius: 8px;
  padding: 10px 14px;
  //margin-bottom: 10px;
}

.input1 {
  padding: 0px;
  margin-right: 11rem;
}

.input2 {
  padding-right: 0px;
  width: 14%;
}

.custom-margin {
  margin-bottom: 5px;
}

.share-icon {
  padding: 11px 16px;
  min-width: 40px;
  position: absolute;
  //text-align: center;
}

.workflow-icon1 {
  padding: 1px 0px;
  min-width: 16px;
  //position:absolute;
}

.myLabel {
  margin-right: 5px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #EAEAEA;
  border-radius: 10px;
}

.float-left {
  float: left;
}

.popup.workflowpopup {
  max-width: 640px;
}

@media screen and (max-width: 768px) {
  .items2 .field {
    font-size: 12px !important;
    width: 145px !important;
  }

  .field.fontsmall {
    font-size: 12px;
    max-width: 61px;
  }

  .items2 {
    padding-left: 2px;
    border-left: 1px solid #bcbcbc;
  }

  svg.cross-icon-mobile {
    width: 25px;
  }

  .onlydraggableicon svg {
    position: relative;
    top: 9px;
  }

  .min-h-50 {
    min-height: 50px;
  }

  svg.cross-icon-mobile {
    width: 25px;
  }

  .onlydraggableicon svg {
    position: relative;
    top: 9px;
  }

  .min-h-50 {
    min-height: 50px;
  }
}
</style>
