<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import SprintIcon from "@/components/Icons/SprintIcon.vue";
import draggable from "vuedraggable";
import CupIcon from "@/components/Icons/CupIcon.vue";
import NewIssueType from "./NewIssueType.vue";
import EditIssueType from "./EditIssueType.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import DeleteIcon from "../../ToastIcons/DeleteIcon";
import { onMounted, ref, } from "vue";
import api from "../../../api";
import { db } from "@/localdatabase/dbConfig";
var router = useRouter();
const data = ref([]);
const selectedIssueType = ref(null);
const selectedIssueTypeForEdit = ref(null);
const toast = useToast();

const isNewIssueTypeVisible = ref(false);
const toggleNewIssueType = () => {
  isNewIssueTypeVisible.value = true;
  selectedIssueTypeForEdit.value = null;
}

onMounted(() => {
  fetchData();
});

function fetchData() {
  var team_id = router.currentRoute.value.params.id;
  db.issue_types.where({ team_id: parseInt(team_id) }).toArray().then((response) => {
    data.value = response;
    for (let element of response) {
      if (element.icon.startsWith('ico')) {
        let iconArray = element.icon.split(';');
        element.iconToShow = iconArray[0].slice(4);
        element.iconColorToShow = iconArray[1].slice(6);
      }
    }
  }).catch((error) => {
    console.log(error);
  });
}

function onEditClick(item) {
  isNewIssueTypeVisible.value = false;
  selectedIssueTypeForEdit.value = item;

}

function onDeleteClick(item) {
  selectedIssueType.value = item;
}

function onDeleteClickFromModal() {
  const team_id = router.currentRoute.value.params.id;
  api.delete(`teams/${team_id}/issuetypes/${selectedIssueType.value.id}`).then((response) => {
    db.issue_types.delete(selectedIssueType.value.id).then((responselocal) => {
      let btn = document.getElementById("teamsModalCancelBtn");
      btn.click();
      fetchData();
      toast("Your issue type has been deleted.", {
        position: "top-right",
        timeout: 4025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: DeleteIcon,
        rtl: false,
      });
    });
  });
}
</script>

<template>
  <div v-if="isNewIssueTypeVisible">
    <NewIssueType :v-model="isNewIssueTypeVisible" />
  </div>
  <div v-if="selectedIssueTypeForEdit != null">
    <EditIssueType :selectedIssueType="selectedIssueTypeForEdit" />
  </div>

  <div v-if="!isNewIssueTypeVisible && selectedIssueTypeForEdit == null">
    <div class="py-4">
      <div class="d-flex flex-wrap justify-content-between">
        <div class="col-6 col-md-4">
          <InputField placeholder="Search" type="text" :svgIcon="true"></InputField>
        </div>
        <!-- <router-link to="/new-issue-type" class="text-decoration-none"> -->
        <button class="custom-primary-btn" type="button" @click="toggleNewIssueType">
          <strong>New Issue Type</strong>
        </button>
        <!-- </router-link> -->
      </div>
    </div>
    <div class="tab-wrapper p-3">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-view">
            <tr class="head-row-view text-center align-middle">
              <th scope="col"></th>
              <th scope="col">Issue Type Name</th>
              <th scope="col" class="d-none d-md-table-cell">Owner</th>
              <th scope="col" class="d-none d-md-table-cell">Location</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-view" v-for="(element, index) in data" :key="index">
              <td>
                <img src="../../../assets/images/drag-menu.svg" />
              </td>
              <td class="text-start text-md-center">
                <div class="iconcontainer">
                  <CupIcon v-if="element.icon == null || element.icon == 'CupIcon'"></CupIcon>
                  <img id="previewImage" class="image-logo" style="width: 32px !important; height: 32px !important;"
                    :src="element.icon ? element.icon : './../../../assets/images/profile.png'"
                    v-else-if="!element.icon.startsWith('#') && !element.icon.startsWith('ico')" />
                  <i v-else-if="element.icon.startsWith('ico')" class="material-icons fa-icon"
                    :style="{ color: element.iconColorToShow }" style="font-size: 1.5rem !important;">
                    {{ element.iconToShow }}
                  </i>
                </div>
                <span>{{ element.name }}</span>
              </td>
              <td class="d-none d-md-table-cell">{{ element.creator != null ? element.creator.name : '' }}</td>
              <td class="d-none d-md-table-cell">Test Location</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" @click="onEditClick(element)">Edit</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" @click="onEditClick(element)">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal" @click="onDeleteClick(element)"
                        data-bs-target="#exampleModal">Delete</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">Are you sure you want to delete this issue type?</div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="teamsModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onDeleteClickFromModal">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}

.iconcontainer {
  width: 32px;
  background: linear-gradient(to bottom, #FFFFFF, #D0D5DD);
  border-radius: 8px;
  display: inline-block;
  /*vertical-align: middle;*/
  margin-right: 8px;
  padding: 5px 0px;
  text-align: center;
}

.row-view span {
  text-align: center;
  vertical-align: middle;
}
</style>
