<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.69 11.53C18.12 11.38 17.45 11.3 16.65 11.3C15.54 11.3 15.13 11.57 14.56 12C14.53 12.02 14.5 12.05 14.47 12.08L13.52 13.09C12.73 13.94 11.28 13.94 10.48 13.09L9.52999 12.09C9.49999 12.05 9.46999 12.02 9.43999 12C8.85999 11.57 8.44999 11.3 7.34999 11.3C6.54999 11.3 5.87999 11.37 5.30999 11.53C2.92999 12.17 2.92999 14.06 2.92999 15.72V16.65C2.92999 19.16 2.92999 22 8.27999 22H15.72C19.27 22 21.07 20.2 21.07 16.65V15.72C21.07 14.06 21.07 12.17 18.69 11.53Z"
      fill="#7B8497"
    />
    <path
      opacity="0.4"
      d="M14.79 2H9.20998C4.78998 2 4.78998 4.35 4.78998 6.42V12.21C4.78998 12.43 4.88998 12.63 5.05998 12.76C5.22998 12.89 5.45998 12.94 5.66998 12.88C6.11998 12.76 6.67998 12.7 7.34998 12.7C8.01998 12.7 8.15998 12.78 8.55998 13.08L9.46998 14.04C10.12 14.74 11.05 15.14 12.01 15.14C12.97 15.14 13.89 14.74 14.55 14.04L15.46 13.08C15.86 12.78 16 12.7 16.67 12.7C17.34 12.7 17.9 12.76 18.35 12.88C18.56 12.94 18.78 12.89 18.96 12.76C19.13 12.63 19.23 12.42 19.23 12.21V6.42C19.21 4.35 19.21 2 14.79 2Z"
      fill="#7B8497"
    />
    <path
      d="M13.55 9.91001H10.45C10.06 9.91001 9.75 9.60001 9.75 9.21001C9.75 8.82001 10.06 8.51001 10.45 8.51001H13.55C13.94 8.51001 14.25 8.82001 14.25 9.21001C14.25 9.59001 13.93 9.91001 13.55 9.91001Z"
      fill="#7B8497"
    />
    <path
      d="M14.33 7.12H9.66997C9.27997 7.12 8.96997 6.81 8.96997 6.42C8.96997 6.03 9.27997 5.72 9.66997 5.72H14.32C14.71 5.72 15.02 6.03 15.02 6.42C15.02 6.8 14.71 7.12 14.33 7.12Z"
      fill="#7B8497"
    />
  </svg>
</template>
