<script setup>
import { onMounted, ref, inject } from "vue";
import api from "../../../api";
import Dexie from "dexie";
import { getCurrentInstance } from "vue";
import { db, defaultDatabase, initiateDatabase } from '@/localdatabase/dbConfig';

let emits = defineEmits(['close:loading', 'update:date', 'clear:interval']);
let syncerProps = defineProps(['showOverlay']);
const showPopup = ref(false);
const data = ref([]);
const issues = ref({});
const labelsToDisplay = ref([]);
const reloadIssues = inject('reloadIssues');
const reloadIssue = inject('reloadIssue');
const reloadSubTasks = inject('reloadSubTasks');
const isSyncing = inject('isSyncing');

onMounted(() => {

});

const token = localStorage.getItem("token");
if (isSyncing.value == true || token == null) {
  emits('close:loading', false);
  if (token == null) {
    emits('clear:interval');
  }
}
else {
  Dexie.exists(defaultDatabase).then(function (exists) {
    if (!exists) {
      initiateDatabase();
    }
    fetchWorkspace();
    fetchWorkflowStatuses();
  });
}
function fetchSprints() {
  api.get(`sprints`).then(async (response) => {
    if (response.status == 200) {
      let sprints = response.data;
      for (let sprint of sprints) {
        const sprintLocal = await db.sprint.get({ id: sprint.id });
        if (!sprintLocal) {
          await db.sprint.add(sprint);
        }
        else {
          await db.sprint.put(sprint, sprint.id);
        }
      }
    }
  });
}
function fetchWorkspace() {
  api
    .get(`workspace`)
    .then(async (response) => {
      if (response.status === 200) {
        const workspace = response.data;
        const workspaceLocal = await db.workspaces.get({ id: workspace.id });
        if (!workspaceLocal) {
          await db.workspaces.add(workspace);
        }
        else {
          await db.workspaces.put(workspace, workspace.id);
        }
        fetchTeams();
      }
    });
}
function fetchTeams() {
  api
    .get(`teams`)
    .then(async (response) => {
      if (response.status === 200) {
        const teams = response.data;
        for (let team of teams) {
          const teamLocal = await db.teams.get({ id: team.id });
          if (!teamLocal) {
            await db.teams.add(team);
          }
          else {
            await db.teams.put(team, team.id);
          }
          //Adding Users to users table
          for (let user of team.members) {
            const localUser = await db.users.get({ id: user.id });
            if (!localUser) {
              await db.users.add(user);
            }
            else {
              await db.users.put(user, user.id);
            }
          }

          //Adding Members to  Members Table
          for (let mem of team.members) {
            const localMember = await db.members.get({ user_id: mem.id, team_id: team.id });
            let newMember = {
              user_id: mem.id,
              team_id: team.id,
              email: mem.email,
              first_name: mem.first_name,
              last_name: mem.last_name,
              name: mem.name,
              avatar: mem.avatar
            };
            if (!localMember) {
              await db.members.add(newMember);
            }
            else {
              mem.team_id = team.id;
              await db.members.put(newMember, localMember.id);
            }
          }
          //Adding Workflows for the Teams
          for (let workflow of team.workflows) {
            const localWorkFlow = await db.workflows.get({ id: workflow.id });
            if (!localWorkFlow) {
              await db.workflows.add(workflow);
            }
            else {
              await db.workflows.put(workflow, workflow.id);
            }
            //Adding Workflow Statuses
            const noStatus = await db.workflow_statuses.get({ id: 0 });
            if (!noStatus) {
              await db.workflow_statuses.add({ 'id': 0, 'open': true, 'label': 'No Status', 'name': 'No Status', 'value': null, 'color': '', 'icon': '' });
            }
            for (let status of workflow.statuses) {
              const localStatus = await db.workflow_statuses.get({ id: status.id });
              if (!localStatus) {
                await db.workflow_statuses.add(status);
              }
              else {
                await db.workflow_statuses.put(status, status.id);
              }
            }
          }

          //Fetching and Adding Projects for the team
          const response = await api.get(`teams/${team.id}/projects`);
          const projects = response.data;
          for (let project of projects) {
            const localProject = await db.projects.get({ id: project.id });
            if (!localProject) {
              await db.projects.add(project);
            }
            else {
              await db.projects.put(project, project.id);
            }
          }

          //Fetching and Adding Issue Types for the team
          const responseIssueType = await api.get(`teams/${team.id}/issuetypes`);
          const issueTypes = responseIssueType.data;
          for (let issueType of issueTypes) {
            const localissueType = await db.issue_types.get({ id: issueType.id });
            if (!localissueType) {
              await db.issue_types.add(issueType);
            }
            else {
              await db.issue_types.put(issueType, issueType.id);
            }
          }
        }
      }
    });
}
function fetchWorkflowStatuses() {
  const workspaceId = localStorage.getItem('workSpaceID');
  api
    .get(`workspace/${workspaceId}/workflowstatuses`)
    .then(async (response) => {

      if (response.status === 200) {
        const noStatus = await db.workflow_statuses.get({ id: 0 });
        if (!noStatus) {
          await db.workflow_statuses.add({ 'id': 0, 'open': true, 'label': 'No Status', 'name': 'No Status', 'value': null, 'color': '', 'icon': '' });
        }
        for (let sta of response.data) {
          const statusLocal = await db.workflow_statuses.get({ id: sta.id });
          if (!statusLocal) {
            await db.workflow_statuses.add(sta);
          }
          else {
            await db.workflow_statuses.put(sta, sta.id);
          }
        }
        getIssues();
        fetchSprints();
      }
    })
    .catch((error) => {
      if (error) {
        console.log(error);
        db.open().then(() => {
          fetchWorkflowStatuses();
        });
        return false;
      }
    });
}

function getIssues() {
  api
    .get(`issues`)
    .then(async (response) => {
      if (response.status == 200) {
        let tempIssues = response.data;
        let counter = 0;
        let updatedDate = '';
        let issuesIds = [];
        let commentIds = [];
        for (let issue of tempIssues) {
          if (counter == 0) {
            updatedDate = issue.updated_at;
            counter = counter + 1;
          }
          //Adding a new field to speedup the fetching process
          issue.identifier = issue.project == null ? issue.issue_number : issue.project.slug + '-' + issue.issue_number;
          //Check if the element already exists then update if not then add
          const document = await db.issues.get({ id: issue.id });
          if (!document) {
            if (issue['deletedAt'] == null && issue['deleted_at'] == null) {
              if (!issuesIds.includes(issue.id)) {
                issuesIds.push(issue.id);
              }
              await db.issues.add(issue);
            }
          }
          else {
            if (issue['deletedAt'] == null && issue['deleted_at'] == null) {
              if (!issuesIds.includes(issue.id)) {
                issuesIds.push(issue.id);
              }
              await db.issues.put(issue, issue.id);

            }
            else {
              await db.issues.delete(issue.id);
            }
          }
        }
        //Getting the Comments Related to the Issues
        for (const issueId of issuesIds) {
          const comments = await api.get(`issues/${issueId}/comments`);
          if (comments.data.data.length > 0) {
            for (let comment of comments.data.data) {
              if (!commentIds.includes(comment.id)) {
                commentIds.push(comment.id);
              }
              const react = await db.comments.get({ id: comment.id });
              if (!react) {
                await db.comments.add(comment);
              }
              else {
                await db.comments.put(comment, comment.id);
              }
            }
          }
        }
        //Getting the Comments Related to the Issues
        //Getting the Reactions Related to the Issues
        const objectIdsToPass = issuesIds.concat(commentIds);
        api.post('reactions/getreactions', { issues_id: objectIdsToPass }).then(async (reactions) => {
          for (let reaction of reactions.data) {
            const react = await db.reaction.get({ id: reaction.id });
            if (!react) {
              await db.reaction.add(reaction);
            }
            else {
              await db.reaction.put(reaction, reaction.id);
            }
          }
          isSyncing.value = false;
          /*reloadIssues.value = true;
          reloadIssue.value = true;
          reloadSubTasks.value = true;*/
          emits('update:date', updatedDate);
          emits('close:loading', false);
        });
        //Getting the Reactions Related to the Issues
      }
    });
}


</script>
<template>
  <div class="flex-container loadingWrapper"
    :class="{ 'showLoadingOverlay': showOverlay, 'hideLoadingOverlay': !showOverlay }">
    <div class="row">
      <div class="col-12">
        <div class="logoWrapper">
          <img class="image-sidebar-logo" src="../../../assets/images/logo-icon.svg" alt="" /><br>
          <span>Loading workspace...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.hideLoadingOverlay {
  display: none !important;
}

.showLoadingOverlay {
  display: grid !important;
}

.loadingWrapper {
  position: absolute;
  z-index: 100;
  background: white;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
}

.logoWrapper {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 40%;
}
</style>
